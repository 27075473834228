import styled from "styled-components";

export const BasicCalendarBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #f8f8f8;
  border-radius: 10px;
  padding: 1rem;

  header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  header p {
    font-size: 1rem;
  }

  header .icons {
    width: 98%;

    display: flex;
    justify-content: space-between;
  }

  header .icons > span {
    margin: 0 1px;
    cursor: pointer;
    color: #878787;
    text-align: center;
    font-size: 1rem;
    user-select: none;
    border-radius: 50%;
  }

  .icons > span:last-child {
    margin-right: -10px;
  }

  header .icons > span:hover {
    background: #f2f2f2;
  }

  header .current-date {
    font-size: 1rem;
    font-weight: 500;
  }

  .calendar ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    text-align: center;
  }

  .calendar .days {

    margin-bottom: 20px;
  }

  .calendar li {
    color: #0b0b0c;
    width: calc(100% / 7);
    font-size: 1.07rem;
  }

  .calendar .weeks li {
    font-weight: 500;
    cursor: default;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
  }

  .calendar .days li {
    z-index: 1;
    cursor: pointer;
    position: relative;
    margin-top: 30px;
  }

  .days li.disabled {
    color: #a09f9f;
  }

  .days li.active {
    color: #0b0b0c;
  }

  .days li::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    height: 40px;
    width: 40px;
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  .days li.active::before {
    background: #dae2f1;
  }

  .days li:not(.active):hover::before {
    background: #f2f2f2;
  }
`;

export const CalendarBase = styled.div`
  width: 100%;
  padding: 0.8rem;
  margin-left: auto;
  margin-right: auto;

  background-color: #fff;
  & > * {
    box-sizing: content-box;
  }
`;


export const DateDisplayWrapper = styled.div`
  display: flex;
  margin-bottom: .5rem;
`