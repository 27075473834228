import React from "react";
import { ErrorControls, ErrorWrapper } from "./styled";
import { Button } from "..";

export const ErrorComp = ({
  message,
  actionText,
  action,
}: {
  message?: string;
  actionText?: string;
  action?: () => void;
}) => {
  return (
    <ErrorWrapper>
      <h3>{message || "Something went wrong"}</h3>
      <ErrorControls>
        <Button
          onClick={() => action && action()}
          label={actionText || "Dismiss"}
          disabled={false}
          variant="primary"
        />
      </ErrorControls>
    </ErrorWrapper>
  );
};
