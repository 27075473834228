

export const throttle = (callback: () => void, cooldown: number) => {
	let inExecution = false;
	let secondCall = false;
	
	const callSecond = () => {
		inExecution = false;
		if (secondCall) {
			secondCall = false;
			run();
		}
	}
	
	const run = async () => {
		if (!inExecution) {
			await callback();
			inExecution = true;
			setTimeout(() => callSecond(), cooldown);
			return true;
		}
		else {
			secondCall = true;
			return false;
		}
	}
	return run;
}

