import React from "react";
import { Route, Routes } from "react-router-dom";

import { AppRoutes } from "./types";
import { withAuth } from "../hoc/withAuth";
import { PageNotFound } from "../pages/NotFound";

import {
  Clustering,
  ClusteringErrors,
  Reclustering,
} from "../pages/Clustering";

import { ClusteringStoreProvider } from "../context/stores/clustering/clustering-store";

const routes: AppRoutes = [
  {
    path: "",
    component: withAuth((props) => <Clustering {...props} />),
  },
  {
    path: "clustered/:reply_id",
    component: withAuth((props) => <Reclustering {...props} />),
  },
  {
    path: "errors",
    component: withAuth((props) => <ClusteringErrors {...props} />),
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

export const ClusteringRouter = () => {

  return (
    <ClusteringStoreProvider>
      <Routes>
        {routes.map((route) => {
          const Comp = route.component;

          return (
            <Route key={route.path} path={route.path} element={<Comp />} />
          );
        })}
      </Routes>
    </ClusteringStoreProvider>
  );
};
