import React, { createContext, useContext, useState } from "react";
import { API, API_Data } from "../../../main-service/src/types/index";
import { APIFilter } from "../../../main-service/src/types/shared/api";
import { reportingFilters } from "../../../main-service/src/constants";
import { http } from "../modules/http";
import { urlSearchParamsFromObj } from "../modules/url";
import { useAuthStore } from "./stores/auth/auth-store";

export type ReportingFilter = APIFilter<keyof typeof reportingFilters>;

export type ReportingResponse = API[API_Data.REPORTING_RESPONSE];

export type ReportingContext = {
  state: {
    isLoading?: boolean;
    reporting?: ReportingResponse | null;
  };
  actions: {
    fetch(
      limit?: number,
      offset?: number,
      searchTerm?: string,
      filters?: ReportingFilter
    ): Promise<unknown>;
  };
};

export const ReportingContext = createContext<ReportingContext>({
  state: {
    isLoading: true,
    reporting: null,
  },
  actions: {
    fetch: () => Promise.resolve(),
  },
});

const useReporting = (): ReportingContext => {
  const { store } = useAuthStore()
  const [ authState ] = store;

  const [isLoading, setIsLoading] = useState(true);
  const [reporting, setReporting] = useState<ReportingResponse | null>();
  const actions = {
    fetch: async (
      limit?: number,
      offset?: number,
      searchTerm?: string,
      filters?: ReportingFilter
    ) => {
      setIsLoading(true);

      let endDate = filters!.end_time?.[0] as Date
    
      if(endDate) {
        endDate.setHours(23,59,59,999)
        filters!.end_time = [endDate]
      }

      const q = filters
        ? urlSearchParamsFromObj(filters)
        : new URLSearchParams();
      const url = `${
        process.env.CLUSTERING_BACKEND_HOST
      }/reporting?limit=${limit}&offset=${offset}&search=${searchTerm}&${q.toString()}`;
      const headers = {
        Authorization: authState.bearerToken,
        "Content-Type": "application/json",
      };

      const data = await http.fetch<ReportingResponse>(url, { headers });

      setReporting(data);
      setIsLoading(false);
    },
  };

  const state = {
    reporting,
    isLoading,
  };

  return {
    state,
    actions,
  };
};

export const ReportingContextProvider = ({ children }) => {
  const value = useReporting()

  return <ReportingContext.Provider value={value}>
    {children}
  </ReportingContext.Provider>
}