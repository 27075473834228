import React, { useState } from 'react';
import { ContentCardHeaderBase, Title, SubTitle, ContentCardIcon, OptionsIconBase } from './styled';
import OptionsIcon from '~/src/assets/dots-vertical.svg';
import { MenuPopup } from '../MenuPopup';

export type ContentCardHeader = {
	isLoading: boolean,
	content: {
		icon?: () => JSX.Element,
		subText?: string,
		title: string
	},
	actions?: { label: string, action: () => void }[]
}

export const ContentCardHeader = ({ isLoading, content, actions }: ContentCardHeader) => {
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	
	return <ContentCardHeaderBase>
		{content.icon && <ContentCardIcon>
			{content.icon()}
		</ContentCardIcon>}
		<Title>{isLoading ? '......' : content.title}</Title>
		{!isLoading && <SubTitle>{content.subText}</SubTitle>}
		{actions && <OptionsIconBase onClick={() => setIsPopupVisible(!isPopupVisible)}><OptionsIcon/></OptionsIconBase>}
		{isPopupVisible && actions ? <MenuPopup items={actions} onItemClick={() => setIsPopupVisible(false)}/> : null}
	</ContentCardHeaderBase>
}
