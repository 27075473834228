import { useCallback } from "react";
import { useAuthRequestSender } from "../../hooks/http";
import { API, API_Data } from "../../../../main-service/src/types";
import { E_ClusteringAI } from "../../../../main-service/src/types/shared/api/clustering-ai";

const baseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/clustering/ai`;
export type PromptTestResultRes =
  API[API_Data.CLUSTERING_AI_RES][E_ClusteringAI.PROMPT_TEST];
  
export const useClusteringAI = () => {
  const sendRequest = useAuthRequestSender();

  const testPrompt = useCallback(
    (text: string) => {
      return sendRequest<PromptTestResultRes>(`${baseUrl}/prompt/test`, {
        method: "POST",
        body: { text },
      });
    },
    [sendRequest]
  );

  return {
    testPrompt,
  };
};
