import React, { useCallback } from "react";
import { Store } from "../../../../../context/stores/copilot-settings";

import { InputItemForm } from "../../../../../components/ConfigurationPanel/TextInputItemForm";

export const CopilotMinimumActionConfidence = () => {
  const { store, effects } = Store.useCopilotSettingsStore();
  const [data] = store;

  const submit = useCallback(
    (value: number) => {
      effects.updateConfidenceLevelToAct(value);
    },
    [effects.updateConfidenceLevelToAct]
  );

  return (
    <InputItemForm
      type="number"
      label="Copilot Minimum Confidence Level To Cluster"
      min={0}
      max={100}
      placeholder="a number between 0 and 100"
      name="confidenceLevelToAct"
      defaultValue={data.settings?.value?.confidenceLevelToAct}
      onSubmit={submit}
    />
  );
};
