import React from "react";

import ArrowLeft from "~/src/assets/arrow-left.svg";
import { BackButton, SideBar } from "../Clustering/styled";
import { ReportingContent } from "../../containers/Reporting";
import { PageTitle } from "../../components/PageTitle";
import { Wrapper } from "./styled";
import { ReportingContextProvider } from "../../context/reporting";

export const Reporting = () => {
  return (
    <ReportingContextProvider>
      <SideBar>
        <BackButton onClick={() => history.back()}>
          <ArrowLeft />
        </BackButton>
      </SideBar>

      <Wrapper>
        <PageTitle>Clustering Report</PageTitle>
        <ReportingContent />
      </Wrapper>
    </ReportingContextProvider>
  );
};
