import styled from "styled-components";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
`;

export const TableWrapper = styled.div`
  position: relative;
  height: 85%;
  width: 100%;
  overflow-y: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  position: sticky;
  background: white;
  top: 0;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #f2f2f2;
`;

export const TableData = styled.td`
  padding: 1.2rem 1.5rem;
`;

export const PaginationBase = styled.div`
  position: relative;
  padding-bottom: 0.25rem;
  position: sticky;
  top: 100%;
  left: 0;
  background: #fff;
  > * {
    &:first-child {
      max-width: 100%;
      width: fit-content;
    }
  }
`;

export const TableHeader = styled.div`
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 97%;
  height: 6.25rem;
  padding: 0 1.5rem;
`;

export const PaginationControlWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const PaginationButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const PaginationControlLabel = styled.span`
  display: inline-flex;
  margin: 0;
  font-family: Work Sans, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  position: relative;
  color: #535c5c;
`;

export const PaginationInput = styled.input`
  position: relative;
  display: inline-flex;
  background-color: #fff;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4375em;
  font-family: Work Sans, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  align-items: center;

  min-width: 0;
  width: 3.938rem;
  height: 2rem;
  border: 2px solid #e0e0e0;
  border-radius: 0.5rem;
  cursor: text;
  padding: 0 0.5rem;
  margin: 0 1rem;
`;

export const ResultsPerPageDropdown = styled.select`
  position: relative;
  display: inline-flex;
  background-color: #fff;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4375em;
  font-family: Work Sans, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  align-items: center;

  min-width: 0;
  width: 3.938rem;
  height: 2rem;
  border: 2px solid #e0e0e0;
  border-radius: 0.5rem;
  cursor: text;
  padding: 0 0.5rem;
  margin: 0 1rem;
`;
export const Divider = styled.div`
  background-color: #edeeee;
  height: 32px;
  width: 2px;
  margin-right: 8px;
`;
