import { useCallback } from "react";
import { ClusteringTasks } from "../../../../main-service/src/types/shared/shared";

import { useAuthRequestSender } from "../../hooks/http";
import { Reply } from "../../context/stores/clustering/types";

const baseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/clustering/tasks/assign`;

export const useAssign = () => {
  const sendRequest = useAuthRequestSender();

  const assignClusteringTask = useCallback(async () => {
    return await sendRequest<Reply>(
      `${baseUrl}/?task=${ClusteringTasks.CLUSTERING}`
    );
  }, [sendRequest]);

  const assignReclusteringTask = useCallback(
    async (replyId: string) => {
      return await sendRequest<Reply>(
        `${baseUrl}/?task=${ClusteringTasks.RECLUSTERING}&reply_id=${replyId}`
      );
    },
    [sendRequest]
  );

  const assignClusteringErrorTask = useCallback(async () => {
    return await sendRequest<Reply>(
      `${baseUrl}/?task=${ClusteringTasks.ERROR_RESOLUTION}`
    );
  }, [sendRequest]);

  const assign = useCallback(
    async (task: ClusteringTasks, id?: string) => {
      switch (task) {
        case ClusteringTasks.CLUSTERING:
          return await assignClusteringTask();
        case ClusteringTasks.RECLUSTERING:
          return await assignReclusteringTask(id!);
        case ClusteringTasks.ERROR_RESOLUTION:
          return await assignClusteringErrorTask();
        default:
          return await assignClusteringTask();
      }
    },
    [assignClusteringTask, assignReclusteringTask, assignClusteringErrorTask]
  );

  return assign;
};
