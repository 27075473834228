import {
  closeClusteringErrorFixesDialogActions,
  clusteringErrorFixingErrorDialogActions,
} from "../../constants";

export const clusteringErrorsExitPopupConfig = {
  id: "clustering-error-fixing-exit",
  mainText: "Are you sure you want to stop?",
  subText: "What doesn't kill you makes you stronger",
  actions: closeClusteringErrorFixesDialogActions,
} as const;

export const clusteringErrorPopupConfig = {
  id: "clustering-error",
  mainText: "An error while trying to fix clustering error",
  subtext: "",
  actions: clusteringErrorFixingErrorDialogActions,
} as const;
