import { ClusterData } from "../../../../../main-service/src/types/shared/api";
import { AIClusteringConflictCheck } from "../../../../../main-service/src/types/shared/cluster-info";
import { ClusteringTasks } from "../../../../../main-service/src/types/shared/shared";
import { ClusteringEffects, Reply, ReplyManagementEffects } from "./types";

export const getDefaultClusteringEffects = (): {
  clustering: ClusteringEffects;
  replyManagement: ReplyManagementEffects;
} => {
  return {
    clustering: {
      assign: function <
        P extends ClusterData | string | undefined | unknown = unknown
      >(_task: ClusteringTasks, _payload: P): Promise<void> {
        throw new Error("Function not implemented.");
      },
      complete: function <
        P extends ClusterData | string | undefined | unknown = unknown
      >(_task: ClusteringTasks, _payload: P): Promise<void> {
        throw new Error("Function not implemented.");
      },
      skip: function <
        P extends ClusterData | string | undefined | unknown = unknown
      >(_task: ClusteringTasks, _payload: P): Promise<void> {
        throw new Error("Function not implemented.");
      },
      unassign: function <
        P extends ClusterData | string | undefined | unknown = unknown
      >(_task: ClusteringTasks, _payload: P): Promise<void> {
        throw new Error("Function not implemented.");
      },
    },
    replyManagement: {
      bounced: function (_replyId: string): Promise<void> {
        throw new Error("Function not implemented.");
      },
      autoreplied: function (_replyId: string): Promise<void> {
        throw new Error("Function not implemented.");
      },
      replied: function (_replyId: string): Promise<void> {
        throw new Error("Function not implemented.");
      },
      toggleTranslation: function (): void {
        throw new Error("Function not implemented.");
      },
      toggleOriginalMessage: function (): void {
        throw new Error("Function not implemented.");
      },
    },
  };
};

export const getSuggestionFromClusteringError = (
  clusteringError: Reply["clusteringError"]
) => {
  const conflict = clusteringError?.metadata;
  if (!conflict) {
    return null;
  }

  const parsedConflict: AIClusteringConflictCheck =
    typeof conflict === "string"
      ? (JSON.parse(conflict) as AIClusteringConflictCheck)
      : conflict;

  if (!parsedConflict.ai) {
    return null;
  }

  let suggestion: Exclude<AIClusteringConflictCheck["ai"], string> =
    typeof parsedConflict.ai === "string"
      ? JSON.parse(parsedConflict.ai)
      : parsedConflict.ai;

  return suggestion;
};
