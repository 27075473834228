import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { Role } from "../../../../main-service/src/types/shared/shared";
import { Tabs } from "../../components";
import { GlobalContext } from "../../context/global";
import { LandingPageBase } from "./styled";
import { NavigationContextProvider } from "../../context/navigation";

const pageData = [
  { label: "Cluster", path: "/landing" },
  {
    label: "Feed",
    path: "/landing/feed",
    roles: [Role.ADMIN, Role.MANAGER, Role.CS],
  },
];

export const Landing = () => {
  return (
    <LandingPageBase>
      <NavigationContextProvider>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Tabs tabs={pageData} />
          <UnclusteredRepliesCount />
        </div>
        <Outlet />
      </NavigationContextProvider>
    </LandingPageBase>
  );
};

const UnclusteredRepliesCount = () => {
  const {
    globalState: { unclusteredRepliesCount },
  } = useContext(GlobalContext);

  return (
    <h2
      style={{
        display: "inline-flex",
        marginTop: "auto",
        marginBottom: "auto",
        padding: ".5rem",
      }}
    >
      {typeof unclusteredRepliesCount !== "undefined" &&
      unclusteredRepliesCount !== null ? (
        <>There are {unclusteredRepliesCount} unclustered responses</>
      ) : (
        ""
      )}
    </h2>
  );
};
