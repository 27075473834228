import styled from "styled-components";

export const ContentBase = styled.div`
  display: flex;
  grid-gap: 8px;
  flex-direction: column;
`;

export const ListView = styled.div`
  display: flex;
  grid-gap: 16px;
  flex-grow: 1;
  flex-direction: column;
`;

export const Button = styled.button`
  padding: 12px;
  color: #fff;
  background: #002342;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s;
  &:hover {
    background: #405d76;
  }
  &:disabled {
    cursor: unset;
    opacity: 0.3;
  }
`;

export const ConfigurationInputLabel = styled.span`
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
`;

export const ConfigurationItemBase = styled.div`
  display: flex;
  width: fit-content;
  margin-left: auto;
`;

export const ConfigurationBlockItemBase = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 1rem;
  }
  & > * {
    margin-bottom: 0.5rem;
  }
`;

export const Group = styled.div`
  display: flex;
  grid-gap: 8px;
  div {
    display: flex;
    & > * {
      margin: 0 0.2rem;
    }
    padding: 0.05rem;
    margin-left: auto;
  }
`;
