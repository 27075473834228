import { useCallback } from "react";
import { HttpFetchResult } from "../../hooks/http/fetch/types";
import { useAuthRequestSender } from "../../hooks/http";

const baseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/clustering/async/replies`;

export type ReplyManagementAPI = {
  setAsAutoreply: (replyId: string) => Promise<HttpFetchResult<unknown>>;
  rescheduleSendout: (
    replyId: string,
    nextSendoutDate: string
  ) => Promise<HttpFetchResult<unknown>>;
  setAsBounced: (replyId: string) => Promise<HttpFetchResult<unknown>>;
  setAsReplied: (replyId: string) => Promise<HttpFetchResult<unknown>>;
};

export const useReplyManagement = (): ReplyManagementAPI => {
  const sendRequest = useAuthRequestSender();

  const setAsAutoreply = useCallback(
    (replyId: string) => {
      return sendRequest(`${baseUrl}/autoreplied`, {
        method: "POST",
        body: { reply_id: replyId },
      });
    },
    [sendRequest]
  );

  const rescheduleSendout = useCallback(
    (replyId: string, nextSendoutDate: string) => {
      return sendRequest(`${baseUrl}/reschedule-sendout`, {
        method: "POST",
        body: { reply_id: replyId, send_after: nextSendoutDate },
      });
    },
    [sendRequest]
  );

  const setAsBounced = useCallback(
    (replyId: string) => {
      return sendRequest(`${baseUrl}/bounced`, {
        method: "POST",
        body: { reply_id: replyId },
      });
    },
    [sendRequest]
  );

  const setAsReplied = useCallback(
    (replyId: string) => {
      return sendRequest(`${baseUrl}/replied`, {
        method: "POST",
        body: { reply_id: replyId },
      });
    },
    [sendRequest]
  );

  return {
    setAsAutoreply,
    rescheduleSendout,
    setAsBounced,
    setAsReplied,
  };
};
