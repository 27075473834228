import React, { createContext, useState } from "react";

type NavigationContextData = {
	currentState: 'LANDING' | 'LOGOUT',
	setCurrentState: React.Dispatch<React.SetStateAction<NavigationContextData['currentState']>>
}

export const NavigationContext = createContext<NavigationContextData>({
	currentState: 'LANDING',
	setCurrentState: () => {}
});

const useNavigation = () => {
	const [currentState, setCurrentState] = useState<NavigationContextData['currentState']>('LANDING');
	return {
		currentState,
		setCurrentState
	}
}

export const NavigationContextProvider = ({children}) => {
	const value = useNavigation()
	return <NavigationContext.Provider value={value}>
		{children}
	</NavigationContext.Provider>

}


