import { useCallback } from "react";
import { ClusterData } from "../../../../main-service/src/types/shared/api";
import {
  ClusteringTasks,
  SetSendAfterTaskPayload,
} from "../../../../main-service/src/types/shared/shared";

import { useAuthRequestSender } from "../../hooks/http";

const baseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/clustering/tasks/complete`;

type Res = {
  error?: boolean;
  message: string;
};

export const useComplete = () => {
  const sendRequest = useAuthRequestSender();

  const completeClusteringTask = useCallback(
    async (payload: ClusterData) => {
      return await sendRequest<Res>(
        `${baseUrl}/?task=${ClusteringTasks.CLUSTERING}`,
        {
          method: "POST",
          body: payload,
        }
      );
    },
    [sendRequest]
  );

  const completeReclusteringTask = useCallback(
    async (payload: ClusterData) => {
      return await sendRequest<Res>(
        `${baseUrl}/?task=${ClusteringTasks.RECLUSTERING}`,
        {
          method: "POST",
          body: payload,
        }
      );
    },
    [sendRequest]
  );

  const completeRescheduleTask = useCallback(
    async (payload: SetSendAfterTaskPayload) => {
      return await sendRequest<Res>(
        `${baseUrl}/?task=${ClusteringTasks.RESCHEDULING}`,
        {
          method: "POST",
          body: payload,
        }
      );
    },
    [sendRequest]
  );

  const completeClusteringErrorTask = useCallback(
    async (payload: ClusterData) => {
      return await sendRequest<Res>(
        `${baseUrl}/?task=${ClusteringTasks.ERROR_RESOLUTION}`,
        {
          method: "POST",
          body: payload,
        }
      );
    },
    [sendRequest]
  );

  const complete = useCallback(
    async <Payload extends ClusterData | SetSendAfterTaskPayload>(
      task: ClusteringTasks,
      payload: Payload
    ) => {
      switch (task) {
        case ClusteringTasks.CLUSTERING:
          return await completeClusteringTask(payload as ClusterData);
        case ClusteringTasks.RECLUSTERING:
          return await completeReclusteringTask(payload as ClusterData);
        case ClusteringTasks.RESCHEDULING:
          return await completeRescheduleTask(
            payload as SetSendAfterTaskPayload
          );
        case ClusteringTasks.ERROR_RESOLUTION:
          return completeClusteringErrorTask(payload as ClusterData);
        default:
          return await completeClusteringTask(payload as ClusterData);
      }
    },
    [completeClusteringTask, completeReclusteringTask]
  );

  return complete;
};
