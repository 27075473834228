import styled from "styled-components";

type ActionBaseProps = {
  type: "primary" | "secondary";
};

export const ActionBase = styled.div<ActionBaseProps>`
  border: none;
  padding: 16px 13px;
  background: ${(props) => (props.type === "primary" ? "#FF6731" : "#fff")};
  color: ${(props) => (props.type === "primary" ? "#fff" : "#002648")};
  flex-grow: 1;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  font-size: ${(props) => (props.type === "primary" ? "16px" : "14px")};
`;

export const ActionsBase = styled.div`
  display: flex;
  padding: 16px 32px 0 32px;
  grid-gap: 16px;
  border-top: 1px solid #edeeee;
  margin-top: 16px;
  justify-content: center;
`;

export const PopupBase = styled.div`
  background: #fff;
  padding: 24px 0;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
`;

export const MainText = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #0b0b0c;
  padding: 16px 32px 0 32px;
  text-align: center;
`;

export const SubText = styled.h4`
  font-size: 16px;
  font-weight: 400;
  color: #0b0b0c;
  padding: 0 32px;
  text-align: center;
`;
