import React, { FC, useCallback } from "react";

import { NoReplyView } from "../../../containers/Clustering/NoReplyView.tsx";
import { ClusteringView } from "../../../containers/Clustering/ClusteringView/index";
import { Popups } from "../../../components/DialogPopups/index.tsx";
import { useClusteringErrorsPage } from "./hooks/use-clustering-errors-page";

export const ClusteringErrors: FC = () => {
  const {
    task,
    dialogs,
    isLoading,
    complete,
    skip,
    onActionDone,
    onViewExit,
    onViewEnter,
  } = useClusteringErrorsPage();

  const renderFallback = useCallback(
    () => <NoReplyView retry={onViewEnter} />,
    [onViewEnter]
  );

  return (
    <>
      <ClusteringView
        task={task}
        exitOnIdle
        isLoading={isLoading}
        onViewExit={onViewExit}
        onViewEnter={onViewEnter}
        complete={complete}
        skip={skip}
        renderFallback={renderFallback}
        onClusteringActionDone={onActionDone}
      />

      <Popups configs={dialogs} />
    </>
  );
};
