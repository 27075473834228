import React, { FC, useCallback } from "react";

export const BasicSelect: FC<{
  options: string[];
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}> = ({ options, value, placeholder, onChange }) => {
  const handleChange = useCallback(
    (e: React.BaseSyntheticEvent<Event>) => {
      onChange && onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <select placeholder={placeholder} value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option} value={option} selected={option === value}>
          {option}
        </option>
      ))}
    </select>
  );
};
