import styled from "styled-components";

export const ToggleContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ToggleLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #edeeee;
  border-radius: 20px;
  padding: 5px;
  width: 100%;
  height: 100%;
  position: relative;
  font-family: Arial, sans-serif;
  font-size: 16px;
`;

export const ToggleButton = styled.span<{
  checked: boolean;
  checkedColor?: string;
  uncheckedColor?: string;
}>`
  position: absolute;
  left: ${({ checked }) => (checked ? "49%" : "0")};
  width: 50%;
  height: 100%;
  background-color: ${({ checked, checkedColor, uncheckedColor }) =>
    checked ? checkedColor || "#FF6731" : uncheckedColor || "#a56c58"};
  border-radius: 20px;
  transition: left 0.3s ease;
`;

const ToggleText = styled.span<{ visible }>`
  position: absolute;
  width: 50%;
  text-align: center;
  color: white;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

export const ToggleTextLeft = styled(ToggleText)`
  left: 0;
  line-height: 30px;
`;

export const ToggleTextRight = styled(ToggleText)`
  right: 0;
  line-height: 30px;
`;
