import { createContext, useState } from "react";
import { Comparable as CMP } from "../../../main-service/src/types/shared/api";

export type Comparable = CMP

export type SelectedFilterProps = {
  [key: string]: Array<Comparable>;
};

type FilterState = {
  isSelected: boolean;
  options: Array<FilterOption>;
  filters: SelectedFilterProps;
  resultsCount: number
};

type FilterAction = {
  setIsSelected: (isSelected: boolean) => void;
  setOptions: (options: Array<FilterOption>) => void;
  setFilters: (filters: SelectedFilterProps) => void;
  setResultsCount: (count: number) => void
};

export type FilterOptionValue =
  | string
  | number
  | { id: string | number; name: string; [key: string | number]: unknown };

export type FilterOption = {
  placeholder?: string;
  renderIcon?: (...args: any[]) => React.ReactNode;
  iconPosition?: "left" | "right";
  label?: string;
  type?: "select" | "multiselect" | "calendar" | "checkbox";
  searchable?: boolean;
  name: string;
  getOptionValues?: () => Array<FilterOptionValue>;
};


export type FilterProps = {
  actions?: FilterAction;
  state?: FilterState;
};

export const FilterContext = createContext<FilterProps>({
  actions: {
    setIsSelected: () => {},
    setOptions: () => [],
    setFilters: () => {},
    setResultsCount: () => {}
  },
  state: {
    isSelected: false,
    options: [],
    filters: {},
    resultsCount: 0
  },
});

export const useFilter = (): FilterProps => {
  const [isSelected, setIsSelected] = useState(false);
  const [options, setOptions] = useState<FilterOption[]>([]);
  const [filters, setFilters] = useState<SelectedFilterProps>({});
  const [resultsCount, setResultsCount] = useState(0)

  const actions: FilterAction = {
    setIsSelected,
    setOptions,
    setFilters,
    setResultsCount,
  };

  const state: FilterState = {
    isSelected,
    options,
    filters,
    resultsCount
  };

  return {
    actions,
    state,
  };
};
