import React, { FC, useCallback, useState } from "react";
import { InputItemFormWrapper } from "./InputItemFormWrapper";
import { Button } from "./styled";

type BaseInputProps<T> = {
  placeholder?: string;
  defaultValue?: T;
  label: string;
  name?: string;
  onSubmit?: (val: T) => void;
};

type TextInputProps = BaseInputProps<string> & {
  type: "text";
};

type NumberInputProps = BaseInputProps<number> & {
  min?: number;
  max?: number;
  type: "number";
};

type InputItemProps = TextInputProps | NumberInputProps;

export const InputItemForm: FC<InputItemProps> = ({
  defaultValue,
  type,
  label,
  onSubmit,
  ...restProps
}) => {
  const [value, setValue] = useState<string | number>(
    defaultValue ?? (type === "text" ? "" : 0)
  );

  const [enabled, setEnabled] = useState(false);

  const enable = useCallback(() => {
    setEnabled(true);
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue =
        type === "text" ? e.target.value : parseFloat(e.target.value);
      setValue(newValue);
    },
    [type]
  );

  const submit = useCallback(() => {
    if (onSubmit) {
      if (type === "text" && typeof value === "string") {
        onSubmit(value);
      } else if (type === "number" && typeof value === "number") {
        onSubmit(value);
      }
    }
    setEnabled(false);
  }, [value, onSubmit, type]);

  return (
    <InputItemFormWrapper label={label}>
      <input
        {...restProps}
        type={type}
        value={value}
        onChange={handleChange}
        disabled={!enabled}
      />

      <Button onClick={enabled ? submit : enable}>
        {enabled ? "Submit" : "Edit"}
      </Button>
    </InputItemFormWrapper>
  );
};
