import React from "react";
import styled from "styled-components";
import { Spinner } from "../Spinner";

export const LoaderBase = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fefefe8f;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader: React.FC = () => {
    return <LoaderBase>
        <Spinner />
    </LoaderBase>
}