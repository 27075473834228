import React from "react";

export const LocaleDate = ({ timestamp }: { timestamp: string }) => {
  return (
    <>
      {new Date(timestamp).toLocaleDateString("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      })}
    </>
  );
};
