import { methodsWithPayload } from "./constants";
import { FetchOptions, HttpMethods, RequestValidationResult } from "./types";

const getHeaders = (headers: any) => {
  const defaultHeader = { "Content-Type": "application/json" };
  return headers ? { ...defaultHeader, ...headers } : defaultHeader;
};

export const parseJSONBody = (body: Record<string, unknown>) => {
  try {
    const stringifiedBody = JSON.stringify(body);
    return { stringifiedBody };
  } catch (error) {
    const message =
      "Request body should be a string or JSON serializable object";
    return { error: message, message };
  }
};

export const convertToFetchAPIOpts = <Opt extends FetchOptions<unknown>>(
  options: Opt,
  validation: RequestValidationResult
) => {
  const { body, ...rest } = options;
  return {
    ...rest,
    method: options.method || "GET",
    headers: getHeaders(options.headers),
    body: validation.body,
  };
};

export const validateRequest = <Body>(
  url: string,
  options?: FetchOptions<Body> | null
): RequestValidationResult => {
  if (!url) {
    return { error: "url required" };
  }

  if (
    options?.body &&
    options?.method &&
    !methodsWithPayload.includes(options.method)
  ) {
    const message = `Method ${options.method} cannot have a body`;
    return { error: message, message };
  }

  let stringifiedBody: string | undefined =
    typeof options?.body === "string" ? options.body : "";
  if (options?.body && typeof options.body === "object") {
    const parse = parseJSONBody(options.body as Record<string, unknown>);
    if (parse.error) {
      return parse;
    }

    stringifiedBody = parse.stringifiedBody;
  }

  if (stringifiedBody) {
    return { body: stringifiedBody, error: "" };
  }

  return { error: "" };
};
