import React, { FC } from "react";
import { Avatar } from "../../../../components/Avatar";
import { LocaleDate } from "../../../../components/DateTime/LocaleDate";
import { SmartDateTime } from "../../../../components/DateTime/SmartDateTime";
import { TReplyViewClusteringHistory } from "../../../../context/reply-cluster/types";

const isUrl = (str: string) => {
  try {
    return !!new URL(str);
  } catch (e) {
    return false;
  }
};

const SendAfter: FC<{ sendAfter: string }> = ({ sendAfter }) => {
  return sendAfter ? (
    <span>
      rescheduled the next send out to{" "}
      <b>
        <LocaleDate timestamp={sendAfter} />
      </b>
      .
    </span>
  ) : (
    <>rescheduled the next send out.</>
  );
};

export const ClusteringHistoryList: React.FC<{
  history: TReplyViewClusteringHistory[];
}> = ({ history }) => {
  return (
    <ul>
      {history.map((h) => {
        let imgUrl = "";
        let initials = "";

        if (isUrl(h.avatar)) {
          imgUrl = h.avatar;
        } else {
          initials = h.avatar;
        }

        return (
          <li key={`${h.id}-${h.name}-${h.date}`}>
            <div className="cluster-info">
              <span className="avatar">
                <Avatar imgUrl={imgUrl} initials={initials} />
              </span>
              <span className="name">{h.name}</span>
              {h.isRescheduled ? (
                <SendAfter sendAfter={h.sendAfter} />
              ) : (
                "clustered to"
              )}
              <span className="category">{h.clusteredTo}</span>
            </div>
            <span>
              <SmartDateTime timestamp={h.date} />
            </span>
          </li>
        );
      })}
    </ul>
  );
};
