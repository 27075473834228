import { useCallback, useMemo } from "react";
import { useAuthRequestSender } from "../../hooks/http";
import {
  RES_AdminUser,
  RES_AdminUsers,
} from "../../../../main-service/src/types/shared/api";
import { HttpFetchResult } from "../../hooks/http/fetch/types";
import { User } from "../../../../main-service/src/types/shared/shared";
import { urlSearchParamsFromObj } from "../../modules/url";

const baseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/admin/users`;

export type UserManagementAPI = {
  editUser: (
    userId: string,
    edit: Partial<User>
  ) => Promise<HttpFetchResult<User>>;
  fetchUsers: (params: {
    limit: number;
    offset: number;
    search?: string;
  }) => Promise<HttpFetchResult<RES_AdminUser>>;
};

export const useUserManagement = () => {
  const sendRequest = useAuthRequestSender();

  const editUser = useCallback(
    (userId: string, edit: Partial<User>) => {
      return sendRequest<RES_AdminUser, Partial<User>>(`${baseUrl}/${userId}`, {
        body: edit,
        method: "PATCH",
      });
    },
    [sendRequest]
  );

  const getUsers = useCallback(
    (params: { limit: number; offset: number; search?: string }) => {
      const p = urlSearchParamsFromObj(params);
      return sendRequest<RES_AdminUsers>(`${baseUrl}?${p.toString()}`);
    },
    []
  );

  return useMemo(
    () => ({
      editUser,
      getUsers,
    }),
    [editUser, getUsers]
  );
};
