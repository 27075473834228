import React, { FC, ReactNode } from "react";
import { InputBase, InputWithLabelBase, Label } from "./styled";
import { FormGroup } from "../../FormGroup";
import { IconContainer } from "../../../IconContainer";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  icon?: ReactNode;
  label?: ReactNode;
  iconPosition?: "left" | "right";
};

export const Input: FC<InputProps> = (props) => {
  const { icon, label, iconPosition, ...htmlInputProps } = props;

  let input = <InputBase {...htmlInputProps} />;

  if (icon) {
    input = (
      <FormGroup row reverse={!iconPosition || iconPosition === "right"}>
        <IconContainer>
          {typeof icon === "string" ? <img src={icon} alt="icon" /> : icon}
        </IconContainer>
        {input}
      </FormGroup>
    );
  }

  if (label) {
    input = (
      <InputWithLabelBase>
        <Label>{label}</Label>
        {input}
      </InputWithLabelBase>
    );
  }

  return input;
};
