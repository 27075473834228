import React, { useContext } from "react";
import {
  CopilotSettingsEffectsContext,
  CopilotSettingsEffectsProvider,
} from "./copilot-settings-effect";
import {
  CopilotSettingsStateContext,
  CopilotSettingsStateProvider,
} from "./copilot-settings-state";

export const useCopilotSettingsStore = () => {
  const store = useContext(CopilotSettingsStateContext);
  const effects = useContext(CopilotSettingsEffectsContext);

  return {
    store,
    effects,
  };
};

export const CopilotSettingsStoreProvider = ({ children }) => {
  return (
    <CopilotSettingsStateProvider>
      <CopilotSettingsEffectsProvider>
        {children}
      </CopilotSettingsEffectsProvider>
    </CopilotSettingsStateProvider>
  );
};
