import React, { FC } from "react";
import { FilterActionsBase, FilterPanelBase } from "./styled";
import { FilterOption, SelectedFilterProps } from "../../../../hooks/filter";
import { FilterControls } from "../FilterControls";
import { Button } from "../../../Button";

export type FilterPanelProps = {
  options?: Array<FilterOption>;
  onFilterChange: (newOptions: SelectedFilterProps) => void;
  onClose?: () => void;
  filters?: SelectedFilterProps;
  resultsCount?: number;
};

export const FilterPanel: FC<FilterPanelProps> = ({
  options,
  filters,
  onFilterChange,
  onClose,
  resultsCount,
}) => {
  return (
    <FilterPanelBase>
      <h3>Filter</h3>
      <FilterControls
        options={options}
        filters={filters}
        onFilterChange={onFilterChange}
      />
      <FilterActionsBase>
        <span
          className="link"
          onClick={(e) => {
            e.preventDefault();
            onFilterChange({});
          }}
        >
          Reset Filter
        </span>
        <Button
          variant="primary"
          label={`Show ${resultsCount} Result${
            typeof resultsCount === "number" && resultsCount > 1 ? "s" : ""
          }`}
          onClick={() => {
            onClose && onClose();
          }}
        />
      </FilterActionsBase>
    </FilterPanelBase>
  );
};
