import styled from "styled-components";
import { FilterPanelBase } from "../FilterPanel/styled";

export const FilterControlWrapper = styled.div`
  display: flex;
  width: 45%;
  height: fit-content;
`;

export const FilterControlsWrapper = styled(FilterPanelBase)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;

  flex-wrap: wrap;
  box-shadow: none;
  position: relative;
  top: 0;
  left: 0;

  padding: 0;
  height: 100%;
  width: 100%;
  background: inherit;
  border-radius: none;
  overflow-y: auto;
`;
