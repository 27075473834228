import React, { useCallback } from "react";
import {
  CLUSTERING_AI_MODES,
  ClusteringCopilotMode,
} from "../../../../../../../main-service/src/types/shared/settings";
import { Store } from "../../../../../context/stores/copilot-settings";
import { SelectItemForm } from "../../../../../components/ConfigurationPanel/SelectItemForm";

export const CopilotMode = () => {
  const { store, effects } = Store.useCopilotSettingsStore();
  const [data] = store;

  const options = CLUSTERING_AI_MODES;

  const handleOptionChange = useCallback(
    (value: string) => {
      effects.updateMode(value as ClusteringCopilotMode);
    },
    [effects.updateMode]
  );

  return (
    <SelectItemForm
      label="Mode"
      defaultValue={data?.settings?.value?.mode}
      options={options}
      onChange={handleOptionChange}
    />
  );
};
