import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from "react";
import mixpanel from "mixpanel-browser";

import { MixPanelEvent } from "../../../modules/mixpanel";
import { AuthEffects, LoginRes } from "./types";
import { AuthStateContext } from "./auth-state";
import { getDefaultAuthEffects } from "./helpers";
import { AuthAPI } from "../../../api";

const getPersistedLoginState = (): LoginRes => {
  const persistedCred = localStorage.getItem("comxloginstate");

  if (!persistedCred) {
    return {} as LoginRes;
  }

  try {
    return JSON.parse(persistedCred!);
  } catch (e) {
    localStorage.removeItem("comxloginstate");

    return {} as LoginRes;
  }
};

export const AuthEffectsContext = createContext<AuthEffects>(
  getDefaultAuthEffects()
);

const useAuthEffects = (): AuthEffects => {
  const [_, reducer] = useContext(AuthStateContext);

  const authAPI = AuthAPI.useAuth();

  useEffect(() => {
    const credentials = getPersistedLoginState();

    authAPI.checkAuth(credentials.accessToken).then((res) => {
      if (res.error) {
        reducer.err(res.message || "Auth check failed");
        reducer.setInitialLoad(false);

        return;
      }

      reducer.setAuth(credentials);
      reducer.setInitialLoad(false);
    });
  }, [authAPI.checkAuth, reducer.setAuth, reducer.setInitialLoad]);

  const login: AuthEffects["login"] = useCallback(
    async (idToken: string) => {
      try {
        reducer.loading();
        const res = await authAPI.login(idToken);
        if (res.error) {
          throw res.error;
        }

        const date = new Date();
        const eAt = date.setHours(date.getHours() + 1);
        const userData = { ...res.data, eAt };

        localStorage.setItem("comxloginstate", JSON.stringify(userData));
        reducer.setAuth(userData);

        mixpanel.identify(userData?.email);
        mixpanel.track(MixPanelEvent.LOGIN);
      } catch (error) {
        localStorage.removeItem("comxloginstate");
        reducer.err(error.message);
      }
    },
    [authAPI.login, reducer.setAuth, reducer.err, reducer.loading]
  );

  const logout: AuthEffects["logout"] = useCallback(async () => {
    const res = await authAPI.logout();
    if (!res.error) {
      mixpanel.track(MixPanelEvent.LOGOUT);
    }

    reducer.removeAuth();
    localStorage.removeItem("comxloginstate");
  }, [reducer.removeAuth, authAPI.logout]);

  const effects: AuthEffects = {
    login,
    logout,
  };

  return useMemo(() => effects, [login, logout]);
};

export const AuthEffectsProvider = ({ children }) => {
  const value = useAuthEffects();

  return (
    <AuthEffectsContext.Provider value={value}>
      {children}
    </AuthEffectsContext.Provider>
  );
};
