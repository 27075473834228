import Smile from "~/src/assets/smile.svg";
import Meh from "~/src/assets/meh.svg";
import Frown from "~/src/assets/frown.svg";
import { TCategorizationOptions } from "../../components/Options";

export const clusteringCategories: TCategorizationOptions[] = [
  {
    name: "Positive",
    color: "green",
    icon: Smile,
    options: [
      {
        name: "Meeting Request",
        tip: "Interested, meeting, call me, let’s meet",
      },
    ],
  },
  {
    name: "Neutral",
    color: "yellow",
    icon: Meh,
    options: [
      { name: "Referral in Email", tip: "referral name, email address" },
      { name: "Referral in CC", tip: "cc’d, copied in, included here" },
      {
        name: "Has a Question",
        tip: "info requested, any question asked, I am the right person",
      },
      {
        name: "Contact Later",
        tip: "follow up date provided, contact next year",
      },
    ],
  },
  {
    name: "Negative",
    color: "cold-grey",
    icon: Frown,
    options: [
      {
        name: "Not Relevant with Reason",
        tip: "budget, capacity, not relevant to field",
      },
      {
        name: "Not Relevant without Reason",
        tip: "no reason, referred to generic email or different department/ office",
      },
      {
        name: "Other Solution in Place",
        tip: "in house solution, company or product name",
      },
      {
        name: "Please Delete my Data",
        subOptions: [
          { name: "Angry", tip: "Legal action / GDPR law" },
          { name: "Not Angry", tip: "Unsubscribe, opt-out" },
        ],
      },
      {
        name: "Where did you get my Data?",
        subOptions: [
          { name: "Angry", tip: "GDPR law / legal action" },
          { name: "Not Angry" },
        ],
      },
    ],
  },
];
