import React, { createContext, useCallback, useMemo, useState } from "react";

import { Role } from "../../../../../main-service/src/types/shared/shared";
import {
  AuthReducer,
  AuthStateContext as ASC,
  LoginRes,
  AuthState,
} from "./types";

import { getDefaultAuthenticationContext } from "./helpers";

export const AuthStateContext = createContext<ASC>(
  getDefaultAuthenticationContext()
);

const useAuth = (): ASC => {
  const [isAutoSignIn, setIsAutoSignIn] = useState(false);
  const [credentials, setCredentials] = useState<Partial<LoginRes>>({});
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [authError, setAuthError] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);

  // FIX ME: PROPER AUTHORIZATION CHECK IN THE BACKEND
  const isAuthorized: AuthReducer["isAuthorized"] = useCallback(
    (role: string) => {
      return role === credentials.role || credentials.role === Role.ADMIN;
    },
    [credentials.role]
  );

  const getAuthHeaderValue = useCallback(() => {
    return `Bearer ${credentials.accessToken}`;
  }, [credentials.accessToken]);

  const bearerToken = getAuthHeaderValue();

  const loading = useCallback(() => {
    setIsLoading(true);
    setAuthError("");
  }, []);

  const err = useCallback((message: string) => {
    setIsLoading(false);
    setIsAuthenticated(false);
    setAuthError(message);
  }, []);

  const setAuth: AuthReducer["setAuth"] = useCallback((cred: LoginRes) => {
    setCredentials(cred || {});

    setIsAuthenticated(!!cred?.accessToken);
    setIsLoading(false);
    setAuthError("");
    setInitialLoad(false);
  }, []);

  const removeAuth = useCallback(() => {
    setCredentials({});
    setIsAuthenticated(false);
    setIsLoading(false);
    setAuthError("");
    setInitialLoad(false);
  }, []);

  const state: AuthState = useMemo(
    () => ({
      credentials,
      isLoading,
      initialLoad,
      isAutoSignIn,
      isAuthenticated,
      bearerToken,
      authError,
    }),
    [
      credentials,
      isLoading,
      initialLoad,
      isAutoSignIn,
      bearerToken,
      isAuthenticated,
      authError,
    ]
  );

  const reducer: AuthReducer = useMemo(
    () => ({
      isAuthorized,
      getAuthHeaderValue,
      loading,
      setAuth,
      removeAuth,
      err,
      setInitialLoad,
    }),
    [
      isAuthorized,
      getAuthHeaderValue,
      loading,
      removeAuth,
      setAuth,
      err,
      setInitialLoad,
    ]
  );

  return useMemo(() => [state, reducer], [state, reducer]);
};

export const AuthStateProvider = ({ children }) => {
  const auth = useAuth();

  return (
    <AuthStateContext.Provider value={auth}>
      {children}
    </AuthStateContext.Provider>
  );
};
