import { Res_CopilotSettings } from "../../../../../main-service/src/types/shared/api/admin";
import { PromptConfig } from "../../../../../main-service/src/types/shared/clustering";
import { ClusteringCopilotMode } from "../../../../../main-service/src/types/shared/settings";
import { PromptTestResultRes } from "../../../api/clustering-ai";

type Settings = Res_CopilotSettings["data"];
export type CopilotSettingsState = {
  isUpdatingEnabledState?: boolean | null;
  error: string;
  isLoading: boolean;
  settings?: Settings | null;
  fullPrompt: string;
  promptTestingResultStatus: { error: string; isLoading: boolean };
  promptTestingResult?: PromptTestResultRes["data"];
  isBasicSettingsLoading: boolean | undefined;
};

export type CopilotSettingsActions = {
  setPromptTestResult: (result: PromptTestResultRes["data"]) => void;
  setCopilotSettings(data: Res_CopilotSettings["data"]): void;
  error(message?: string): void;
  loading(): void;
  setFullPrompt: React.Dispatch<React.SetStateAction<string>>;
  setIsBasicSettingsLoading: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  setPromptTestingResultStatus: React.Dispatch<
    React.SetStateAction<{
      isLoading: boolean;
      error: string;
    }>
  >;
};
export type CopilotSettingsStateContext = [
  CopilotSettingsState,
  CopilotSettingsActions
];

export type CopilotSettingsEFfectsContext = {
  testPrompt(email: string): void;
  updatePrompt: (prompt: Partial<PromptConfig>) => void;
  updateAgentEmail(arg0: string): void;
  setHintEnabled(enabled: boolean): void;
  updateConfidenceLevelToAct(value: number): void;
  updateMode(value: ClusteringCopilotMode): void;
  setCopilotEnabled(enabled: boolean): void;
  fetchSettings: () => Promise<void>;
};
