import React from "react";
import { FC, useCallback, useState } from "react";
import { useCopilotSettingsStore } from "../../../../../../context/stores/copilot-settings/copilot-settings-store";
import { PromptExampleProps } from "./PromptExample";
import { ExampleResultProps } from "./PromptExampleResult";
import { Backdrop } from "../../../../../../components/Backdrop";
import { BasicSelect } from "../../../../../../components/BasicSelect";
import { StyledTextAreaItem } from "../../../../../../components/ConfigurationPanel/TextAreaItemForm/styled";
import { FormBase, Button } from "../../../styled";
import { ExampleFormWrapper } from "../../styled";

export const ExampleForm: FC<{
  isSubmitting?: boolean;
  onClose: (e: React.SyntheticEvent) => void;
  onSubmit: (example: PromptExampleProps) => void;
}> = ({ isSubmitting, onClose, onSubmit }) => {
  const { store } = useCopilotSettingsStore();
  const [state] = store;

  const [email, setEmail] = useState("");
  const [confidence, setConfidence] = useState(0);
  const [sentiment, setSentiment] = useState(1);
  const [error, setError] = useState("");

  const submit = useCallback(() => {
    try {
      if (!confidence) {
        setError("confidence required");
        return;
      }

      if (confidence < 1 || confidence > 100) {
        setError("confidence must be a number between 1 and 100");
        return;
      }
      if (!sentiment) {
        setError("category required");
        return;
      }

      if (!email) {
        setError("email required");
        return;
      }

      const res: ExampleResultProps = {
        percentageConfidenceLevel: confidence,
        sentiment,
      };

      onSubmit({
        email,
        categorization: JSON.stringify(res),
      });
    } catch (error) {
      setError("something went wrong when encoding example's result");
    }
  }, [email, confidence, sentiment, onSubmit]);
  const btnLabel = !isSubmitting ? "Submit" : "Submitting...";

  const handleEmailChange = useCallback((e: React.BaseSyntheticEvent) => {
    setEmail(e.target.value);
  }, []);

  const handleConfidenceChange = useCallback((e: React.BaseSyntheticEvent) => {
    if (typeof e.target.value === "string") {
      setConfidence(Number.parseInt(e.target.value, 10));
      return;
    }

    setConfidence(e.target.value);
  }, []);

  const handleSentimentChange = useCallback((value: string) => {
    const getSentimentNumber = () => {
      const num = value.split(".")[0];
      const n = Number.parseInt(num);

      return n;
    };

    setSentiment(getSentimentNumber());
  }, []);

  return (
    <Backdrop onClick={onClose}>
      <FormBase
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ExampleFormWrapper>
          <form>
            <div>
              <h3>Add Example</h3>
              <span className="error">{error}</span>
            </div>
            <div>
              <h4>Sample Email</h4>
              <StyledTextAreaItem
                minHeight="4rem"
                onChange={handleEmailChange}
              />
              <h5>Confidence</h5>
              <input
                name="percentageConfidenceLevel"
                min="0"
                max="100"
                onChange={handleConfidenceChange}
                type="number"
              />
            </div>

            <div>
              <h5>Categories</h5>
              <BasicSelect
                options={state?.settings?.value?.prompt?.categories || []}
                placeholder="Please select a category"
                onChange={handleSentimentChange}
              />
            </div>

            <Button type="button" onClick={submit}>
              {btnLabel}
            </Button>
          </form>
        </ExampleFormWrapper>
      </FormBase>
    </Backdrop>
  );
};
