import React, { useCallback, useEffect, useRef, useState } from "react";
import { ClockBase, ClockDrawing, ClockText } from "./styled";

type ClockProps = {
  variant: "countdown" | "stopwatch";
  seconds: number;
  className?: string;
  onStop: () => void;
};

export const Clock = (props: ClockProps) => {
  const { className, seconds, variant, onStop } = props;
  const element = useRef<HTMLCanvasElement>(null);
  const timer = useRef<number>();
  const [timeValue, setTimeValue] = useState<[number, number, number]>([
    0, 0, 0,
  ]);

  const getTimeValue = (s: number): [number, number, number] => {
    return [Math.floor(s / 3600), Math.floor(s / 60), s % 60];
  };

  const getSeconds = (tv: [number, number, number]) => {
    return tv[0] * 3600 + tv[1] * 60 + tv[2];
  };

  const drawCountdown = useCallback(
    (limit: number) => {
      const ctx = element.current?.getContext?.("2d");
      if (ctx) {
        ctx.lineWidth = 10;
        ctx.lineCap = "round";
        renderClockBase(ctx);
        const _seconds = getSeconds(timeValue);
        if (!element.current || _seconds <= 0) return;
        let start = new Date().getTime();
        interpolate(ctx, start, 1000, _seconds * 1000, limit * 1000);
      }
    },
    [timeValue]
  );

  const interpolate = (
    ctx: CanvasRenderingContext2D,
    start: number,
    diff: number,
    current: number,
    limit: number
  ) => {
    const _s = new Date().getTime();
    if (_s >= start + diff - 1) return;
    const delta = _s - start;
    const rate = (2 * (current - delta)) / limit;
    renderClockBase(ctx);
    ctx.beginPath();
    ctx.strokeStyle = "#FF7F51";
    ctx.arc(140, 140, 130, -0.5 * Math.PI, (rate - 0.5) * Math.PI);
    ctx.stroke();
    requestAnimationFrame(() => interpolate(ctx, start, diff, current, limit));
  };

  const renderClockBase = (ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, 280, 280);
    ctx.beginPath();
    ctx.strokeStyle = "#F7E0CF";
    ctx.arc(140, 140, 130, 0, 2 * Math.PI);
    ctx.stroke();
  };

  const startCountdown = useCallback(() => {
    let _seconds = seconds;
    if (timer.current) clearInterval(timer.current);
    timer.current = setInterval(() => {
      setTimeValue(getTimeValue(_seconds--));
      if (_seconds < 0) {
        clearInterval(timer.current);
        onStop && onStop();
      }
    }, 1000);
  }, [seconds]);

  const drawTimer = (_limit: number) => {
    // not implemented
  };

  useEffect(() => {
    let limit = variant === "countdown" ? seconds : 30 * 60;
    // how did this even work
    variant === "countdown" ? drawCountdown(limit) : drawTimer(limit);
  }, [timeValue]);

  useEffect(() => {
    if (variant === "countdown") {
      startCountdown();
    }
  }, [variant]);

  useEffect(() => {
    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, []);

  return (
    <ClockBase className={className}>
      <ClockDrawing ref={element} width="280" height="280"></ClockDrawing>
      <ClockText>{`${timeValue[0] > 9 ? "" : "0"}${timeValue[0]}:${
        timeValue[1] > 9 ? "" : "0"
      }${timeValue[1]}:${timeValue[2] > 9 ? "" : "0"}${
        timeValue[2]
      }`}</ClockText>
    </ClockBase>
  );
};
