import { useCallback } from "react";

import { ClusteringHintRes } from "../../context/stores/clustering/types";
import { useAuthRequestSender } from "../../hooks/http";

const baseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/clustering/copilot`;
export const useHint = () => {
  const sendRequest = useAuthRequestSender();

  const getClusteringHint = useCallback(
    (replyId: string) => {
      return sendRequest<ClusteringHintRes>(`${baseUrl}/hint/${replyId}`);
    },
    [sendRequest]
  );

  return getClusteringHint;
};
