import React, { FC, useEffect, useState } from "react";
import {
  ConfigurationBlockItemBase,
  Group,
} from "../../../../../../components/ConfigurationPanel/styled";
import { TextAreaItemForm } from "../../../../../../components/ConfigurationPanel/TextAreaItemForm";
import { PromptExampleResult } from "./PromptExampleResult";
import { DeleteButton } from "../../styled";

export type PromptExampleProps = {
  email: string;
  categorization: string;
  rescheduleDate?: string;
};

export const PromptExample: FC<
  PromptExampleProps & { id: number; onDelete: (id: number) => void }
> = ({ email, categorization, id, rescheduleDate, onDelete }) => {
  const [parsedResult, setParsedResult] = useState<{
    sentiment: number;
    percentageConfidenceLevel: number;
  }>();
  const [error, setError] = useState("");

  useEffect(() => {
    try {
      setParsedResult(JSON.parse(categorization));
    } catch (error) {
      setError(error.message);
    }
  }, [categorization]);

  return (
    <ConfigurationBlockItemBase>
      <TextAreaItemForm
        readOnly
        minHeight="6rem"
        defaultValue={error || email}
      />

      <Group>
        <PromptExampleResult
          percentageConfidenceLevel={parsedResult?.percentageConfidenceLevel}
          result={rescheduleDate || parsedResult?.sentiment}
        />
        <DeleteButton onClick={() => onDelete && onDelete(id)}>🗑️</DeleteButton>
      </Group>
    </ConfigurationBlockItemBase>
  );
};
