import React, { FC } from "react";
import { InputItemFormWrapper } from "./InputItemFormWrapper";
import { BasicSelect } from "../BasicSelect/index";

export const SelectItemForm: FC<{
  defaultValue?: string;
  label?: string;
  onChange?: (val: string) => void;
  options: ReadonlyArray<string>;
}> = ({ label, defaultValue, onChange, options }) => {
  return (
    <InputItemFormWrapper label={label}>
      <BasicSelect
        value={defaultValue}
        onChange={onChange}
        options={options as unknown as string[]}
      />
    </InputItemFormWrapper>
  );
};
