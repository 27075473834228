import React, { useCallback } from "react";
import { Store } from "../../../../../context/stores/copilot-settings";

import { InputItemForm } from "../../../../../components/ConfigurationPanel/TextInputItemForm";

export const CopilotAgentEmail = () => {
  const { store, effects } = Store.useCopilotSettingsStore();
  const [data] = store;

  const submit = useCallback(
    (val: string) => {
      effects.updateAgentEmail(val.trim());
    },
    [effects.updateAgentEmail]
  );

  return (
    <InputItemForm
      label="Agent Email"
      defaultValue={data.settings?.value?.agentEmail}
      placeholder="Enter a valid user email"
      name="agentEmail"
      type="text"
      onSubmit={submit}
    />
  );
};
