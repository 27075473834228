import React from "react";
import {
  ActionBase,
  ActionsBase,
  MainText,
  PopupBase,
  SubText,
} from "./styled";
import { Backdrop } from "../Backdrop";
import { DialogPopup as DP } from "./types";

const Action = (props: {
  value: string;
  type: "primary" | "secondary";
  onClick: () => void;
}) => {
  return (
    <ActionBase type={props.type} onClick={props.onClick}>
      {props.value}
    </ActionBase>
  );
};

export const DialogPopup = <T,>(props: DP<T>) => {
  return props?.isVisible ? (
    <Backdrop>
      <PopupBase id={props.id}>
        <MainText>{props.mainText}</MainText>
        {props.subText && <SubText>{props.subText}</SubText>}
        <ActionsBase>
          {props.actions.map((action) => (
            <Action
              key={action.name}
              value={action.name}
              type={action.type}
              onClick={() => props.onActionCall(action.key)}
            />
          ))}
        </ActionsBase>
      </PopupBase>
    </Backdrop>
  ) : null;
};
