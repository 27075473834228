import React, { FC } from "react";
import FilterIcon from "../../../assets/filter.svg";

import { FilterButton, FilterLabel } from "./styled";
import { IconContainer } from "../../IconContainer";
import { FilterProps } from "../../../hooks/filter";
import { FormGroup } from "../../Form/FormGroup";
import { Collapsible } from "../../Collapsible";
import { FilterPanel } from "./FilterPanel";

export const Filter: FC<FilterProps> = ({ actions, state }) => {
  const renderToggler = () => {
    return (
      <FilterButton>
        <IconContainer>
          <FilterIcon />
        </IconContainer>
        <FilterLabel>Filter</FilterLabel>
      </FilterButton>
    );
  };

  const renderCollapsible = (handleOnClose: (() => void) | undefined) => {
    return (
      <FilterPanel
        options={state?.options}
        onFilterChange={(newFilters) => {
          actions?.setFilters?.(newFilters);
        }}
        filters={state?.filters}
        onClose={handleOnClose}
        resultsCount={state?.resultsCount}
      />
    );
  };

  return (
    <FormGroup>
      <Collapsible
        renderToggler={renderToggler}
        renderCollapsible={renderCollapsible}
        collapseDefault={true}
      />
    </FormGroup>
  );
};
