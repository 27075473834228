import React, { FC } from "react";
import { Comparable, FilterOption } from "../../../../hooks/filter";
import { FilterOpt, FilterOptions } from "./styled";
import { Calendar } from "../../../Calendar";

export type FilterControlProps = {
  option: FilterOption;
  onOptionSelected?: (option: FilterOption, value: string | number) => void;
  onDateSelected?: (option: FilterOption, date?: Date | null) => void;
  isSelected?: boolean;
  search?: string;
  selectedOptions?: Comparable[];
};

export const FilterControl: FC<FilterControlProps> = ({
  option,
  onOptionSelected,
  onDateSelected,
  isSelected,
  search,
  selectedOptions,
}) => {
  const selected = selectedOptions || [];

  if (
    option.type === "select" ||
    option.type == "multiselect" ||
    !option.type
  ) {
    let optionValues = option?.getOptionValues?.() || [];

    if (search) {
      optionValues = optionValues.filter((optVal) => {
        if (typeof optVal == "string" || typeof optVal === "number") {
          return optVal.toString().toLowerCase().includes(search.toLowerCase());
        }

        return optVal.name.toLowerCase().includes(search.toLowerCase());
      });
    }

    return (
      <FilterOptions isSelected={isSelected}>
        {optionValues.map((value) => {
          if (typeof value === "string" || typeof value === "number") {
            return (
              <FilterOpt
                className={
                  selected.some(
                    (s) =>
                      s.toString().toLocaleLowerCase() ===
                      value.toString().toLowerCase()
                  )
                    ? "selected"
                    : ""
                }
                key={value}
                onClick={() => onOptionSelected(option, value)}
              >
                {value}
              </FilterOpt>
            );
          }

          const v = value as { id: string | number; name: string };

          return (
            <FilterOpt
            className={
              selected.some(
                (s) =>
                  s.toString().toLocaleLowerCase() ===
                  value.name.toString().toLowerCase()
              )
                ? "selected"
                : ""
            }
              key={v.id}
              onClick={() => onOptionSelected(option, v.name)}
            >
              {v.name}
            </FilterOpt>
          );
        })}
      </FilterOptions>
    );
  }

  return (
    <FilterOptions>
      <Calendar onDateSelect={(date) => onDateSelected(option, date)} />
    </FilterOptions>
  );
};
