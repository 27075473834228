import React, { useContext } from "react";
import { AuthStateContext, AuthStateProvider } from "./auth-state";
import { AuthEffectsContext, AuthEffectsProvider } from "./auth-effects";

export const useAuthStore = () => {
  const store = useContext(AuthStateContext);
  const effects = useContext(AuthEffectsContext);

  return {
    store,
    effects,
  };
};

export const AuthStoreProvider = ({ children }) => {
  return (
    <AuthStateProvider>
      <AuthEffectsProvider>{children}</AuthEffectsProvider>
    </AuthStateProvider>
  );
};
