import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { trackButtonClick } from "../../../../modules/mixpanel";
import { UseClusteringPageArgs } from "./types";
import {
  clusteringErrorPopupConfig,
  clusteringErrorsExitPopupConfig,
} from "./constants";
import { UsePopups } from "../../types";
import {
  CloseDialogKeys,
  DialogPopup,
} from "../../../../components/DialogPopup/types";
import { ClusteringTasks } from "../../../../../../main-service/src/types/shared/shared";
import { Store } from "../../../../context/stores/clustering";

// Todo:  this abstraction sucks. generalize for all clustering related pages later
export const useClusteringPageDialogs: UsePopups<UseClusteringPageArgs> = (
  args
) => {
  const { effects } = Store.useClusteringStore();

  const navigate = useNavigate();

  const handleStopSelected = useCallback(async () => {
    const stopClustering = async () => {
      if (args.replyId) {
        await args.unassign(ClusteringTasks.CLUSTERING, args.replyId);
      }

      trackButtonClick({ name: "stop_clustering" });

      navigate("/landing");
    };

    return await stopClustering();
  }, [args.unassign, args.replyId]);

  const handlePageExitConfirmationDialogActions = useCallback(
    (key: CloseDialogKeys) => {
      switch (key) {
        case CloseDialogKeys.GO_BACK:
          args.setPageExitPopupVisible(false);
          break;
        case CloseDialogKeys.STOP:
          handleStopSelected();
          break;
      }
    },
    [handleStopSelected]
  );

  const handleClusteringErrorDialogActions = useCallback(
    (key: CloseDialogKeys) => {
      switch (key) {
        case CloseDialogKeys.GO_BACK:
          break;
        case CloseDialogKeys.STOP:
          effects.assign(ClusteringTasks.CLUSTERING, "");
      }
    },
    [effects.assign]
  );

  const clusteringExitPopup: DialogPopup<CloseDialogKeys> = useMemo(
    () => ({
      ...clusteringErrorsExitPopupConfig,
      isVisible: args.pageExitPopupVisible,
      onActionCall: handlePageExitConfirmationDialogActions,
    }),
    [handlePageExitConfirmationDialogActions, args.pageExitPopupVisible]
  );

  const clusteringErrorPopup: DialogPopup<CloseDialogKeys> = useMemo(
    () => ({
      isVisible: !!args.error,
      ...clusteringErrorPopupConfig,
      mainText: args.error || "",
      onActionCall: handleClusteringErrorDialogActions,
    }),
    [handleClusteringErrorDialogActions, args.error]
  );

  const popups: DialogPopup<CloseDialogKeys>[] = useMemo(
    () => [clusteringErrorPopup, clusteringExitPopup],
    [clusteringErrorPopup.isVisible, clusteringExitPopup.isVisible]
  );

  return popups;
};
