import React, { useCallback } from "react";

import { ReplyViewClusteringHistory } from "./ReplyClusteringHistory";
import { ReplyPane } from "../../../components/ReplyPane/index";
import { useReplyViewActions } from "../../../hooks/reply-actions";

export const ReplyView: React.FC<{
  onReplyActionDone: () => Promise<void>;
}> = ({ onReplyActionDone }) => {
  const {
    setAutoReplyToNormalReply,
    setNormalReplyToAutoReply,
    setReplyToBounced,
    toggleOriginalMessage,
    toggleTranslation,
  } = useReplyViewActions();
  const onSetAsAutoReply = useCallback(
    async (replyId?: string) => {
      await setNormalReplyToAutoReply(replyId || "", onReplyActionDone);
    },
    [setNormalReplyToAutoReply, onReplyActionDone]
  );

  const onSetAsNormalReply = useCallback(
    async (replyId?: string) => {
      await setAutoReplyToNormalReply(replyId || "", onReplyActionDone);
    },
    [setNormalReplyToAutoReply, onReplyActionDone]
  );

  const onSetAsBounced = useCallback(
    async (replyId?: string) => {
      await setReplyToBounced(replyId || "", onReplyActionDone);
    },
    [setReplyToBounced, onReplyActionDone]
  );

  return (
    <>
      <ReplyViewClusteringHistory />
      <ReplyPane
        toggleOriginalMessage={toggleOriginalMessage}
        toggleTranslation={toggleTranslation}
        onSetAutoReplyToNormalReply={onSetAsNormalReply}
        onSetNormalReplyToAutoReply={onSetAsAutoReply}
        onSetReplyToBounced={onSetAsBounced}
      />
    </>
  );
};
