import React from "react";
import styled from "styled-components";

export const LandingIntroBase = styled.div`
	flex: 1 1 auto;
	display: flex;
	grid-gap: 24px;
`;

export const AsideImageBase = styled.div`
	max-width: 573px;
	overflow: hidden;
	border-radius: 16px;
	background: #f7e0cf;
	width: 100%;

	& > svg {
		height: 100%;
	}
`;

export const ContentBase = styled.div`
	background: #fff;
	border-radius: 16px;
	display: flex;
	flex: 1 1 auto;
	padding: 64px;
	align-items: center;
	justify-content: center;
`;

export const MessageAreaBase = styled.div`
	display: flex;
	flex-flow: column;
	max-width: 767px;
`;

export const PersonalMessage = styled.h2`
	font-size: 24px;
	font-weight: 300;
	text-transform: uppercase;
	color: #818888;
`;

export const Quote = styled.div`
	font-size: 48px;
	font-weight: 500;
	margin: 48px 0 120px 0;
	display: flex;
	flex-flow: column;

	&> span {
		font-size: 32px;
		margin-top: 48px;
	}
`;
