import {
  CloseDialogKeys,
  DialogBoxActions,
} from "../../components/DialogPopup/types";

export const closeDialogActions: DialogBoxActions = [
  {
    name: "Back to Clustering",
    type: "secondary",
    key: CloseDialogKeys.GO_BACK,
  },
  {
    name: "Stop Clustering",
    type: "primary",
    key: CloseDialogKeys.STOP,
  },
];

export const closeClusteringErrorFixesDialogActions: DialogBoxActions = [
  {
    name: "Back to Fixing Errors",
    type: "secondary",
    key: CloseDialogKeys.GO_BACK,
  },
  {
    name: "Stop Fixing Errors",
    type: "primary",
    key: CloseDialogKeys.STOP,
  },
];

export const reclusteringCloseDialogActions: DialogBoxActions = [
  {
    name: "Back to Reclustering",
    type: "secondary",
    key: CloseDialogKeys.GO_BACK,
  },
  {
    name: "Leave Without Saving",
    type: "primary",
    key: CloseDialogKeys.STOP,
  },
];

export const errorDialogActions: DialogBoxActions = [
  {
    name: "Back",
    type: "secondary",
    key: CloseDialogKeys.GO_BACK,
  },
  {
    name: "Next",
    type: "primary",
    key: CloseDialogKeys.STOP,
  },
];

export const clusteringErrorFixingErrorDialogActions: DialogBoxActions = [
  {
    name: "Back",
    type: "secondary",
    key: CloseDialogKeys.GO_BACK,
  },
  {
    name: "Next",
    type: "primary",
    key: CloseDialogKeys.STOP,
  },
];

export const reclusteringErrorActions: DialogBoxActions = [
  {
    name: "Back",
    type: "secondary",
    key: CloseDialogKeys.GO_BACK,
  },
];

export const reclusteringDoneActions: DialogBoxActions = [
  {
    name: "Go to Feeds",
    type: "primary",
    key: CloseDialogKeys.STOP,
  },
];

export const clusteringAIConflictActions: DialogBoxActions = [
  {
    name: "Back",
    type: "secondary",
    key: CloseDialogKeys.GO_BACK,
  },
  {
    name: "I'm sure",
    type: "primary",
    key: CloseDialogKeys.STOP,
  },
];

export enum ClusteringPages {
  CLUSTERING = "CLUSTERING",
  RECLUSTERING = "RECLUSTERING",
}

export const INACTIVITY_WARNING_IN_MS = 5 * 60_000; // 5 minutes
export const CLUSTERING_TIMEOUT_IN_MS = 30_000;
