import styled from "styled-components";

export const HeaderBase = styled.header`
  display: grid;
  grid-template-columns: auto auto min-content;
  border-bottom: 1px solid #dcdddd;
  align-items: center;
  position: relative;
`;

export const UserIconBase = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.2, 1.2);
  }
`;

export const UserInfoArea = styled.div`
  display: flex;
`;

export const UserOptionsBase = styled.div`
  font-weight: 500;

  & > .user-name {
    text-align: center;
    font-size: 14px;
    color: #0b0b0c;
  }
  & > .user-email {
    text-align: center;
    color: #7f92a3;
    font-size: 12px;
    margin: 4px 0 8px;
    display: block;
  }
`;

export const SessionNotification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0.5rem;
  color: #ff6731;
  > span {
    font-size: 1.1rem;
    font-weight: 600;
    padding: 0.5rem;
  }
`;
