import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import LandingIntroImage from "../../assets/landing-post-login.svg";
import {
  AsideImageBase,
  LandingIntroBase,
  ContentBase,
  PersonalMessage,
  Quote,
  MessageAreaBase,
} from "./styled";
import { Button } from "../../components";
import { trackButtonClick } from "../../modules/mixpanel";
import {
  ButtonsWrapper,
  SecondaryButton,
} from "../../components/MessagePane/styled";
import { GlobalContext } from "../../context/global";
import { useAuthStore } from "../../context/stores/auth/auth-store";
import { Role } from "../../../../main-service/src/types/shared/shared";

const LoginLandingContent = () => {
  const { store } = useAuthStore();
  const [state, reducer] = store;

  const {
    globalState: { clusteringErrorsCount },
  } = useContext(GlobalContext);
  const navigate = useNavigate();

  const startClustering = () => {
    trackButtonClick({ name: "start_clustering" });
    navigate("/clustering");
  };

  const startFixingErrors = () => {
    trackButtonClick({ name: "start_fixing_clustering_errors" });
    navigate("/clustering/errors");
  };
  return (
    <MessageAreaBase>
      <PersonalMessage>
        Hi {state.credentials.given_name}, Welcome Aboard!
      </PersonalMessage>
      <Quote>
        The best preparation for tomorrow is doing your best today.
        <span>- H. Jackson Brown</span>
      </Quote>
      <ButtonsWrapper>
        <Button
          variant="primary"
          label="Start Clustering"
          onClick={() => startClustering()}
        />
        {clusteringErrorsCount && reducer.isAuthorized(Role.CS) ? (
          <SecondaryButton
            onClick={() => {
              startFixingErrors();
            }}
          >
            Fix Clustering Errors ({clusteringErrorsCount})
          </SecondaryButton>
        ) : null}
      </ButtonsWrapper>
    </MessageAreaBase>
  );
};

export const LandingIntro = () => {
  return (
    <LandingIntroBase>
      <AsideImageBase>
        <LandingIntroImage />
      </AsideImageBase>
      <ContentBase>
        <LoginLandingContent />
      </ContentBase>
    </LandingIntroBase>
  );
};
