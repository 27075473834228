import React, { FC, useCallback, useMemo } from "react";

import { Button, TableBase } from "../styled";
import { RES_AdminUsers } from "../../../../../../main-service/src/types/shared/api";
import { DataTable } from "../../../../components/DataTable";
import { User } from "../../../../../../main-service/src/types/shared/shared";
import { Store } from "../../../../context/stores/user-management";
import { UserEditor } from "./UserEditor";

export const UserManagementTable: FC = () => {
  const { store, effects } = Store.useUserManagementStore();
  const [state, actions] = store;

  const rowIdIndex = "id";
  const cols = new Map([
    ["Name", { index: "name" }],
    ["Email", { index: "email" }],
    ["Role", { index: "role" }],
    ["Created At", { index: "created_at" }],
    ["Updated At", { index: "updated_at" }],
    [
      "actions",
      [
        {
          label: "View",
          render: (data: RES_AdminUsers["data"][0]) => {
            return (
              <Button
                onClick={() => {
                  actions.setUserToEdit(data);
                }}
              >
                Edit
              </Button>
            );
          },
        },
      ],
    ],
  ]);

  const submit = useCallback(
    (edit: Partial<User>) => {
      effects.editUser(edit);
    },
    [effects.editUser]
  );
  const closeEditor = useCallback(() => {
    actions.setUserToEdit(null);
  }, [actions.setAdminUsers]);

  const tableSearch = useMemo(
    () => ({ state: state.search, actions: actions.search }),
    [state.search, actions.search]
  );

  return (
    <TableBase>
      {state.adminUsers && (
        <DataTable
          rowIdIndex={rowIdIndex}
          title="Users"
          dataSource={state.adminUsers?.data}
          columns={cols}
          isLoading={state.isLoading}
          count={state.pagination?.totalRecords || 0}
          search={tableSearch}
          onSearchChange={actions?.search?.setSearch}
          onChange={actions.pagination.changePage}
        />
      )}
      {state.userToEdit && (
        <UserEditor
          user={state.userToEdit}
          error={state.editError}
          isSubmitting={state.isLoading}
          onClose={closeEditor}
          onSubmit={submit}
        />
      )}
    </TableBase>
  );
};

export const UserManagement: FC = () => {
  return (
    <Store.UserManagementStoreProvider>
      <UserManagementTable />
    </Store.UserManagementStoreProvider>
  );
};
