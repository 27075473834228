import { useMemo } from "react";
import { createRequestSenderWithAuth } from "./fetch/request-with-auth";
import { useAuthStore } from "../../context/stores/auth/auth-store";

export const useAuthRequestSender = () => {
  const { store } = useAuthStore();
  const [state] = store;


  const sendRequest = useMemo(
    () => createRequestSenderWithAuth(state.bearerToken),
    [state.bearerToken]
  );

  return sendRequest;
};
