// The Main Tabs Component
import React from "react";
import { TabBase, TabsBase } from "./styled";
import { NavLink } from "react-router-dom";
import { Role } from "../../../../main-service/src/types/shared/shared";
import { useAuthStore } from "../../context/stores/auth/auth-store";

type TabProps = {
  label: string;
  path: string;
  roles?: Role[];
};

type TabsProps = {
  tabs: Array<TabProps>;
};

const Tab = ({ label, path }: TabProps) => {
  return (
    <TabBase>
      <NavLink
        end
        to={path}
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        {label}
      </NavLink>
    </TabBase>
  );
};

export const Tabs = ({ tabs }: TabsProps) => {
  const { store } = useAuthStore()
  const [_, reducers] = store;

  return (
    <TabsBase>
      {tabs.map((t) => {
        if (t?.roles && !t.roles.some((role) => reducers.isAuthorized(role))) {
          return null;
        }
  
        return <Tab key={t.label} label={t.label} path={t.path} />;
      })}
    </TabsBase>
  );
};
