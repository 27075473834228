import {
  Reply,
  ReplyClusterContext,
  ReplyClusterState,
  TGetReplyViewClusteringHistoryFunc,
} from "./types";

export const _getReplyContent = (
  reply: Reply | null | undefined,
  showTranslated: boolean,
  showOriginal: boolean
) => {
  if (!showTranslated && showOriginal) {
    return reply?.wp_message || "";
  }

  const linkRegex =
    /(\b(https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;

  const content = showTranslated ? reply?.translated : reply?.message;

  return (
    content?.replace?.(linkRegex, '<a href="$1" target="_blank">$1</a>') || ""
  );
};

export const _getSenderInitials = (
  firstName: string[] | string,
  lastName: string[] | string
) => {
  let first = "";
  let last = "";
  if (Array.isArray(firstName)) {
    first = firstName[0];
  } else {
    first = firstName;
  }

  if (lastName && Array.isArray(lastName)) {
    last = lastName[0];
  } else {
    last = lastName;
  }

  return `${first[0]?.toUpperCase?.() || "."}${
    last[0]?.toUpperCase?.() || "."
  }`;
};

export const getReplyViewClusteringHistory: TGetReplyViewClusteringHistoryFunc =
  (history) => {
    if (!history) {
      return;
    }

    return history.map((log) => {
      return {
        id: `${log.id}-${log.created_at}`,
        avatar: log.picture
          ? log.picture
          : `${log.name.split(" ")[0][0]}${log.name.split(" ")[1][0]}`,
        name: log.name,
        date: log.created_at,
        clusteredTo: log.cluster_info?.category,
        isRescheduled: !!log.rescheduled,
        sendAfter: log.send_after || "",
      };
    });
  };

export const getIsRescheduling = (
  replyClusteringHistory: ReplyClusterState["replyClusteringHistory"],
  isAutoReply?: boolean
) => {
  const historyLength = replyClusteringHistory?.length || 0;
  const isRescheduling: ReplyClusterState["isRescheduling"] =
    isAutoReply || !!replyClusteringHistory?.[historyLength - 1]?.isRescheduled;

  return isRescheduling;
};

export const getDefaultReplyClusterContext = (): ReplyClusterContext => {
  return [
    {
      metadata: { isLoading: true, message: "", error: "" },
      reply: null,
      isAutoReply: false,
      isRescheduling: false,
      showOriginal: false,
      showTranslated: false,
      cluster: null,
      hint: null,
      nextScheduleDate: null,
      replyClusteringHistory: [],
      assignedOn: 0,
    },
    {
      setAssignedOn: () => {},
      setReply: () => {},
      getReplyContent: () => "",
      getSenderInitials: () => "",
      setCluster: () => {},
      setNextScheduleDate: () => {},
      setHint: () => {},
      setMetadata: () => {},
      resetAssignedOnAndRequestMeta: () => {},
      resetRequestMetadata: () => {},
      setReplyAndMetadata: () => {},
      setShowTranslated: () => {},
      setShowOriginal: () => {},
      setSuggestion: function (value: any): void {},
    },
  ];
};
