import { useCallback } from "react";
import { ClusteringMetricsAPI } from "../api";

export const useGetUnclusteredRepliesCount = () => {
  const metricsAPI = ClusteringMetricsAPI.useMetrics();

  const getUnclusteredRepliesCount = useCallback(async () => {
    const res = await metricsAPI.getUnclusteredRepliesCount();
    return res?.data?.count;
  }, [metricsAPI.getUnclusteredRepliesCount]);

  return getUnclusteredRepliesCount;
};
