import React from "react";
import styled from "styled-components";

export const LandingPageBase = styled.div`
	padding: 24px;
	display: flex;
	grid-gap: 14px;
	flex-flow: column;
	width: 100%;
`;
