import styled from 'styled-components';

export const ClockBase = styled.div`
	display: flex;
	position: relative;
	width: 280px;
	height: 280px;
	align-items: center;
	justify-content: center;
`;

export const ClockDrawing = styled.canvas`
	position: absolute;
`;

export const ClockText = styled.p`
	color: #FF7F51;
	font-size: 48px;
	font-weight: 500;
`;
