import {
  closeDialogActions,
  clusteringAIConflictActions,
  errorDialogActions,
} from "../../constants";

export const clusteringExitPopupConfig = {
  id: "clustering-exit",
  mainText: "Are you sure you want to stop?",
  subText: "What doesn't kill you makes you stronger",
  actions: closeDialogActions,
} as const;

export const clusteringErrorPopupConfig = {
  id: "clustering-error",
  mainText: "An error occurred while clustering",
  subtext: "",
  actions: errorDialogActions,
} as const;

export const clusteringHintPopupConfig = {
  id: "clustering-hint",
  mainText: "Are you sure that's the right category?",
  subText:
    "Excellence is in the details. Give attention to the details and excellence will come.",
  actions: clusteringAIConflictActions,
} as const;
