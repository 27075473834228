import React, { FC } from "react";
import { Button } from "../../../components";
import { SecondaryButton } from "../../../components/MessagePane/styled";
import { ClusteringViewActions } from "../ClusteringView/types";
import { ClusteringTasks } from "../../../../../main-service/src/types/shared/shared";

export const ClusteringActions: FC<ClusteringViewActions> = ({
  task,
  disableCompleteBtn,
  skip,
  complete,
}) => {
  let labels = {
    [ClusteringTasks.CLUSTERING]: "Cluster and Continue",
    [ClusteringTasks.RECLUSTERING]: "Recluster",
    [ClusteringTasks.RESCHEDULING]: "Reschedule Send out",
    [ClusteringTasks.ERROR_RESOLUTION]: "Submit to fix this error",
  };

  const label = labels[task] || labels[ClusteringTasks.CLUSTERING];

  return (
    <>
      {skip ? (
        <SecondaryButton onClick={skip}>Skip</SecondaryButton>
      ) : (
        <SecondaryButton></SecondaryButton>
      )}
      <Button
        disabled={disableCompleteBtn}
        variant="primary"
        label={label}
        onClick={complete}
      />
    </>
  );
};
