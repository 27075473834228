import React, { FC, useState, useCallback } from "react";
import { RES_AdminUsers } from "../../../../../../main-service/src/types/shared/api";
import { User } from "../../../../../../main-service/src/types/shared/shared";
import { Backdrop } from "../../../../components/Backdrop";
import { Input } from "../../../../components/Form/FormControls";
import { FormBase, Button } from "../styled";

export const UserEditor: FC<{
  user: RES_AdminUsers["data"][0];
  error?: string;
  isSubmitting?: boolean;
  onClose: (e: React.SyntheticEvent) => void;
  onSubmit: (edit: Partial<User>) => void;
}> = ({ user, error, isSubmitting, onClose, onSubmit }) => {
  const [edits, setEdits] = useState<Partial<User>>({ ...user });

  const editableFields = ["role", "name"];

  const getInputs = () => {
    return Object.keys(edits).map((key) => {
      const disabled = !editableFields.includes(key);

      return (
        <Input
          label={key.split("_").join(" ").toUpperCase()}
          key={key}
          name={key}
          disabled={disabled}
          value={edits[key]}
          onChange={(e) => {
            setEdits({ ...edits, [key]: e.target.value });
          }}
        />
      );
    });
  };

  const submit = useCallback(() => {
    onSubmit(edits);
  }, [edits, onSubmit]);
  const btnLabel = !isSubmitting ? "Submit" : "Submitting...";

  return (
    <Backdrop onClick={onClose}>
      <FormBase
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <form>
          <h4>Edit User</h4>
          <span className="error">{error}</span>
          {getInputs()}
          <Button type="button" onClick={submit}>
            {btnLabel}
          </Button>
        </form>
      </FormBase>
    </Backdrop>
  );
};
