import React, { FC, useCallback, useState } from "react";
import {
  ToggleButton,
  ToggleContainer,
  ToggleLabel,
  ToggleTextLeft,
  ToggleTextRight,
} from "./styled";

export const Toggle: FC<{
  leftLabel: string;
  rightLabel: string;
  onChange(isChecked: boolean): void;
  defaultIsChecked?: boolean;
  checkedColor?: string;
  uncheckedColor?: string;
}> = ({
  defaultIsChecked,
  onChange,
  leftLabel,
  rightLabel,
  checkedColor,
  uncheckedColor,
}) => {
  const [isToggled, setIsToggled] = useState(defaultIsChecked);
  const handleToggle = useCallback(
    (e: React.SyntheticEvent) => {
      setIsToggled(!isToggled);
      onChange && onChange(!isToggled);
    },
    [onChange, isToggled]
  );

  return (
    <ToggleContainer>
      <ToggleLabel onClick={handleToggle}>
        <ToggleButton
          checked={!!isToggled}
          onClick={handleToggle}
          checkedColor={checkedColor}
          uncheckedColor={uncheckedColor}
        />
        <ToggleTextLeft visible>{leftLabel}</ToggleTextLeft>
        <ToggleTextRight visible>{rightLabel}</ToggleTextRight>
      </ToggleLabel>
    </ToggleContainer>
  );
};
