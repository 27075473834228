import React from 'react';
import { ContentCardBody } from './ContentCardBody';
import { ContentCardHeader } from './ContentCardHeader';
import { ContentCardBase } from './styled';

export type ContentCard = {
	isLoading: boolean,
	data: {
		actions?: ContentCardHeader['actions'],
		header: ContentCardHeader['content'],
		body?: ContentCardBody['content']
	}
}

export const ContentCard = ({ isLoading, data }: ContentCard) => {
	return <ContentCardBase>
		<ContentCardHeader isLoading={isLoading} content={data.header} actions={data.actions} />
		{data.body && <ContentCardBody isLoading={isLoading} content={data.body} />}
	</ContentCardBase>
}
