import styled from "styled-components";

export const OptionsBase = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
`;

export const OptionBase = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  padding: 16px;
  background: #fff;
  border: 1px solid
    ${(props) =>
      props.color ? props.theme.colors[props.color][100] : "#EDEEEE"};
  border-radius: 8px;
  grid-gap: 0 80px;
  cursor: pointer;
  &.disabled {
    cursor: none;
  }
  &.selected {
    border: 1px solid
      ${(props) =>
        props.color ? props.theme.colors[props.color][600] : "#535C5C"};
    background: ${(props) =>
      props.color ? props.theme.colors[props.color][50] : "#EDEEEE"};
  }

  &.suggested {
    border: 1px solid
      ${(props) =>
        props.color ? props.theme.colors[props.color][400] : "#535C5C"};
    background: ${(props) =>
      props.color ? props.theme.colors[props.color][25] : "#EDEEEE"};
  }

  & label .info {
    display: inline-flex;
    /* justify-content: center; */
    align-items: center;
    font-size: 0.65rem;
    /* width: 1rem;
    height: 1.2rem; */
    /* border: 1px solid blue; */
    text-align: center;
    margin-right: 8px;
    /* padding:  .2rem; */
    color: ${(props) =>
      props.color ? props.theme.colors[props.color][600] : "#535C5C"};
    border-radius: 100%;
  }
  &:hover {
    background: ${(props) =>
      props.color ? props.theme.colors[props.color][50] : "#EDEEEE"};
  }
  &:hover > .options,
  &.selected > .options {
    max-height: 50px;
    padding-top: 16px;
  }
  & > label {
    cursor: inherit;
    flex-grow: 1;
    font-size: 14px;
  }
  & > .outer-circle {
    display: inline-block;
    justify-self: end;
    border-radius: 16px;
    box-sizing: border-box;
    border: 2px solid
      ${(props) =>
        props.color ? props.theme.colors[props.color][100] : "#EDEEEE"};
    width: 16px;
    height: 16px;
    &.selected {
      border: 4px solid
        ${(props) =>
          props.color ? props.theme.colors[props.color][600] : "#535C5C"};
    }
  }

  &:hover .tooltip {
    visibility: visible;
    transition: visibility 0.3s ease;
  }

  .tooltip {
    display: inline-block;
    position: relative;
    visibility: hidden;
    width: fit-content;

    .icon {
      padding: 1px 6px;
      border: 1px solid #000;
      border-radius: 100%;
      background: inherit;
      color: #000;
      font-style: italic;
      text-align: center;
    }
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -145px;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;

    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    max-width: 20px;
    border-color: transparent transparent black transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    transition: visibility 0.5s ease;
  }
`;

export const OptionGroup = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
`;

export const OptionGroupHeaderBase = styled.div`
  display: flex;
  grid-gap: 8px;
  align-items: center;
  & > h4 {
    font-weight: 400;
  }
`;

export const SubOptionsBase = styled.div`
  max-height: 0;
  padding-top: 0;
  display: flex;
  grid-column: 1/-1;
  grid-gap: 8px;
  transition: all 0.3s;
  overflow: hidden;
  &:hover {
    overflow: visible;
  }

  cursor: inherit;
  & > button {
    cursor: inherit;
    flex: 1 1 auto;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    background: #fff;
    &.selected {
      background: ${(props) =>
        props.color ? props.theme.colors[props.color][600] : "#535c5c"};
      color: #fff;
    }
  }
`;

export const IconBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  border-radius: 8px;
  background: ${(props) =>
    props.color ? props.theme.colors[props.color][100] : "#dcdddd"};
  & > * {
    width: 16px;
    height: 16px;
  }

  &.suggested {
    background: ${(props) =>
      props.color ? props.theme.colors[props.color][50] : "#dcdddd"};
  }
`;
