import styled from 'styled-components';

export const MenuContainerBase = styled.div`
	background: #fff;
	padding: 16px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 16px;

	min-width: 172px;
	position: absolute;
	top: 95%;
	right: 16px;
	
	font-weight: 500;
	z-index: 1000;
`;

export const MenuBase = styled.div`
	background: #fff;
	padding: 0 16px;
	display: flex;
	flex-flow: column;
`;

export const MenuItemBase = styled.button`
	border: none;
	background: none;
	text-align: left;
	border-bottom: 1px solid #edeeee;
	font-size: 14px;
	font-weight: 500;
	color: #0B0B0C;
	cursor: pointer;
	padding: 10px 0;

	&:disabled {
		opacity: 0.5;
		cursor: inherit;
		&:hover {
			color: inherit;
		}	
	}
	
	&:hover {
		color: #ff6731;
	}

	&:last-of-type {
		border: 0;
	}
`;

