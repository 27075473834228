import React, { FC, useCallback, useState } from "react";
import { Button, ConfigurationBlockItemBase } from "../styled";
import { StyledTextAreaItem } from "./styled";

export const TextAreaItemForm: FC<{
  onSubmit?: (val: string) => void;
  defaultValue?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  minHeight?: string;
  readOnly?: boolean;
}> = ({
  onSubmit,
  defaultValue,
  label,
  name,
  placeholder,
  minHeight,
  readOnly,
}) => {
  const [enabled, setEnabled] = useState(false);
  const [value, setValue] = useState(defaultValue || "");
  const submit = useCallback(() => {
    onSubmit && onSubmit(value);
  }, [value]);

  const enable = useCallback(() => {
    setEnabled(true);
  }, []);

  const handleChange = useCallback((e: React.BaseSyntheticEvent) => {
    setValue(e.target.value);
  }, []);

  return (
    <ConfigurationBlockItemBase>
      {label ? <h2>{label}</h2> : null}
      <StyledTextAreaItem
        minHeight={minHeight}
        disabled={!enabled}
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        name={name}
        readOnly={readOnly}
      />

      {enabled ? (
        !readOnly ? (
          <Button onClick={submit}>Submit</Button>
        ) : null
      ) : !readOnly ? (
        <Button onClick={enable}>Edit</Button>
      ) : null}
    </ConfigurationBlockItemBase>
  );
};
