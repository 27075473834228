import React, { FC } from "react";
import { Search } from "../../Form/FormControls/Search";
import { Filter } from "../Filter";
import { SearchProps } from "../../../hooks/search";
import { FilterProps } from "../../../hooks/filter";
import { FilterWrapper, SearchWrapper, Wrapper } from "./styled";

type Props = {
  search?: SearchProps;
  filter?: FilterProps;
};

export const SearchFilter: FC<Props> = ({ search, filter }) => {
  return (
    <Wrapper>
      {search &&  <SearchWrapper>
        <Search
          debounceTime={search?.state?.debounce}
          onChange={(val) => {
            search?.actions?.setSearch?.(val);
          }}
        />
      </SearchWrapper>}
      {filter && <FilterWrapper className={filter?.state?.isSelected ? "selected" : ""}>
        <Filter
          state={filter.state}
          actions={filter.actions}
        />
      </FilterWrapper>}
    </Wrapper>
  );
};
