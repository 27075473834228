import React, { FC } from "react";

import { OptionBase } from "../styled";
import { OptionToolTip } from "./OptionToolTip";
import { BaseOption, OptionActions, OptionSuggestion } from "./types";
import { SubOptions } from "./SubOptions";

export type OptionProps = BaseOption & {
  subOptions?: BaseOption[];
  suggestion?: OptionSuggestion | null;
} & OptionActions;

export const Option = ({
  name,
  color,
  subOptions,
  suggestion,
  tip,
  onSelect,
  isSelected,
}: OptionProps) => {
  const isOptionSelected = isSelected(name);

  const isSuggested = () => {
    return suggestion?.mainOption && suggestion.mainOption === name;
  };
  const getClassName = () => {
    if (isOptionSelected) {
      return "selected";
    }

    if (isSuggested()) {
      return "suggested";
    }

    return "";
  };

  return (
    <OptionBase
      onClick={!subOptions ? () => onSelect(name) : () => {}}
      color={color}
      className={getClassName()}
    >
      <OptionLabel
        tip={tip}
        label={name}
        info={isSuggested() ? suggestion?.confidence : ""}
      />

      <OptionsRadio highlight={isOptionSelected} />
      <SubOptions
        parent={name}
        suggestion={suggestion?.subOption}
        options={subOptions}
        onSelect={onSelect}
        isSelected={isSelected}
      />
    </OptionBase>
  );
};

export const OptionsRadio: FC<{ highlight?: boolean }> = ({ highlight }) => {
  return <div className={`outer-circle ${highlight ? "selected" : ""}`}></div>;
};

const OptionLabel: FC<{ tip?: string; label: string; info?: string }> = ({
  tip,
  label,
  info,
}) => {
  return (
    <label>
      <span className="info">{info || "   "}</span>
      {label} {tip ? <OptionToolTip tip={tip} /> : null}
    </label>
  );
};
