import React, { FC, useEffect, useMemo, useState } from "react";
import {
  PaginationBase,
  TableHeader,
  TableWrapper,
  TableContainer,
  TableHead,
  Table,
  TableData,
  TableRow,
} from "./styled";
import { TablePagination, initialPagination } from "./TablePagination";
import { FilterProps } from "../../hooks/filter";
import { SearchFilter } from "./SearchFilter";
import { SearchProps } from "../../hooks/search";
import { Loader } from "../Loader/Loader";

export type DataTableColumn<Data = any> = {
  index: string;
  render?: (data: Data) => React.ReactNode;
};

export type DataTableColumnActions<Data = any> = Array<{
  label: string;
  render: (data: Data) => React.ReactNode;
}>;

export type DataTableProps<Data = any> = {
  rowIdIndex: keyof Data;
  /** Static headings for the table */
  renderHeading?: (headingLabel: string) => JSX.Element;

  columns: Map<
    string | "actions",
    DataTableColumn<Data> | DataTableColumnActions
  >;
  dataSource: Data[];
  /** Total count of records available in source */
  count: number;
  isLoading: boolean;
  error?: React.ReactNode;
  title?: React.ReactNode;
  // remove when full filter is implemented. this is a temporary patch for viewing unclustered
  filters?: any;
  filter?: FilterProps;
  search?: SearchProps;
  onChange?: (page: number, rowsPerPage: number) => void;
  onSearchChange?: (search: string) => void;
};

export const DataTable: FC<DataTableProps> = ({
  dataSource,
  rowIdIndex,
  isLoading,
  columns,
  count,
  title,
  filters,
  filter,
  search,
  renderHeading,
  onChange,
  onSearchChange,
}) => {
  const [pagination, setPagination] = useState(initialPagination);

  const headings = [...columns.keys()];
  
  const changePage = (page: number, dataCount?: number, rowsPerPage?: number) =>
    (() => {
      if (page > pagination.maxPage) {
        return;
      }

      const maxPage = isNaN(page)
        ? pagination.maxPage
        : Math.ceil(
            (dataCount !== undefined ? dataCount : count) /
              (rowsPerPage || pagination.rowsPerPage)
          );

      return setPagination({
        ...pagination,
        maxPage,
        itemsOnPage: dataSource?.length || 0,
        rowsPerPage: rowsPerPage || pagination.rowsPerPage,
        page,
        count: dataCount !== undefined ? dataCount : count,
      });
    })();

  const getTableTitle = () => {
    if (!isLoading && !dataSource) {
      return <h2>Nothing to show</h2>;
    }

    if(typeof title === 'string') {
      return <h2>{title}</h2>
    }

    return title || "Table";
  };

  useEffect(() => {
    if (dataSource?.length) {
      changePage(pagination.page, count);
    }
  }, [dataSource]);

  useEffect(() => {
    onChange && onChange(pagination.page, pagination.rowsPerPage);
  }, [pagination]);

  useEffect(() => {
    changePage(1, pagination.count);
    onSearchChange && onSearchChange(search?.state?.searchTerm || "");
  }, [search?.state?.searchTerm]);

  useEffect(() => {
    changePage(1, pagination.count);
  }, [filters, filter?.state?.filters]);

  return (
    <TableContainer>
      {isLoading ? <Loader />: ""}
      <TableHeader>
        {getTableTitle()}{" "}
        {(filter || search) && <SearchFilter search={search} filter={filter} />}
      </TableHeader>
      <TableWrapper>
        <Table>
          <TableHead>
            <tr>
              {headings.map((heading: string) => {
                if (heading === "actions") {
                  return "";
                }
                return (
                  <TableData key={heading}>
                    {renderHeading ? renderHeading(heading) : heading}
                  </TableData>
                );
              })}
            </tr>
          </TableHead>
          <tbody>
            {dataSource?.length
              ? dataSource.map((d) => {
                  return (
                    <TableRow key={d[rowIdIndex]}>
                      {headings.map((heading) => {
                        const key = `${d[rowIdIndex]}-${heading}`;

                        const column = columns.get(heading);
                        if (Array.isArray(column)) {
                          if (heading !== "actions") {
                            throw new Error(
                              "a table column of type 'Array' must be named 'actions'"
                            );
                          }

                          return column.map((action) => {
                            return (
                              <TableData key={key}>
                                {action.render(d)}
                              </TableData>
                            );
                          });
                        }

                        if (column?.render) {
                          return (
                            <TableData key={key}>{column.render(d)}</TableData>
                          );
                        }

                        let label = "";
                        column?.index.split(".").forEach((path) => {
                          label = d[path];
                        });

                        return <TableData key={key}>{label}</TableData>;
                      })}
                    </TableRow>
                  );
                })
              : null}
          </tbody>
        </Table>
      </TableWrapper>
      <PaginationBase>
        <TablePagination pagination={pagination} changePage={changePage} />
      </PaginationBase>
    </TableContainer>
  );
};
