import React, { useContext, useEffect, useState } from "react";
import CalendarIcon from "../../assets/calendar.svg";
import { ReportingContext } from "../../context/reporting";
import { DataTable } from "../../components/DataTable";
import {
  useFilter,
  FilterOption,
  SelectedFilterProps,
} from "../../hooks/filter";
import { usePagination } from "../../hooks/pagination";
import { useSearch } from "../../hooks/search";
import { ErrorMsg, ReportingBase } from "./styled";

const rowIdIndex = "user_id";
const cols = new Map([
  ["User", { index: "user" }],
  ["No of responses clustered", { index: "clustered" }],
  ["No of responses reclustered", { index: "reclustered" }],
  ["No of responses skipped", { index: "skipped" }],
  ["Average time to cluster a response", { index: "avg_time_to_cluster" }],
  ["Total time spent on Clustering", { index: "total_time_clustering" }],
  [
    "No. of replies marked as Autoreplied/ Bounced",
    { index: "autoreplied_and_bounced" },
  ],
  ["No. of auto replies rescheduled", { index: "rescheduled" }],
]);

const getFilterDefaults = () => {
  const startDate = new Date();
  startDate.setHours(0, 0, 0);

  const endDate = new Date();
  endDate.setHours(23, 59, 59);

  return { startDate, endDate };
};

export const ReportingContent = () => {
  const reporting = useContext(ReportingContext);
  const pagination = usePagination();
  const search = useSearch();

  const filter = useFilter();
  const [error, setError] = useState("");

  const filterOptions: FilterOption[] = [
    {
      placeholder: "Start",
      renderIcon: () => <CalendarIcon />,
      label: "Start",
      type: "calendar",
      name: "start_time",
    },
    {
      placeholder: "End",
      renderIcon: () => <CalendarIcon />,
      label: "End",
      type: "calendar",
      name: "end_time",
    },
  ];

  useEffect(() => {
    const { start_time, end_time } = filter?.state?.filters || {};

    if (!start_time?.length || !end_time?.length) {
      const defaults = getFilterDefaults();
      filter?.actions?.setFilters?.({
        start_time: [defaults.startDate],
        end_time: [defaults.endDate],
      });
    } else {
      if (start_time[0] > end_time[0]) {
        setError(
          "Looks like the end time you selected is less than your start time. That doesn't work in real life and sure wouldn't work here."
        );
      } else {
        setError("");
        const { limit, offset } = pagination.actions.getLimitAndOffset();

        reporting.actions.fetch(
          limit,
          offset,
          search?.state?.searchTerm,
          filter?.state?.filters
        );
      }
    }
  }, [
    filter?.state?.filters?.start_time,
    pagination.state,
    search?.state?.searchTerm,
  ]);

  useEffect(() => {
    search.actions?.setDebounce(400);
    filter.actions?.setOptions?.(filterOptions);
    filter.actions?.setResultsCount?.(reporting?.state?.reporting?.total || 0);
    pagination.actions.setTotalRecords(reporting?.state?.reporting?.total || 0);
  }, [reporting?.state?.reporting?.data]);

  const handleTableChange = (page: number, rowsPerPage: number) => {
    pagination.actions.changePage(page, rowsPerPage);
  };

  const getTableTitle = (filters?: SelectedFilterProps) => {
    if (error) {
      return <ErrorMsg>{error}</ErrorMsg>;
    }

    if (filters) {
      const { start_time, end_time } = filters;
      if (
        start_time?.length &&
        end_time?.length &&
        start_time[0] &&
        end_time[0]
      ) {
        return (
          <h3>
            Report from {(start_time[0] as Date).toLocaleDateString()} to{" "}
            {(end_time[0] as Date).toLocaleDateString()}
          </h3>
        );
      }
    }

    return "";
  };

  return (
    <ReportingBase>
      {reporting?.state?.reporting && (
        <DataTable
          rowIdIndex={rowIdIndex}
          title={getTableTitle(filter?.state?.filters)}
          dataSource={reporting?.state?.reporting?.data}
          columns={cols}
          filter={filter}
          search={search}
          onSearchChange={search?.actions?.setSearch}
          isLoading={!!reporting?.state.isLoading}
          count={pagination?.state?.totalRecords || 0}
          onChange={handleTableChange}
        />
      )}
    </ReportingBase>
  );
};
