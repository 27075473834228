import styled from "styled-components";

export const TabsBase = styled.div`
  background: #ffffff;
  border-radius: 16px;
  padding: 8px;
  display: inline-flex;
`;

export const TabBase = styled.div`
  a {
    padding: 0;
    margin: 0;
    color: #7f92a3;
    text-decoration: none;
    display: block;
    font-weight: 500;
    font-size: 18px;
    padding: 16px;
    border-radius: 8px;
    flex: 1 1 0px;
    min-width: 172px;
    text-align: center;
    cursor: pointer;
  }

  a.active {
    background: #f8f8f8;
    color: #002648;
  }
`;
