import { createContext, useState } from "react";

type SearchActions = {
  setDebounce: (value: number) => void;
  setSearch: (value: string) => void;
};

type SearchState = {
  debounce?: number;
  searchTerm?: string;
};

export type SearchProps = {
  actions?: SearchActions;
  state?: SearchState;
};

export const SearchContext = createContext<SearchProps>({
  actions: { setDebounce: () => {}, setSearch: () => {} },
  state: { debounce: 0, searchTerm: "" },
});

export const useSearch = (): SearchProps => {
  const [search, setSearch] = useState("");
  const [debounce, setDebounce] = useState(0);

  const actions = {
    setSearch,
    setDebounce,
  };

  const state = {
    debounce,
    searchTerm: search,
  };

  return {
    state,
    actions,
  };
};
