import { APIFilter } from "../../../main-service/src/types/shared/api";

type URLSearchParamsFromObjFn = <T extends string | number | symbol = string>(
  obj: APIFilter<T>
) => URLSearchParams;

export const urlSearchParamsFromObj: URLSearchParamsFromObjFn = (obj) => {
  const q = new URLSearchParams();

  Object.keys(obj).forEach((key) => {
    const values = obj[key];
    if (Array.isArray(values)) {
      values.forEach((v: string) => {
        q.append(key, v);
      });
    } else {
      q.append(key, values);
    }
  });

  return q;
};
