import {
  API,
  API_Data,
  ClusterData,
  E_ClusteringCopilotRes,
  E_ClusteringRes,
} from "../../../../../../main-service/src/types/shared/api";
import { ClusteringTasks } from "../../../../../../main-service/src/types/shared/shared";
import { ReplyClusterContext } from "../reply-cluster-state/types";

export type UnclusteredCountRes =
  API[API_Data.CLUSTERING_RES][E_ClusteringRes.UNCLUSTERED_COUNT];
export type ClusteringHintRes =
  API[API_Data.CLUSTERING_COPILOT_RES][E_ClusteringCopilotRes.HINT];

export type Reply = API[API_Data.CLUSTERING_REPLY_DETAILS];

export type ClusteringEffectFn<
  T extends ClusterData | string | undefined | unknown = unknown
> = <P extends ClusterData | string | undefined | unknown = T>(
  task: ClusteringTasks,
  payload: P
) => Promise<void>;

export type ClusteringEffects = {
  assign: ClusteringEffectFn;
  complete: ClusteringEffectFn;
  skip: ClusteringEffectFn;
  unassign: ClusteringEffectFn;
};

export type ClusteringStoreContext = {
  store: ReplyClusterContext;
  effects: ClusteringEffects;
};
