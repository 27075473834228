import styled from "styled-components";

export const FilterPanelBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 24px;
  padding: 1rem;
  width: 43.5rem;
  height: 43.313rem;

  position: absolute;
  right: -0.312rem;
    top: 2rem;

  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  z-index: 1000;

  border-radius: 16px;
`;

export const FilterActionsBase = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button {
    padding: 1rem 2rem;
  }

  .link {
    color: #2020f1;
    &:hover {
      cursor: pointer;
    }
  }
`;
