import styled from "styled-components";

const Base = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Wrapper = styled(Base)`
  justify-content: space-between;
  padding: 0.5rem;
  min-width: 30rem;
  > * {
    margin-left: auto;
  }
`;

export const SearchWrapper = styled(Base)`
  min-width: 19.5rem;
`;

export const FilterWrapper = styled(SearchWrapper)`
  min-width: 8.75rem;
  cursor: pointer;
`;
