import React, { createContext, useCallback, useState } from "react";
import { RES_AdminUsers } from "../../../../../main-service/src/types/shared/api";
import { usePagination } from "../../../hooks/pagination";
import { useSearch } from "../../../hooks/search";

import {
  UserManagementActions,
  UserManagementState,
  UserManagementStateContext as ContextType,
} from "./types";
import {
  convertUsersTimestampToUITime,
  getDefaultUserManagementStateContext,
} from "./helpers";

export const UserManagementStateContext = createContext<ContextType>(
  getDefaultUserManagementStateContext()
);

export const useUserManagementState = (): ContextType => {
  const pagination = usePagination();

  const search = useSearch();
  const [adminUsers, setAdminUsers] = useState<RES_AdminUsers>();
  const [isLoading, setIsLoading] = useState(true);
  const [userToEdit, setUserToEdit] = useState<
    RES_AdminUsers["data"][0] | null
  >();
  const [editError, setEditError] = useState("");
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [usersFetchError, setUsersFetchError] = useState("");

  const setRefresh = useCallback((refresh: boolean) => {
    setIsLoading(false);
    setShouldRefresh(refresh);
    setUserToEdit(undefined);
  }, []);

  const handleEditError = useCallback((message: string) => {
    setEditError(message);
    setIsLoading(false);
  }, []);

  const handleUsersFetchError = useCallback((message: string) => {
    setUsersFetchError(message);
    setIsLoading(false);
  }, []);

  const handleUsersFetchSuccess = useCallback((data?: RES_AdminUsers) => {
    if (!data) {
      actions.setUsersFetchError("Data cannot be empty");
      return;
    }

    const r: RES_AdminUsers = {
      ...data,
      data: convertUsersTimestampToUITime(data?.data),
    };
    actions.setAdminUsers(r);
    actions.setIsLoading(false);
    actions.setUsersFetchError("");
  }, []);

  const state: UserManagementState = {
    search: search.state,
    pagination: pagination.state,
    adminUsers,
    isLoading,
    userToEdit,
    editError,
    usersFetchError,
    shouldRefresh,
  };

  const actions: UserManagementActions = {
    search: search.actions,
    pagination: pagination.actions,
    handleUsersFetchSuccess,
    handleUsersFetchError,
    handleEditError,
    setRefresh,
    setUsersFetchError,
    setAdminUsers,
    setIsLoading,
    setUserToEdit,
    setEditError,
    setShouldRefresh,
  };
  return [state, actions];
};

export const UserManagementStateProvider = ({ children }) => {
  const value = useUserManagementState();

  return (
    <UserManagementStateContext.Provider value={value}>
      {children}
    </UserManagementStateContext.Provider>
  );
};
