import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ReclusteringPagePopupArgs } from "./types";

import {
  CloseDialogKeys,
  DialogPopup,
} from "../../../../components/DialogPopup/types";
import { UsePopups } from "../../types";
import {
  reclusteringDonePopupConfig,
  reclusteringErrorPopupConfig,
  reclusteringExitPopupConfig,
} from "./constants";

export const useReclusteringPageDialogs: UsePopups<
  ReclusteringPagePopupArgs
> = (args) => {
  const navigate = useNavigate();

  const handlePageExitConfirmationDialogActions = useCallback(
    (key: CloseDialogKeys) => {
      switch (key) {
        case CloseDialogKeys.GO_BACK:
          args.setPageExitPopupVisible(false);
          break;
        case CloseDialogKeys.STOP:
          navigate("/landing/feed");

          break;
      }
    },
    []
  );

  const handleReclusteringCall = useCallback(
    (key: CloseDialogKeys) => {
      switch (key) {
        case CloseDialogKeys.GO_BACK:
        case CloseDialogKeys.STOP:
          args.setShowReclusteringDoneDialog(false);
          navigate("/landing/feed");
          break;
      }
    },
    [args.setShowReclusteringDoneDialog]
  );

  const handleClusteringErrorDialogActions = useCallback(
    (key: CloseDialogKeys) => {
      switch (key) {
        case CloseDialogKeys.GO_BACK:
          args.setPageExitPopupVisible(false);

          break;
        case CloseDialogKeys.STOP:
          navigate("/landing/feed");
      }
    },
    []
  );

  const reclusteringExitPopup: DialogPopup<CloseDialogKeys> = useMemo(
    () => ({
      ...reclusteringExitPopupConfig,
      isVisible: !!args.pageExitPopupVisible,
      onActionCall: handlePageExitConfirmationDialogActions,
    }),
    [handleClusteringErrorDialogActions, args.pageExitPopupVisible]
  );

  const reclusteringErrorPopup: DialogPopup<CloseDialogKeys> = useMemo(
    () => ({
      ...reclusteringErrorPopupConfig,
      isVisible: !!args.isErrorPopupVisible,
      mainText: args.error || "",
      onActionCall: handleClusteringErrorDialogActions,
    }),
    [handleClusteringErrorDialogActions, args.error]
  );

  const reclusteringDonePopup: DialogPopup<CloseDialogKeys> = useMemo(
    () => ({
      ...reclusteringDonePopupConfig,
      isVisible: args.showReclusteringDoneDialog,
      onActionCall: handleReclusteringCall,
    }),
    [args.showReclusteringDoneDialog]
  );

  const popups: DialogPopup<CloseDialogKeys>[] = useMemo(
    () => [
      reclusteringExitPopup,
      reclusteringErrorPopup,
      reclusteringDonePopup,
    ],
    [
      reclusteringExitPopup.isVisible,
      reclusteringErrorPopup.isVisible,
      reclusteringDonePopup.isVisible,
    ]
  );

  return popups;
};
