import styled from "styled-components";

export const StyledTextAreaItem = styled.textarea<{
  minHeight?: string;
  maxHeight?: string;
}>`
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "25rem")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : undefined)};
  padding: .5rem;
`;
