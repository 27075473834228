import React, { useCallback, useContext, useMemo } from "react";
import { trackButtonClick } from "../modules/mixpanel";
import { ReplyManagementEffectsContext } from "../context/stores/clustering/reply-management-effect";

type ReplyViewActions = {
  setReplyToBounced: (
    replyId: string,
    onDone: () => Promise<void>
  ) => Promise<void>;
  setAutoReplyToNormalReply: (
    replyId: string,
    onDone: () => Promise<void>
  ) => Promise<void>;
  setNormalReplyToAutoReply: (
    replyId: string,
    onDone: () => Promise<void>
  ) => Promise<void>;
  toggleOriginalMessage: () => void;
  toggleTranslation: () => void;
};

export const useReplyViewActions = (): ReplyViewActions => {
  const effects = useContext(ReplyManagementEffectsContext);

  const setReplyToBounced = useCallback(
    async (replyId: string, onDone: () => Promise<void>) => {
      if (!replyId) {
        return;
      }

      trackButtonClick({ name: "set_to_bounced", reply_id: replyId });
      await effects.bounced(replyId);
      await onDone();
    },
    [effects.bounced]
  );

  const setAutoReplyToNormalReply = useCallback(
    async (replyId: string, onDone: () => Promise<void>) => {
      if (replyId) {
        trackButtonClick({ name: "set_to_replied", reply_id: replyId });
        await effects.replied(replyId);
        await onDone();
      }
    },
    [effects.replied]
  );

  const setNormalReplyToAutoReply = useCallback(
    async (replyId: string, onDone: () => Promise<void>) => {
      if (replyId) {
        trackButtonClick({ name: "set_to_autoreplied", reply_id: replyId });
        await effects.autoreplied(replyId);
        await onDone();
      }
    },
    [effects.autoreplied]
  );

  return useMemo(
    () => ({
      setReplyToBounced,
      setAutoReplyToNormalReply,
      setNormalReplyToAutoReply,
      toggleOriginalMessage: effects.toggleOriginalMessage,
      toggleTranslation: effects.toggleTranslation,
    }),
    [
      setReplyToBounced,
      setAutoReplyToNormalReply,
      setNormalReplyToAutoReply,
      effects.toggleOriginalMessage,
      effects.toggleTranslation,
    ]
  );
};
