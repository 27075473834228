import mixpanel from "mixpanel-browser";

export enum MixPanelEvent {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  BUTTON_CLICK = "BUTTON_CLICK",
  APP_CRASHED = "APP_CRASHED",
}

export const trackButtonClick = (data: { name: string; [x: string]: any }) => {
  mixpanel.track(MixPanelEvent.BUTTON_CLICK, data);
};

export const log = (message: string, data: Record<string, unknown>) => {
  mixpanel.track(message, data);
};

export default mixpanel;
