import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    position: relative;
    width: 100%;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
`;

export type CollapsibleProps = {
  collapseDefault?: boolean;
  /**
   * Render what should be clicked on in order to show the collapsed Node
   */
  renderToggler: () => React.ReactNode;
  /**
   * Renders the node node that needs to be revield when expanded
   */
  renderCollapsible: (onCloseSignalReceived?: () => void) => React.ReactNode;

  onCollapseChange?: (collapse?: boolean) => void
};

/**
 * Uses render props pattern to make React Node collapsible
 */
export const Collapsible: FC<CollapsibleProps> = ({
  renderCollapsible,
  renderToggler,
  collapseDefault,
  onCollapseChange
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!!collapseDefault);

  useEffect(() => {
      onCollapseChange && onCollapseChange(isCollapsed)
  }, [isCollapsed])

  const handleCloseSignal = () => {
    setIsCollapsed(true)
  }
  return (
    <>
      {!isCollapsed ? (
        <Backdrop
          className="bd"
          onClick={(e) => {
            e.stopPropagation();
            setIsCollapsed(true)
          }}
        />
      ) : null}
      <Wrapper>
        <div onClick={() => setIsCollapsed(!isCollapsed)}>
          {renderToggler()}
        </div>
        <div>{!isCollapsed ? renderCollapsible(handleCloseSignal) : null}</div>
      </Wrapper>
    </>
  );
};
