import { useCallback, useMemo, useState } from "react";

import {
  CloseDialogKeys,
  DialogPopup,
} from "../../../../components/DialogPopup/types";

import { trackButtonClick } from "../../../../modules/mixpanel";
import { ClusteringPage } from "../../types";
import { useClusteringPageDialogs } from "./use-clustering-errors-page-dialogs";
import { ClusteringTasks } from "../../../../../../main-service/src/types/shared/shared";
import { Store } from "../../../../context/stores/clustering";

export const useClusteringErrorsPage = (): ClusteringPage => {
  const { store, effects } = Store.useClusteringStore();
  const [state, actions] = store;

  const [pageExitPopupVisible, setPageExitPopupVisible] = useState(false);
  useState(false);

  const error = state.metadata.error;

  const onActionDone = useCallback(async () => {
    actions.setCluster(null);

    await effects.assign(ClusteringTasks.ERROR_RESOLUTION, "");
  }, [effects.assign]);

  const skip = useCallback(async () => {
    trackButtonClick({ name: "skip_reply_error", reply_id: state.reply?.id });
    await effects.skip(ClusteringTasks.ERROR_RESOLUTION, state.reply?.id!);
    await onActionDone();
  }, [onActionDone, state.reply?.id]);

  const submitFix = useCallback(async () => {
    if (!state.cluster) {
      return;
    }

    trackButtonClick({
      name: "submit_clustering_error_fix",
      reply_id: state.reply?.id,
      replied_at: state.reply?.replied_at,
      cluster: state.cluster,
    });
    await effects.complete(ClusteringTasks.ERROR_RESOLUTION, {
      ...state.cluster,
      replyId: state.reply?.id,
      errorId: state.reply?.clusteringError?.id,
    });

    await onActionDone();
  }, [state.cluster, state.hint]);

  const complete = useCallback(async () => {
    await submitFix();
  }, [state.isRescheduling, submitFix]);

  const unassign = useCallback(async () => {
    await effects.unassign(ClusteringTasks.ERROR_RESOLUTION, "");
  }, [effects.unassign]);

  const dialogs: DialogPopup<CloseDialogKeys>[] = useClusteringPageDialogs({
    replyId: state.reply?.id,
    pageExitPopupVisible,
    error,
    setPageExitPopupVisible,
    unassign: unassign,
  });

  const onViewExit = useCallback(() => {
    setPageExitPopupVisible(true);
  }, []);

  const onViewEnter = useCallback(
    () => effects.assign(ClusteringTasks.ERROR_RESOLUTION, ""),
    [effects.assign]
  );

  const res: ClusteringPage = useMemo(
    () => ({
      task: ClusteringTasks.ERROR_RESOLUTION,
      isLoading: state.metadata.isLoading,
      dialogs,
      complete,
      skip,
      onActionDone: onActionDone,
      onViewExit,
      onViewEnter,
    }),
    [
      state.metadata.isLoading,
      dialogs,
      complete,
      skip,
      onActionDone,
      onViewExit,
      onViewEnter,
    ]
  );

  return res;
};
