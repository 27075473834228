import styled from "styled-components";

export const ClusteringOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  & > h3 {
    font-size: 18px;
    font-weight: 500;
    color: #0b0b0c;
    margin-bottom: 24px;
  }
  max-width: 23.604rem;
`;

export const ContainerBase = styled.div`
  display: flex;
  width: 100%;

  &.with-padding {
    padding: 24px;
    grid-gap: 24px;
  }
`;

export const PausedContentBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  & > h1 {
    font-size: 56px;
    font-weight: 300;
    color: #0b0b0c;
  }
  & > h3 {
    margin-top: 8px;
    font-weight: 300;
    font-size: 20px;
    color: #7f92a3;
  }
  & .countdown {
    margin-top: 80px;
  }
`;

export const ContentBody = styled.div`
  padding: 24px;
  flex-grow: 1;
  overflow: auto;
`;

export const ContentSpace = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  border-right: 1px solid #dcdddd;
  flex-grow: 1;
  & > div {
    border-bottom: 1px solid #dcdddd;
  }
`;

export const ContentTitle = styled.div`
  padding: 24px;
  display: flex;
  grid-gap: 8px;
  flex-direction: column;
  & h1 {
    font-size: 24px;
    font-weight: 500;
    color: #000b0c;
  }
  & h3 {
    font-size: 18px;
    color: #7f92a3;
    font-weight: 400;
  }
`;

export const ContentFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 33px;
`;
