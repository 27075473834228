import React, { useCallback } from "react";
import { Toggle } from "../../../../../components/Toggle";
import { useCopilotSettingsStore } from "../../../../../context/stores/copilot-settings/copilot-settings-store";
import { ToggleWrapper } from "../../styled";

export const CopilotToggler = () => {
  const { store, effects } = useCopilotSettingsStore();
  const [data] = store;

  const handleToggleChange = useCallback(
    (enabled: boolean) => {
      effects.setCopilotEnabled(enabled);
    },
    [effects.setCopilotEnabled]
  );

  return (
    <ToggleWrapper>
      <Toggle
        leftLabel="Copilot Disabled"
        rightLabel="Copilot Enabled"
        defaultIsChecked={data.settings?.value?.enabled}
        onChange={handleToggleChange}
        checkedColor="#002342"
        uncheckedColor="#002342e0"
      />
    </ToggleWrapper>
  );
};
