import React, { useEffect, useMemo, useState } from "react";
import ArrowLeft from "~/src/assets/arrow-left.svg";

import {
  BackButton,
  ContainerBase,
  ContentBase,
  SideBar,
} from "../../../pages/Clustering/styled";
import { TimeoutTracker } from "./TimeoutDialog";
import { ReplyView } from "../ReplyView";
import { ClusteringOptions } from "../ClusteringOptions/Index";
import { ContentBody, ContentFooter, ContentSpace } from "../styled";
import { ClusteringViewTitle } from "./ClusteringViewTitle";
import { ClusteringActions } from "../ClusteringTaskActions";
import { Store } from "../../../context/stores/clustering";
import { ClusteringTasks } from "../../../../../main-service/src/types/shared/shared";
import { OptionSetters } from "../ClusteringOptions/types";

export type TClusteringViewProps = {
  exitOnIdle?: boolean;
  isLoading?: boolean;
  skipHint?: boolean;
  task: ClusteringTasks;
  complete: () => Promise<void>;
  skip?: () => Promise<void>;
  onClusteringActionDone: () => Promise<void>;
  onViewExit: () => void;
  renderFallback: () => React.ReactNode;
  onViewEnter: () => Promise<void>;
};
export const ClusteringView: React.FC<TClusteringViewProps> = ({
  task,
  exitOnIdle,
  isLoading,
  complete,
  skip,
  onClusteringActionDone,
  onViewExit,
  onViewEnter,
  renderFallback,
}) => {
  const { store } = Store.useClusteringStore();

  const [state, actions] = store;
  const [isViewDirty, setIsViewDirty] = useState(false);

  const optionSetters: OptionSetters = useMemo(
    () => ({
      setCluster: actions.setCluster,
      setNextScheduleDate: actions.setNextScheduleDate,
    }),
    [actions.setCluster, actions.setNextScheduleDate]
  );
  
  useEffect(() => {
    onViewEnter();
  }, [onViewEnter]);
  
  const clusteringErrorCategory = state?.reply?.clusteringError?.category
  return (
    <ContainerBase>
      <SideBar>
        <BackButton onClick={onViewExit}>
          <ArrowLeft />
        </BackButton>
      </SideBar>
      <ContentBase>
        {!isLoading && !state.reply?.id ? (
          renderFallback()
        ) : (
          <ContainerBase>
            <ContentSpace>
              <ClusteringViewTitle
                sender={state?.reply?.sender}
                isLoading={isLoading}
              />
              <ContentBody>
                <ReplyView onReplyActionDone={onClusteringActionDone} />
              </ContentBody>
              <ContentFooter>
                <ClusteringActions
                  disableCompleteBtn={!isViewDirty}
                  task={task}
                  complete={complete}
                  skip={skip}
                />
              </ContentFooter>
            </ContentSpace>
            <ClusteringOptions
              clusteringSuggestion={state.suggestion}
              setIsViewDirty={setIsViewDirty}
              task={task}
              cluster={state.cluster}
              optionSetters={optionSetters}
              errorCategory={clusteringErrorCategory}
            />
          </ContainerBase>
        )}
      </ContentBase>

      {exitOnIdle ? <TimeoutTracker task={task} /> : null}
    </ContainerBase>
  );
};
