import React, { useMemo } from "react";
import { ContentCard } from "../../../../../components/ContentCard";
import { ListView, MaxWidth60Rem } from "../../styled";
import { CopilotMode } from "./CopilotMode";
import { CopilotToggler } from "./CopilotToggler";
import { Store } from "../../../../../context/stores/copilot-settings/";
import { CopilotAgentEmail } from "./CopilotAgentEmail";
import { CopilotMinimumActionConfidence } from "./CopilotMinimumActionConfidence";
import { CopilotSuspiciousClusteringHintToggler } from "./CopilotSuspiciousClusteringHintToggler";
import { ConfigurationList } from "../../../../../components/ConfigurationPanel/ConfigurationList";

const BasicSettingsContent = () => {
  const { store } = Store.useCopilotSettingsStore();
  const [data] = store;
  return (
    <ConfigurationList>
      <CopilotToggler />
      {data.settings?.value?.enabled ? (
        <ConfigurationList>
          <CopilotMode />
          {data.settings.value.mode === "AGENT" ? (
            <CopilotMinimumActionConfidence />
          ) : null}
          <CopilotAgentEmail />
          <CopilotSuspiciousClusteringHintToggler />
        </ConfigurationList>
      ) : null}
    </ConfigurationList>
  );
};

export const BasicSettings = () => {
  const content = useMemo(
    () => ({
      header: { title: "Basic Settings" },
      body: () => <BasicSettingsContent />,
    }),
    []
  );
  return (
    <MaxWidth60Rem>
      <ListView>
        <ContentCard isLoading={false} data={content} />
      </ListView>
    </MaxWidth60Rem>
  );
};
