import React, { FC, ReactNode } from "react";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(11, 11, 12, 0.25);
  z-index: 1001;
  cursor: default;
  backdrop-filter: blur(4px);
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
`;

export const Backdrop: FC<{
  onClick?: (e: React.SyntheticEvent) => void;
  children?: ReactNode;
}> = ({ onClick, children }) => <Overlay onClick={onClick}>{children}</Overlay>;
