import {
  ReplyUserAction,
  SecondaryProspect,
  WoodpeckerAgencyHookEvent,
  WoodpeckerHookEvent,
  WpSnippets,
  ReplyWithMetadata,
  Role,
  ClusteringError,
  User,
} from '../shared';

import {
  CampaignDetails,
  DB,
  DT_ComxCompany,
  DT_User,
  E_Schemas,
  E_Tables,
} from '../../db';
import { BaseClusteringTaskPayload } from '../../internal/result';

import { E_Type } from '../replies';
import {
  CopilotHintResult,
  E_Categories,
  E_ClusteringSubcategories,
  E_Sentiments,
} from '../clustering';
import { AdminAPI } from './admin';
import { ClusteringAICtr } from './clustering-ai';

export type Comparable = string | number | Date;

export const E_UserRole = Role;

export type APIFilter<T extends string | number | symbol = string> = Partial<
  Record<T, Comparable | Comparable[]>
>;

type ClusteringOtherSubCategories = {
  subCategory: E_ClusteringSubcategories;
};

type ClusterInfo = {
  other: ClusteringOtherSubCategories;
  category: E_Categories;
  sentiment: E_Sentiments;
};

type ClusteringFeedData = {
  id: string;
  name: string;
  email: string;
  secondary_email?: string;
  type: E_Type;
  rescheduled?: string;
  cluster_info: ClusterInfo;
  delivered_at: string;
};

type Res_ClusteringFeed = {
  total: number;
  data: ClusteringFeedData[];
};

type RES_Login = {
  accessToken: string;
  email: string;
  given_name: string;
  name: string;
  picture: string;
  role: string;
};

type RES_ClusteringReplyDetails = {
  id: string;
  message: string;
  metadata: {
    last_updated_user?: string;
  };
  replied_at: string;
  sender?: {
    email: string;
    first_name: string;
    last_name: string;
    name: Pick<DB[E_Schemas.PUBLIC][E_Tables.WP_PROSPECTS], 'email' | 'name'>;
    companyName: string;
  };
  status: string;
  type?: E_Type;
  subject: string;
  updated_at: string;
  wp_message: string;
  translated?: string;
  reply_user_actions?: Array<ReplyUserAction>;
  clusteringError: ClusteringError;
};

export type RES_ComxCompany = DT_ComxCompany;
export type RES_Reporting = {
  // total records
  total: number;
  data: Array<{
    id: string;
    user_id: string;
    clustered: number;
    reclustered: number;
    skipped: number;
    avg_time_to_cluster: string;
    total_time_clustering: string;
    user: string;
    rescheduled: string;
    autoreplied_and_bounced: number;
    total?: string;
  }>;
};

export type RES_AdminUsers = {
  total: number;
  data: Array<Partial<User>>;
};

export type RES_AdminUser = DT_User;

type _Prospect = {
  address: string;
  campaigns_details?: Array<CampaignDetails>;
  city: string;
  company: string;
  country: string;
  email: string;
  first_name: string;
  id: number;
  industry: string;
  last_contacted: string;
  last_replied: string;
  last_name: string;
  linkedin_url: string;
  phone: string;
  state: string;
  status: string;
  tags: string;
  title: string;
  updated: string;
  website: string;
  campaign_id?: number;
  campaign_email?: string;
  secondary_prospect: SecondaryProspect;
} & WpSnippets;

type Company = {
  id: number;
  name: string;
  api_keys: Array<{ name: string; api_key: string }>;
  campaigns: number;
  owner: string;
};

export type WoodpeckerCompanyV2 = {
  id: number;
  name: string;
  owner: string;
  active?: boolean;
  running_campaigns: number;
  email_stats: number;
  linkedin_slots: number;
};

export type WoodpeckerCompanyAPIKey = {
  api_key: string;
  label: string;
};

export type WoodpeckerPaginationData = {
  total_elements: number;
  total_pages: number;
  current_page_number: number;
};

export type WoodpeckerCompanyAPIV2Res = {
  content: WoodpeckerCompanyV2[];
  pagination_data: WoodpeckerPaginationData;
};

export type WoodpeckerCompanyAPIKeysAPIV2Res = {
  content: WoodpeckerCompanyAPIKey[];
  pagination_data: WoodpeckerPaginationData;
};

type WoodpeckerAPI = {
  // Prospect: Response from Woodpecker (GET Prospect)
  get_prospects?: _Prospect;
  get_reply?: {
    email: string;
    prospect_id: number;
    responses: Array<{
      campaign_id: number;
      campaign_email_sent: number;
      delivered: string;
      message: string;
      step: number;
      subject: string;
    }>;
  };
  // Company: Type from Woodpecker (GET Companies)
  get_company?: Company;
  get_company_v2?: WoodpeckerCompanyV2;
  get_companies_v2?: WoodpeckerCompanyAPIV2Res;

  // Get Company API Key
  get_company_api_keys?: WoodpeckerCompanyAPIKeysAPIV2Res;
};

export type WoodpeckerHookEventPayloads = {
  method: WoodpeckerHookEvent;
  prospect: _Prospect;
  secondary_prospect: SecondaryProspect;
  timestamp: string;
};

export type WoodpeckerAgencyHookEventPayload = {
  method: WoodpeckerAgencyHookEvent;
  company: Company | WoodpeckerCompanyV2;
};

type WoodpeckerWebhookEventPayloads = {
  hook_event_payload: WoodpeckerHookEventPayloads;
  hook_agency_event_payload: WoodpeckerAgencyHookEventPayload;
};

export enum API_Data {
  FEED_RESPONSE = 'FEED_RESPONSE',
  LOGIN_RESPONSE = 'LOGIN_RESPONSE',
  CLUSTERING_REPLY_DETAILS = 'CLUSTERING_REPLY_DETAILS',
  WP_COMPANY = 'WP_COMPANY',
  REPORTING_RESPONSE = 'REPORTING_RESPONSE',
  ADMIN_USERS = 'ADMIN_USERS',
  ADMIN_USER = 'ADMIN_USER',
  WOODPECKER_API = 'WOODPECKER_API',
  WOODPECKER_WEBHOOK_EVENT_PAYLOADS = 'WOODPECKER_WEBHOOK_EVENT_PAYLOADS',
  CLUSTERING_RES = 'CLUSTERING_RES',
  CLUSTERING_COPILOT_RES = 'CLUSTERING_COPILOT_RES',
  CLUSTERING_ERROR_RES = 'CLUSTERING_ERROR_RES',
  ADMIN_RES = 'ADMIN_RES',
  CLUSTERING_AI_RES = 'CLUSTERING_AI',
}

export enum E_ClusteringRes {
  SEND_AFTER = 'SEND_AFTER',
  GET_CLUSTERED_RESPONSE = 'GET_CLUSTERED_RESPONSE',
  ASSIGN_REPLY_FOR_CLUSTERING = 'ASSIGN_REPLY_FOR_CLUSTERING',
  UNCLUSTERED_COUNT = 'UNCLUSTERED_COUNT',
}

export enum E_ClusteringErrorRes {
  ASSIGN_ERROR_FOR_RECLUSTERING,
  SKIP_CLUSTERING_ERROR,
  FIX_CLUSTERING_ERROR,
}

export enum E_ClusteringCopilotRes {
  HINT = 'HINT',
}

export type ClusterData = {
  category: E_Categories;
  other?: {
    subCategory?: E_ClusteringSubcategories;
  };
  replyId: string;
  sentiment: E_Sentiments;
  duration?: number;
};

export type ClusteringErrorFix = ClusterData & { errorId: string };

type Res_SendAfter = {
  error: boolean;
  message: string;
  data?: BaseClusteringTaskPayload & {
    sendAfter?: string;
    previousReply?: string;
    newReplyId?: string;
  };
};

type Res_GetClusteredResponse = Required<ReplyWithMetadata>;
type Res_AssignedReplyForClustering = ReplyWithMetadata | Record<string, never>;
type Res_UnclusteredCount = { count: number };

export type ClusteringCtr = {
  [E_ClusteringRes.SEND_AFTER]: Res_SendAfter;
  [E_ClusteringRes.UNCLUSTERED_COUNT]: Res_UnclusteredCount;
  [E_ClusteringRes.GET_CLUSTERED_RESPONSE]: Res_GetClusteredResponse;
  [E_ClusteringRes.ASSIGN_REPLY_FOR_CLUSTERING]: Res_AssignedReplyForClustering;
};

type Res_AssignClusteringErrorReplyForReclustering =
  DB[E_Schemas.PUBLIC][E_Tables.CLUSTERING_ERRORS];

type Res_SkipClusteringError = {
  error?: boolean;
  message: string;
};

type Res_FixClusteringError = {
  error?: boolean;
  message: string;
};

export type ClusteringErrorCtr = {
  [E_ClusteringErrorRes.ASSIGN_ERROR_FOR_RECLUSTERING]: Res_AssignClusteringErrorReplyForReclustering;
  [E_ClusteringErrorRes.SKIP_CLUSTERING_ERROR]: Res_SkipClusteringError;
  [E_ClusteringErrorRes.FIX_CLUSTERING_ERROR]: Res_FixClusteringError;
};

type Res_CopilotHint = CopilotHintResult;

export type ClusteringCopilotCtr = {
  [E_ClusteringCopilotRes.HINT]: Res_CopilotHint;
};

export type API = {
  [API_Data.ADMIN_RES]: AdminAPI;
  [API_Data.CLUSTERING_COPILOT_RES]: ClusteringCopilotCtr;
  [API_Data.CLUSTERING_AI_RES]: ClusteringAICtr;
  [API_Data.CLUSTERING_ERROR_RES]: ClusteringErrorCtr;
  [API_Data.CLUSTERING_RES]: ClusteringCtr;
  [API_Data.FEED_RESPONSE]: Res_ClusteringFeed;
  // reorganize stuff below
  [API_Data.LOGIN_RESPONSE]: RES_Login;
  [API_Data.CLUSTERING_REPLY_DETAILS]: RES_ClusteringReplyDetails;
  [API_Data.WP_COMPANY]: RES_ComxCompany;
  [API_Data.REPORTING_RESPONSE]: RES_Reporting;
  [API_Data.ADMIN_USERS]: RES_AdminUsers;
  [API_Data.ADMIN_USER]: RES_AdminUser;
  [API_Data.WOODPECKER_API]: WoodpeckerAPI;
  [API_Data.WOODPECKER_WEBHOOK_EVENT_PAYLOADS]: WoodpeckerWebhookEventPayloads;
};
