import styled from "styled-components";

export const ReportingBase = styled.div`
  padding: 24px;
  display: flex;
  grid-gap: 14px;
  flex-flow: column;
  padding: .5rem;
  width: 100%;
  background-color: #fff;
  height: 80vh;
`;

export const TableHeading = styled.span`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #9da2a2;
`;

export const ErrorMsg = styled.div`
  color: #e76161d9;
`
