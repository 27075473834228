import React from "react";
import { ContentActionsBase, PrePara, SecondaryButton } from "./styled";

export type MessagePaneProps = {
  message: string;
  showOriginal: boolean;
  showTranslated: boolean;
  toggleOriginalMessage: () => void;
  toggleTranslation: () => void;
};

export const MessagePane: React.FC<MessagePaneProps> = ({
  showOriginal,
  showTranslated,
  message,
  toggleOriginalMessage,
  toggleTranslation,
}) => {
  return (
    <>
      <ContentActionsBase>
        <SecondaryButton onClick={toggleOriginalMessage}>
          Show {showOriginal ? "Clean" : "Original"} text
        </SecondaryButton>
        <SecondaryButton onClick={toggleTranslation}>
          {showTranslated ? "Hide" : "Show"} translation
        </SecondaryButton>
      </ContentActionsBase>
      <PrePara
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      ></PrePara>
    </>
  );
};
