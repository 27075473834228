import { useCallback, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  CloseDialogKeys,
  DialogPopup,
} from "../../../../components/DialogPopup/types";
import { ClusterData } from "../../../../../../main-service/src/types/shared/api";
import { trackButtonClick } from "../../../../modules/mixpanel";
import { ClusteringPage } from "../../types";
import { useClusteringPageDialogs } from "./use-clustering-page-dialogs";
import { ClusteringTasks } from "../../../../../../main-service/src/types/shared/shared";
import { Store } from "../../../../context/stores/clustering";
import { CopilotHint } from "../../../../../../main-service/src/types/shared/clustering";

const shouldShowHint = (
  cluster: Omit<ClusterData, "replyId"> | null,
  hint?: CopilotHint | null
) => {
  if (!cluster) {
    return false;
  }

  const clusterInfoHint = hint?.clusterInfo as ClusterData;

  const shouldShow =
    hint &&
    hint.confidence > 50 &&
    (cluster.category !== clusterInfoHint.category ||
      cluster.sentiment !== clusterInfoHint.sentiment ||
      cluster.other?.subCategory !== clusterInfoHint.other?.subCategory);

  return shouldShow;
};

export const useClusteringPage = (): ClusteringPage => {
  const params = useParams();
  const navigate = useNavigate();

  const { store, effects } = Store.useClusteringStore();
  const [state, actions] = store;

  const [showHintDialog, setShowHintDialog] = useState(false);
  const [pageExitPopupVisible, setPageExitPopupVisible] = useState(false);
  useState(false);

  // const isViewDirty = !!(state.cluster || state.nextScheduleDate);
  const error = state.metadata.error;

  const closeErrorDialog = useCallback(() => {
    actions.setMetadata((prev) => ({ ...prev, error: "" }));
  }, [error]);

  const onActionDone = useCallback(async () => {
    actions.setCluster(null);

    if (!error) {
      await effects.assign(ClusteringTasks.CLUSTERING, "");
    }
  }, [effects.assign, error]);

  const skip = useCallback(async () => {
    trackButtonClick({ name: "skip_reply", reply_id: state.reply?.id });
    await effects.skip(ClusteringTasks.CLUSTERING, state.reply?.id!);
    await onActionDone();
  }, [onActionDone, state.reply, state.reply?.id]);

  const submitReplyCluster = useCallback(async () => {
    if (!state.reply?.id || !state.cluster) {
      return;
    }

    trackButtonClick({
      name: "submit_cluster",
      reply_id: state.reply?.id,
      replied_at: state.reply?.replied_at,
      cluster: state.cluster,
    });
    await effects.complete(ClusteringTasks.CLUSTERING, {
      ...state.cluster,
      replyId: state.reply?.id,
    });

    await onActionDone();
  }, [
    state.reply?.id,
    state.reply?.replied_at,
    state.cluster,
    effects.complete,
    navigate,
    onActionDone,
  ]);

  const submitReplyClusterOrHint = useCallback(async () => {
    if (shouldShowHint(state.cluster, state.hint)) {
      setShowHintDialog(true);
      return;
    }

    await submitReplyCluster();
  }, [
    shouldShowHint,
    showHintDialog,
    state.cluster,
    state.hint,
    submitReplyCluster,
  ]);

  const rescheduleAutoReply = useCallback(
    async (date?: Date | null) => {
      if (state.reply?.id && date) {
        await effects.complete(ClusteringTasks.RESCHEDULING, {
          replyId: state.reply.id,
          sendAfter: date,
        });
        trackButtonClick({
          name: "submit_reschedule",
          reply_id: state.reply?.id,
          replied_at: state.reply?.replied_at,
          cluster: state.cluster,
        });

        await onActionDone();
      }
    },
    [onActionDone, effects.complete, state.reply?.id]
  );

  const complete = useCallback(async () => {
    if (state.isRescheduling) {
      await rescheduleAutoReply(state.nextScheduleDate);
    } else {
      await submitReplyClusterOrHint();
    }
  }, [
    state.isRescheduling,
    state.nextScheduleDate,
    rescheduleAutoReply,
    submitReplyClusterOrHint,
  ]);

  const dialogs: DialogPopup<CloseDialogKeys>[] = useClusteringPageDialogs({
    replyId: state.reply?.id,
    showHintDialog,
    pageExitPopupVisible,
    error,
    closeErrorDialog,
    setShowHintDialog,
    submitReplyCluster,
    setPageExitPopupVisible,
    unassign: effects.unassign,
  });

  const onViewExit = useCallback(() => {
    setPageExitPopupVisible(true);
  }, []);

  const onViewEnter = useCallback(
    () => effects.assign(ClusteringTasks.CLUSTERING, ""),
    [effects.assign]
  );

  const task = state.isRescheduling
    ? ClusteringTasks.RESCHEDULING
    : ClusteringTasks.CLUSTERING;

  const res: ClusteringPage = useMemo(
    () => ({
      task,
      showHintDialog,
      isLoading: state.metadata.isLoading,
      dialogs,
      complete,
      skip,
      onActionDone,
      onViewExit,
      onViewEnter,
    }),
    [
      task,
      state.metadata.isLoading,
      dialogs,
      complete,
      skip,
      onActionDone,
      onViewExit,
      onViewEnter,
    ]
  );

  return res;
};
