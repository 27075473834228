import React, { useContext, useMemo, useState, useEffect } from "react";

import { useDebounce } from "../../hooks/debounce";
import { DataTable } from "../../components/DataTable";
import { CheckboxBase, FeedBase, FeedTitleBase, TableHeading } from "./styled";
import { debounceTiming, feedTableTitle } from "./constants";
import { useSearch } from "../../hooks/search";
import { useFilter } from "../../hooks/filter";
import { usePagination } from "../../hooks/pagination";
import { useColumns, useFilterOptions } from "./hooks";
import { Loader } from "../../components/Loader/Loader";
import { FeedContext, FeedFilterProps } from "../../context/feed";

export const Feed = () => {
  const search = useSearch();
  const filter = useFilter();
  const pagination = usePagination();
  const debounce = useDebounce(pagination.state.page, debounceTiming);
  const getOptions = useFilterOptions();
  const getColumns = useColumns();

  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState<FeedFilterProps>({
    unclustered_only: ["false"],
  });

  const { feed, feedActions, isLoading } = useContext(FeedContext);
  const rowIdIndex = "id";
  const columns = getColumns();

  useEffect(() => {
    feedActions.fetchClients();
  }, []);

  useEffect(() => {
    filter.actions?.setOptions?.(getOptions());
    filter.actions?.setResultsCount?.(feed?.total || 0);
    pagination.actions.setTotalRecords(feed?.total || 0);
  }, [feed]);

  useEffect(() => {
    if (!isNaN(debounce) || debounce > 0) {
      const { limit, offset } = pagination.actions.getLimitAndOffset();
      const f = { ...(filter?.state?.filters || {}), ...(filters || {}) };
      feedActions.fetch(limit, offset, searchTerm, f);
    }
  }, [
    debounce,
    pagination.state.rowsPerPage,
    searchTerm,
    filters,
    filter?.state?.filters,
  ]);

  const handleTableChange = (page: number, rowsPerPage: number) => {
    pagination.actions.changePage(page, rowsPerPage);
  };

  const renderHeading = (headingLabel: string) => (
    <TableHeading>{headingLabel}</TableHeading>
  );

  // this entire block would be removed when full filter is implemented
  const feedTitle = useMemo(() => {
    return (
      <FeedTitleBase>
        <h1 style={{ marginBottom: "5px" }}>{feedTableTitle} </h1>
        <CheckboxBase className="container">
          Show unclustered responses
          <input
            type="checkbox"
            onClick={() => {
              setFilters({
                ...filters,
                unclustered_only:
                  filters.unclustered_only?.[0] === "true"
                    ? ["false"]
                    : ["true"],
              });
            }}
          />
          <span className="checkmark"></span>
        </CheckboxBase>
      </FeedTitleBase>
    );
  }, [filters.unclustered_only]);

  return !feed && isLoading ? (
    <Loader />
  ) : (
    <FeedBase>
      {feed ? (
        <DataTable
          rowIdIndex={rowIdIndex}
          title={feedTitle}
          dataSource={feed.data}
          renderHeading={renderHeading}
          columns={columns}
          isLoading={isLoading}
          count={pagination?.state?.totalRecords}
          onChange={handleTableChange}
          onSearchChange={setSearchTerm}
          filter={filter}
          search={search}
          // once full filter is implemented, prop would be removed
          filters={filters}
        />
      ) : null}
    </FeedBase>
  );
};
