import { SendRequest } from "./types";
import { sendRequest } from "./http-fetch";

export const createRequestSenderWithAuth = (bearerToken: string) => {
  const sr: SendRequest = async <ResBody, ReqBody>(url: string, options: any) => {
    const authHeader = {
      headers: bearerToken
        ? { Authorization: bearerToken }
        : { Authorization: "" },
    };

    const opt = {
      ...authHeader,
      ...options,
    };
    return await sendRequest<ResBody, ReqBody>(url, opt);
  };

  return sr;
};
