import { SetStateAction } from "react";
import { AuthEffects, AuthStateContext, LoginRes } from "./types";

export function getDefaultAuthenticationContext(): AuthStateContext {
  const error = new Error(
    "Function not implemented. Ensure AuthStateContext is within scope"
  );
  return [
    {
      isAuthenticated: true,
      credentials: {},
      isAutoSignIn: false,
      bearerToken: "",
      isLoading: true,
      initialLoad: true,
    },
    {
      setAuth: function (_credentials: LoginRes): void {
        throw error;
      },
      getAuthHeaderValue: function (): string {
        throw error;
      },
      isAuthorized: function (_role: string): boolean {
        throw error;
      },
      removeAuth: function (): void {
        throw error;
      },
      err: function (_message: string): void {
        throw error;
      },
      loading: function (): void {
        throw error;
      },
      setInitialLoad: function (value: SetStateAction<boolean>): void {
        throw new Error("Function not implemented.");
      },
    },
  ];
}

export function getDefaultAuthEffects(): AuthEffects {
  const error = new Error(
    "Function not implemented. Ensure AuthEffectsContext"
  );
  return {
    login: function (idToken: string): Promise<void> {
      throw error;
    },
    logout: function (): Promise<void> {
      throw error;
    },
  };
}
