import React, { useContext } from "react";
import { ClusteringHistoryWrapper } from "./styled";
import { ClusteringHistoryList } from "./ClusteringHistoryList";
import { State } from "../../../../context/stores/clustering";

export const ReplyViewClusteringHistory = () => {
  const [state] = useContext(State.ReplyClusterStateContext);

  return state.replyClusteringHistory?.length ? (
    <ClusteringHistoryWrapper>
      <ClusteringHistoryList history={state.replyClusteringHistory!} />
    </ClusteringHistoryWrapper>
  ) : null;
};
