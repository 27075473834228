export type DialogBoxActions = {
  name: string;
  type: "primary" | "secondary";
  key: CloseDialogKeys;
}[];

export type DialogPopup<T> = {
  id?: string;
  isVisible?: boolean;
  mainText: string;
  subText?: string;
  onActionCall: (key: T) => void;
  actions: Array<{
    name: string;
    type: "primary" | "secondary";
    key: T;
  }>;
};

export enum CloseDialogKeys {
  GO_BACK,
  STOP,
}
