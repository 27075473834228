import React from "react";
import { log, MixPanelEvent } from "./modules/mixpanel";
import { ErrorComp } from "./components/ErrorComp";

export class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean },
  {}
> {
  constructor(
    props:
      | { children: React.ReactNode }
      | Readonly<{ children: React.ReactNode }>
  ) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, info) {
    log(MixPanelEvent.APP_CRASHED, { error, info });
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorComp
          message="Snap; App crashed :("
          action={() => {
            window.location.href = "/";
          }}
          actionText="Reload App"
        />
      );
    }
    return this.props.children;
  }
}
