import React, { ChangeEvent, FC, useState } from "react";
import { FilterOption, SelectedFilterProps } from "../../../../hooks/filter";
import { FilterControl } from "../FilterControl";
import { Input } from "../../../Form/FormControls";
import { Collapsible } from "../../../Collapsible";
import { FilterControlWrapper, FilterControlsWrapper } from "./styled";
import { SelectedFilters } from "../SelectedFilters";

export type FilterControlsProps = {
  options?: Array<FilterOption>;
  onFilterChange: (newOptions: SelectedFilterProps) => void;
  filters?: SelectedFilterProps;
};

export const FilterControls: FC<FilterControlsProps> = ({
  options,
  onFilterChange,
  filters,
}) => {
  const [searchTerms, setSearchTerms] = useState(
    options?.reduce?.(
      (prev, option) => ({ ...(prev || {}), [option.name]: "" }),
      {} as Record<keyof typeof options, string>
    )
  );
  const [filterSelectionStates, setFilterSelectionStates] = useState(
    options?.reduce?.(
      (prev, option) => ({ ...(prev || {}), [option.name]: false }),
      {} as Record<keyof typeof options, boolean>
    )
  );

  const handleOptionClick = (opt: FilterOption, value: string | number) => {
    const newFilter = {
      ...filters,
      [opt.name]: filters?.[opt.name]
        ? [...new Set([...filters[opt.name], value])]
        : [value],
    };

    if (opt.type === "checkbox") {
      newFilter[opt.name] = [value];
    }

    onFilterChange(newFilter);
  };

  const handleDateSelected = (opt: FilterOption, date: Date) => {
    onFilterChange({ ...filters, [opt.name]: [date] });
  };

  const handleFilterInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerms({
      ...(searchTerms || ({} as Record<keyof FilterOption[], string>)),
      [e.target.name]: e.target.value,
    });
  };

  return (
    <FilterControlsWrapper>
      {options?.map?.((option) => {
        return (
          <FilterControlWrapper key={option.name}>
            <Collapsible
              onCollapseChange={(collapsed) => {
                const s = { ...filterSelectionStates };
                s[option.name] = !collapsed;
                setSearchTerms({
                  ...(searchTerms ||
                    ({} as Record<keyof typeof options, string>)),
                  [option.name]: "",
                });
                setFilterSelectionStates(s[option.name]);
              }}
              collapseDefault={true}
              renderToggler={() => (
                <Input
                  placeholder={option.placeholder}
                  icon={option?.renderIcon?.(
                    !filterSelectionStates?.[option.name]
                  )}
                  iconPosition={option.iconPosition}
                  label={option.label}
                  name={option.name}
                  value={searchTerms?.[option.name] || ""}
                  disabled={!option.searchable}
                  onChange={handleFilterInputChange}
                />
              )}
              renderCollapsible={() => {
                const opt = { ...option };
                const optValues = (opt?.getOptionValues?.() || []).filter(
                  (val) => {
                    if (typeof val === "object") {
                      return val.name
                        .toLowerCase()
                        .includes(
                          searchTerms?.[option.name]?.toLowerCase?.() || ""
                        );
                    }

                    return val
                      .toString()
                      .toLowerCase()
                      .includes(
                        searchTerms?.[option?.name]?.toLowerCase?.() || ""
                      );
                  }
                );

                opt.getOptionValues = () => optValues;

                return (
                  <FilterControl
                    option={opt}
                    selectedOptions={filters?.[opt.name]}
                    onOptionSelected={handleOptionClick}
                    onDateSelected={handleDateSelected}
                    search={searchTerms?.[option?.name]}
                  />
                );
              }}
            />
          </FilterControlWrapper>
        );
      })}

      <SelectedFilters
        filters={filters}
        onFilterItemDeleted={(newFilters) => onFilterChange(newFilters)}
      />
    </FilterControlsWrapper>
  );
};
