import styled from "styled-components";

export const InputBase = styled.input`
  display: flex;
  position: relative;
  flex: 1 1 auto;
  padding: 0.5rem;
  background: inherit;
  border: none;
`;

export const InputWithLabelBase = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

export const Label = styled.span`
  padding: .2rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 140%;

  display: flex;
  align-items: center;
  text-transform: uppercase;
`;
