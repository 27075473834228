import React from "react";
import { MainRouter } from "./routes/MainRouter";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { ErrorBoundary } from "./ErrorBoundary";
import { GlobalContextProvider } from "./context/global";
import { AuthStoreProvider } from "./context/stores/auth/auth-store";

export const App = () => {
  return (
    <AuthStoreProvider>
      <GlobalContextProvider>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <MainRouter />
          </ErrorBoundary>
        </ThemeProvider>
      </GlobalContextProvider>
    </AuthStoreProvider>
  );
};
