import styled from "styled-components";

export const FeedBase = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  grid-gap: 24px;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.5rem;
  height: 75vh;
`;

export const ForwardButton = styled.button`
  flex: 1 1 auto;
  padding: 4px 16px;
  background: ${(props) => props.theme.colors["cold-grey"][50]};
  border-radius: 100px;
  border: none;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #002648;
  }
  &:hover svg path {
    stroke: #fff;
  }
  &.disabled {
    cursor: default;
    background: #f8f8f8;
  }

  &.disabled svg path {
    cursor: default;
    stroke: #dcdddd;
    background: #f8f8f8;
  }

  &.disabled:hover svg path {
    stroke: #dcdddd !important;
  }
`;

export const AnchorLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  padding: 4px 16px;
  background: ${(props) => props.theme.colors["cold-grey"][50]};
  border-radius: 100px;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none; /* Ensures the link doesn't have the default underline */

  &:hover {
    background: #002648;
  }
  &:hover svg path {
    stroke: #fff;
  }
  &.disabled {
    cursor: default;
    background: #f8f8f8;
  }

  &.disabled svg path {
    cursor: default;
    stroke: #dcdddd;
    background: #f8f8f8;
  }

  &.disabled:hover svg path {
    stroke: #dcdddd !important;
  }
`;


export const TableHeading = styled.span`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #9da2a2;
`;

export const FeedCellLarge = styled.span`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  display: flex;
  align-items: center;

  color: #0b0b0c;

  &.bold {
    font-weight: 500;
  }
`;

export const FeedCellMedium = styled.span`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;

  display: flex;
  align-items: center;

  color: #374242;

  & .light {
    color: #374242e6;
    font-weight: 400;
  }
`;

export const FeedTitleBase = styled.div`
  & > {
    font-family: "Work Sans";
    color: "#0B0B0C";
  }
  h1 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
`;

export const CheckboxBase = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
