import { useCallback } from "react";

import { ClusteringTasks } from "../../../../main-service/src/types/shared/shared";
import { useAuthRequestSender } from "../../hooks/http";

const baseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/clustering/tasks/skip`;

export const useSkip = () => {
  const sendRequest = useAuthRequestSender();

  const skipClusteringTask = useCallback(
    async (payload: { id: string }) => {
      return await sendRequest(
        `${baseUrl}/?task=${ClusteringTasks.CLUSTERING}`,
        {
          method: "POST",
          body: payload,
        }
      );
    },
    [sendRequest]
  );

  const skipClusteringErrorTask = useCallback(
    async (payload: { id: string }) => {
      return await sendRequest(
        `${baseUrl}/?task=${ClusteringTasks.ERROR_RESOLUTION}`,
        {
          method: "POST",
          body: payload,
        }
      );
    },
    [sendRequest]
  );

  const skip = useCallback(
    async <Payload extends { id: string }>(
      task: ClusteringTasks,
      payload: Payload
    ) => {
      switch (task) {
        case ClusteringTasks.CLUSTERING:
          return await skipClusteringTask(payload);
        case ClusteringTasks.RECLUSTERING:
          return {
            error: false,
            message: "unassigned",
            data: null,
          };
        case ClusteringTasks.ERROR_RESOLUTION:
          return await skipClusteringErrorTask(payload);
        default:
          return await skipClusteringTask(payload);
      }
    },
    [skipClusteringTask]
  );

  return skip;
};
