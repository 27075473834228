import React, { Route, Routes } from "react-router-dom";

import { Landing } from "../pages";
import { AppRoutes } from "./types";
import { withAuth } from "../hoc/withAuth";
import { LandingIntro } from "../containers/LandingIntro/index";
import { PageNotFound } from "../pages/NotFound";
import { Feed } from "../containers/Feed";
import { FeedContextProvider } from "../context/feed";
import { Role } from "../../../main-service/src/types/shared/shared";

const routes: AppRoutes = [
  { path: "", component: withAuth(LandingIntro) },
  {
    path: "feed",
    component: withAuth(
      (props) => (
        <FeedContextProvider>
          <Feed {...props} />{" "}
        </FeedContextProvider>
      ),
      [Role.MANAGER, Role.CS]
    ),
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

export const LandingRouter = () => {
  return (
    <Routes>
      <Route path="" element={<Landing />}>
        {routes.map((route) => {
          const Comp = route.component;

          return (
            <Route key={route.path} path={route.path} element={<Comp />} />
          );
        })}
      </Route>
    </Routes>
  );
};
