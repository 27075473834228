import React, { createContext, useCallback, useMemo, useState } from "react";

import { CopilotSettingsStateContext as CopilotSettingsContext } from "./types";
import { ResCopilotSettings } from "../../../api/admin/settings/use-copilot-settings";
import { getDefaultCopilotStateContext } from "./helpers";
import { PromptTestResultRes } from "../../../api/clustering-ai";

export const CopilotSettingsStateContext =
  createContext<CopilotSettingsContext>(getDefaultCopilotStateContext());

const useCopilotStateSettings = (): CopilotSettingsContext => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isBasicSettingsLoading, setIsBasicSettingsLoading] = useState<
    boolean | undefined
  >(false);
  const [settings, setSettings] = useState<
    ResCopilotSettings["data"] | undefined
  >();

  const [fullPrompt, setFullPrompt] = useState("");

  const [promptTestingResult, setPromptTestingResult] =
    useState<PromptTestResultRes["data"]>();
  const [promptTestingResultStatus, setPromptTestingResultStatus] = useState({
    isLoading: false,
    error: "",
  });

  const loading = useCallback(() => {
    setIsLoading(true);
    setError("");
  }, []);

  const onError = useCallback((err: string) => {
    setError(err);
    setIsLoading(false);
  }, []);

  const setCopilotSettings = useCallback((data: ResCopilotSettings["data"]) => {
    setError("");
    setSettings(data);
    setIsLoading(false);
  }, []);

  const setPromptTestResult = useCallback((result) => {
    setPromptTestingResult(result);
    setPromptTestingResultStatus({
      isLoading: false,
      error: "",
    });
  }, []);

  const state: CopilotSettingsContext[0] = useMemo(
    () => ({
      isLoading,
      error,
      settings,
      isBasicSettingsLoading,
      fullPrompt,
      promptTestingResult,
      promptTestingResultStatus,
    }),
    [
      isLoading,
      error,
      settings,
      isBasicSettingsLoading,
      fullPrompt,
      promptTestingResult,
      promptTestingResultStatus,
    ]
  );

  const actions: CopilotSettingsContext[1] = useMemo(
    () => ({
      setPromptTestingResultStatus,
      setPromptTestResult,
      setIsBasicSettingsLoading,
      loading,
      error: onError,
      setCopilotSettings,
      setFullPrompt,
    }),
    [
      loading,
      onError,
      setSettings,
      setIsBasicSettingsLoading,
      setPromptTestResult,
      setPromptTestingResultStatus,
    ]
  );

  return useMemo(() => [state, actions], [state, actions]);
};

export const CopilotSettingsStateProvider = ({ children }) => {
  const value = useCopilotStateSettings();

  return (
    <CopilotSettingsStateContext.Provider value={value}>
      {children}
    </CopilotSettingsStateContext.Provider>
  );
};
