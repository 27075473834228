export const theme = {
	primary: {
		orange: {
			900: '#943A1A',
			800: '#AB431E',
			700: '#BF4B21',
			600: '#D45325',
			500: '#EB5D29',
			400: '#FF6731',
			300: '#FF7F51',
			200: '#FF7F51',
			100: '#FF9872',
			50: '#FFA685',
			25: '#FFB296',
		},
		yellow: {
			900: '#8E6D21',
			800: '#A68027',
			700: '#BB8F2C',
			600: '#CE9E30',
			500: '#E2AE35',
			400: '#F6BD3A',
			300: '#F7C85B',
			200: '#F9CE6C',
			100: '#F9D37B',
			50: '#FAD98D',
			25: '#FADE9C',
		},
	}, 
	secondary: {
		blue: {
			900: '#001629',
			800: '#001930',
			700: '#001C35',
			600: '#001F3B',
			500: '#002342',
			400: '#002648',
			300: '#2B4B67',
			200: '#405D76',
			100: '#546D84',
			50: '#6B8195',
			25: '#7F92A3',
		},
	},
	colors: {
		green: {
			600: '#266D3A',
			500: '#94C446',
			300: '#C6DE9F',
			100: '#E5EED5',
			50: '#F3F6EF',
		},
		yellow: {
			600: '#B69225',
			500: '#E4B62E',
			300: '#EED793',
			100: '#F5EBD0',
			50: '#F8F4E9',
		},
		'x-blue': {
			600: '#001F3B',
			500: '#002342',
			300: '#2B4B67',
			100: '#546D84',
			50: '#6B8195',
		},
		'cold-grey': {
			800: '#111E1E',
			700: '#374242',
			600: '#535C5C',
			500: '#697171',
			400: '#818888',
			300: '#9DA2A2',
			200: '#B3B7B7',
			100: '#DCDDDD',
			50: '#EDEEEE',
			25: '#F8F8F8',
		}
	},
}
