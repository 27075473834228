import React from 'react';
import { MenuBase, MenuContainerBase, MenuItemBase } from './styled';

type MenuPopup = {
	isVisible?: boolean,
	header?: () => JSX.Element, // if you need anything to be shown above the menu.
	items: {
		label: string,
		action?: () => void,
		isActive?: boolean,
	}[],
	onItemClick?: () => void
}

const MenuItem = (props: { label, onClick, disabled }) => {
	return <MenuItemBase disabled={props.disabled} onClick={props.onClick}>
		{props.label}
	</MenuItemBase>
}

export const MenuPopup = (props: MenuPopup) => {
	return <MenuContainerBase>
		{props.header && props.header()}
		<MenuBase>
			{
				props.items.map(item => {
					const a = () => {
						item.action && item.action();
						props.onItemClick && props.onItemClick();
					};

					return <MenuItem
						key={item.label}
						label={item.label}
						onClick={a}
						disabled={!(item.isActive ?? true)}
					></MenuItem>
				})
			}
		</MenuBase>
	</MenuContainerBase>
}
