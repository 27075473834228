import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { ClusterData } from "../../../../../main-service/src/types/shared/api";
import {
  ClusteringCopilotAPI,
  ClusteringTaskManagementAPI,
} from "../../../api";
import { ClusteringEffects as CE, ClusteringEffectFn } from "./types";
import {
  getDefaultClusteringEffects,
  getSuggestionFromClusteringError,
} from "./helpers";
import { ClusteringTasks } from "../../../../../main-service/src/types/shared/shared";
import { ReplyClusterStateContext } from "./reply-cluster-state";

export const ClusteringEffectsContext = createContext<CE>(
  getDefaultClusteringEffects().clustering
);

export const useClusteringEffects = (): CE => {
  const [state, actions] = useContext(ReplyClusterStateContext);

  const completeAPI = ClusteringTaskManagementAPI.useComplete();
  const skipAPI = ClusteringTaskManagementAPI.useSkip();
  const assignAPI = ClusteringTaskManagementAPI.useAssign();
  const unassignAPI = ClusteringTaskManagementAPI.useUnassign();
  const getClusteringHintAPI = ClusteringCopilotAPI.useHint();

  useEffect(() => {
    const fetchHint = async () => {
      if (state.reply?.id) {
        const res = await getClusteringHintAPI(state.reply.id);
        actions.setHint(res.data?.data);
      }
    };

    fetchHint();
  }, [state.reply?.id, getClusteringHintAPI]);

  const assign: ClusteringEffectFn<string | undefined> = useCallback(
    async (task, id) => {
      actions.resetRequestMetadata();
      actions.setSuggestion(null);
      const result = await assignAPI(task, id as string);
      actions.setReplyAndMetadata(result);
      actions.setAssignedOn(Date.now());

      if (!result.data?.clusteringError) {
        return;
      }

      if (task === ClusteringTasks.ERROR_RESOLUTION) {
        const suggestion = getSuggestionFromClusteringError(
          result.data.clusteringError
        );
        actions.setSuggestion(suggestion);
      }
    },
    [
      assignAPI,
      actions.resetRequestMetadata,
      actions.setAssignedOn,
      actions.setReplyAndMetadata,
    ]
  );

  const complete: ClusteringEffectFn<ClusterData> = useCallback(
    async (task, payload) => {
      actions.resetRequestMetadata();

      const p = {
        ...(payload as ClusterData),
        duration: Date.now() - state.assignedOn,
      } as ClusterData;
      const res = await completeAPI(task, p);
      actions.resetAssignedOnAndRequestMeta(res);
    },
    [
      actions.resetRequestMetadata,
      actions.resetAssignedOnAndRequestMeta,
      completeAPI,
      state.assignedOn,
    ]
  );

  const skip: ClusteringEffectFn<string> = useCallback(
    async (task, id) => {
      actions.resetRequestMetadata();
      const res = await skipAPI(task, { id: id as string });

      actions.resetAssignedOnAndRequestMeta(res!);
    },
    [actions.resetAssignedOnAndRequestMeta, skipAPI]
  );

  const unassign: ClusteringEffectFn<string> = useCallback(
    async (task, id) => {
      actions.resetRequestMetadata();
      const res = await unassignAPI(task, id as string);

      actions.resetAssignedOnAndRequestMeta(res!);
    },
    [actions.resetAssignedOnAndRequestMeta, unassignAPI]
  );

  const cEffects: CE = {
    assign,
    complete,
    skip,
    unassign,
  };

  const clusteringEffects = useMemo(
    () => cEffects,
    [skip, assign, complete, unassign]
  );

  return clusteringEffects;
};

export const ClusteringEffectsProvider = ({ children }) => {
  const value = useClusteringEffects();

  return (
    <ClusteringEffectsContext.Provider value={value}>
      {children}
    </ClusteringEffectsContext.Provider>
  );
};
