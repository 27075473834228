import React from "react";
import { ErrorComp } from "../../components/ErrorComp";
import { useNavigate } from "react-router-dom";

export const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <ErrorComp
      message="Forbidden! Please reach out to
    Tech/Product in order to get the required permissions."
      action={() => {
        navigate(-2);
      }}
      actionText="Go back"
    />
  );
};
