import styled from "styled-components";

export const ErrorWrapper = styled.div`
  padding: 8px;
  width: 500px;
  height: 200px;
  border-radius: 16px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
`;

export const ErrorControls = styled.div`
  box-sizing: content-box;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  > * {
    margin-right: 10px;
  }

  button {
    padding: 12px 16px;
  }
`;
