import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { AdminAPI } from "../../../api";
import { UserManagementStateContext } from "./user-management-state";
import { User } from "../../../../../main-service/src/types/shared/shared";
import { getDefaultUserManagementEffects } from "./helpers";

export type UserManagementEffects = {
  editUser: (edit: Partial<User>) => Promise<void>;
  fetchUsers: () => Promise<void>;
};

export const UserManagementEffectsContext =
  createContext<UserManagementEffects>(getDefaultUserManagementEffects());

const useUserManagementEffects = () => {
  const [state, actions] = useContext(UserManagementStateContext);
  const userManagementAPI = AdminAPI.UserManagementAPI.useUserManagement();

  const editUser = useCallback(
    async (edit: Partial<User>) => {
      actions.setIsLoading(true);
      const res = await userManagementAPI.editUser(edit.id!, edit);

      if (res.error) {
        actions.handleEditError(res?.message || "failed to edit user");

        return;
      }

      actions.setRefresh(true);
    },
    [userManagementAPI.editUser, actions.handleEditError, actions.setRefresh]
  );

  const fetchUsers = useCallback(async () => {
    actions.setIsLoading(true);
    const res = await userManagementAPI.getUsers({
      ...actions.pagination.getLimitAndOffset(),
      search: state.search?.searchTerm,
    });

    if (!res.error) {
      actions.handleUsersFetchSuccess(res.data);

      return;
    }

    actions.handleUsersFetchError(res.message || "failed to fetch user");
  }, [
    state.search?.searchTerm,
    userManagementAPI.getUsers,
    actions.pagination.getLimitAndOffset,
    actions.handleUsersFetchError,
    actions.handleUsersFetchSuccess,
  ]);

  useEffect(() => {
    actions.search?.setDebounce?.(400);
    actions.pagination?.setTotalRecords?.(state.adminUsers?.total || 0);
  }, [
    state.adminUsers?.total,
    actions.pagination?.setTotalRecords,
    actions.search?.setDebounce,
  ]);

  useEffect(() => {
    if (state.shouldRefresh) {
      fetchUsers().finally(() => {
        actions.setShouldRefresh(false);
      });
    }
  }, [state.shouldRefresh]);

  useEffect(() => {
    fetchUsers();
  }, [state.pagination, state.search?.searchTerm]);

  return {
    editUser,
    fetchUsers,
  };
};

export const UserManagementEffectsProvider = ({ children }) => {
  const value = useUserManagementEffects();

  return (
    <UserManagementEffectsContext.Provider value={value}>
      {children}
    </UserManagementEffectsContext.Provider>
  );
};
