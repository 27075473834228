import React, { FC, PropsWithChildren } from "react";
import { Group, ConfigurationInputLabel } from "./styled";

export const InputItemFormWrapper: FC<
  PropsWithChildren<{ label?: string }>
> = ({ children, label }) => {
  return (
    <Group>
      <ConfigurationInputLabel>{label}</ConfigurationInputLabel>
      <div>{children}</div>
    </Group>
  );
};
