import { SetStateAction } from "react";
import { User } from "../../../../../main-service/src/types/shared/shared";
import { RES_AdminUsers } from "../../../../../main-service/src/types/shared/api";
import { UserManagementStateContext } from "./types";

export const timestampToUITime = (timestamp: string | undefined) => {
  if (!timestamp) {
    return "";
  }

  const date = new Date(timestamp);

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const convertUsersTimestampToUITime = (data: Partial<User>[]) => {
  return data.map((d) => {
    return {
      ...d,
      created_at: timestampToUITime(d.created_at),
      updated_at: timestampToUITime(d.updated_at),
    };
  });
};

export const getDefaultUserManagementStateContext =
  (): UserManagementStateContext => {
    return [
      {
        search: undefined,
        adminUsers: undefined,
        isLoading: false,
        userToEdit: undefined,
        editError: "",
        shouldRefresh: false,
        pagination: {
          page: 0,
          rowsPerPage: 0,
          totalRecords: 0,
        },
        usersFetchError: "",
      },
      {
        search: undefined,
        setRefresh: () => {},
        pagination: {
          setTotalRecords: function (_value: SetStateAction<number>): void {
            throw new Error(
              "Function not implemented. Ensure that UserManagementStateContext is in scope"
            );
          },
          changePage: function (_page: number, _rowsPerPage: number): void {
            throw new Error(
              "Function not implemented. Ensure that UserManagementStateContext is in scope"
            );
          },
          getLimitAndOffset: function (): { limit: number; offset: number } {
            throw new Error(
              "Function not implemented. Ensure that UserManagementStateContext is in scope"
            );
          },
        },
        setAdminUsers: function (
          _value: SetStateAction<RES_AdminUsers | undefined>
        ): void {
          throw new Error(
            "Function not implemented. Ensure that UserManagementStateContext is in scope"
          );
        },
        setIsLoading: function (_value: SetStateAction<boolean>): void {
          throw new Error(
            "Function not implemented. Ensure that UserManagementStateContext is in scope"
          );
        },
        setUserToEdit: function (
          _value: SetStateAction<Partial<User> | undefined>
        ): void {
          throw new Error(
            "Function not implemented. Ensure that UserManagementStateContext is in scope"
          );
        },
        setEditError: function (_value: SetStateAction<string>): void {
          throw new Error(
            "Function not implemented. Ensure that UserManagementStateContext is in scope"
          );
        },
        setShouldRefresh: function (_value: SetStateAction<boolean>): void {
          throw new Error(
            "Function not implemented. Ensure that UserManagementStateContext is in scope"
          );
        },
        handleUsersFetchSuccess: function (data: RES_AdminUsers): void {
          throw new Error(
            "Function not implemented. Ensure that UserManagementStateContext is in scope"
          );
        },
        setUsersFetchError: function (value: SetStateAction<string>): void {
          throw new Error(
            "Function not implemented. Ensure that UserManagementStateContext is in scope"
          );
        },
        handleUsersFetchError: function (error: string): void {
          throw new Error(
            "Function not implemented. Ensure that UserManagementStateContext is in scope"
          );
        },
        handleEditError: function (error: string): void {
          throw new Error(
            "Function not implemented. Ensure that UserManagementStateContext is in scope"
          );
        },
      },
    ];
  };

export const getDefaultUserManagementEffects = () => {
  return {
    editUser: function (edit: Partial<User>): Promise<void> {
      throw new Error(
        "Function not implemented. Ensure that UserManagementEffectsContext is in scope"
      );
    },
    fetchUsers: function (): Promise<void> {
      throw new Error(
        "Function not implemented. Ensure that UserManagementEffectsContext is in scope"
      );
    },
  };
};
