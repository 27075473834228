import React from "react";
import { LocaleTime } from "./LocaleTime";
import { LocaleDate } from "./LocaleDate";


export const LocaleDateTime = ({
  timestamp,
  dateClassName,
  timeClassName,
}: {
  timestamp: string;
  dateClassName?: string;
  timeClassName?: string;
}) => {
  return (
    <>
      <span className={dateClassName}>
        <LocaleDate timestamp={timestamp} />
      </span>
      {", "}
      <span className={timeClassName}>
        <LocaleTime timestamp={timestamp} />
      </span>
    </>
  );
};
