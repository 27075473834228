import { createContext, useMemo, useState } from "react";

export const paginationDefaults = {
  rowsPerPage: 10,
  currentPage: 1,
  totalRecords: 10,
};

export type PaginationProps = {
  state: {
    page: number;
    rowsPerPage: number;
    totalRecords: number;
  };
  actions: {
    setTotalRecords: React.Dispatch<React.SetStateAction<number>>;
    changePage: (page: number, rowsPerPage: number) => void;
    getLimitAndOffset: () => { limit: number; offset: number };
  };
};

export const PaginationContext = createContext<PaginationProps>({
  actions: {
    changePage: (_: number, __: number) => {},
    setTotalRecords: () => {},
    getLimitAndOffset: () => ({ limit: 10, offset: 0 }),
  },
  state: {
    page: paginationDefaults.currentPage,
    rowsPerPage: paginationDefaults.rowsPerPage,
    totalRecords: paginationDefaults.totalRecords,
  },
});

export const usePagination = (): PaginationProps => {
  const [totalRecords, setTotalRecords] = useState(
    paginationDefaults.totalRecords
  );
  const [page, setPage] = useState(paginationDefaults.currentPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    paginationDefaults.rowsPerPage
  );

  const getLimitAndOffset = () => {
    const limit = rowsPerPage;
    const offset = (page - 1) * limit;

    return { limit, offset };
  };

  /**
   *
   * @param p page
   * @param rppg rowsPerPage
   */
  const changePage = (p: number, rppg: number) => {
    setPage(p);
    setRowsPerPage(rppg);
  };

  const actions = {
    changePage,
    getLimitAndOffset,
    setTotalRecords,
  };

  const state = useMemo(
    () => ({
      page,
      rowsPerPage,
      totalRecords,
    }),
    [page, totalRecords, rowsPerPage]
  );

  return {
    state,
    actions,
  };
};
