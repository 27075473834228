import styled from "styled-components";

export const FilterLabel = styled.span`
  padding: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

export const FilterButton = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  border: inherit;
  border-radius: inherit;
`;

