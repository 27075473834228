import styled from "styled-components";

export const IconContainer = styled.span`
  position: relative;
  display: flex;
  max-width: 2rem;
  max-height: 2rem;
  padding: 0.1rem;

  align-items: center;
  justify-content: center;
  background: inherit;
`;
