import React, { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuthStore } from "../context/stores/auth/auth-store";
import { Role } from "../../../main-service/src/types/shared/shared";
import { Unauthorized } from "../pages/Unauthorized";
import { Backdrop } from "../components/Backdrop";
import { Spinner } from "../components/Spinner";

export const withAuth =
  (InnerComponent: FC, permittedRoles?: Role[]) =>
  (props: JSX.IntrinsicAttributes) => {
    const location = useLocation();
    const { store } = useAuthStore();
    const [state, reducers] = store;

    if (state.initialLoad) {
      return (
        <Backdrop>
          <Spinner />
        </Backdrop>
      );
    }

    if (!state.isAuthenticated && !location.pathname.endsWith("/login")) {
      return <Navigate to="/login" />;
    }

    if (state.isAuthenticated && location.pathname.endsWith("/login")) {
      return <Navigate to="/landing" />;
    }

    if (
      permittedRoles &&
      !permittedRoles.some((role) => reducers.isAuthorized(role))
    ) {
      return (
        <Backdrop>
          <Unauthorized />
        </Backdrop>
      );
    }

    return (
      <>
        <InnerComponent {...props} />
      </>
    );
  };
