import { useRef, useState, useEffect } from "react";
import { useAuthStore } from "../context/stores/auth/auth-store";

declare namespace google {
  namespace accounts {
    namespace id {
      function initialize(options: {
        client_id: string | undefined;
        auto_select: boolean;
        callback: (response: { credential: string }) => void;
      }): void;

      function renderButton(
        parent: HTMLElement | null,
        options: { theme: string; size: string }
      ): void;

      function prompt(callback: (notification: any) => void): void;
    }
  }
}

declare global {
  interface Window {
    google: typeof google;
  }
}

const initializeGoogleAuth = (
  onSuccess: {
    (idToken: string): Promise<void>;
    (idToken: string): Promise<void>;
    (idToken: string): Promise<void>;
    (arg0: string): void;
  },
  autoSignIn: boolean
) => {
  google.accounts.id.initialize({
    client_id: process.env.GOOGLE_CLIENT_ID,
    auto_select: autoSignIn,
    callback: async (response) => {
      await onSuccess(response.credential);
    },
  });
  google.accounts.id.renderButton(
    document.getElementById("google-sso-login-btn"),
    { theme: "outline", size: "large" }
  );
  google.accounts.id.prompt((notifications) => {
    if (notifications.isNotDisplayed() || notifications.isSkippedMoment()) {
      console.log(
        "Something is Fucked up with Google Prompt. Reload the page",
        notifications.isNotDisplayed(),
        notifications.isSkippedMoment()
      );
    }
  });
};

export const useGoogleLoginRef = () => {
  const { store, effects } = useAuthStore();
  const [state] = store;
  const parent = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      setTimeout(() => {
        setIsLoaded(true);
        if (parent.current) {
          Array.from(parent.current.children).forEach((c) =>
            c.classList.add("visible")
          );
          setTimeout(() => {
            if (!window.google)
              window.addEventListener("load", () =>
                initializeGoogleAuth(effects.login, state.isAutoSignIn)
              );
            else initializeGoogleAuth(effects.login, state.isAutoSignIn);
          }, 1200);
        }
      }, 500);
    }

    return () => {
      if (!state.isAuthenticated && !window.google)
        window.removeEventListener("load", () =>
          initializeGoogleAuth(effects.login, state.isAutoSignIn)
        );
    };
  }, [isLoaded]);

  return parent;
};
