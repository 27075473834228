import React, { FC, ReactNode } from "react";
import { FGBaseProps, FGroupBase } from "./styled";

export type FormGroupProps = FGBaseProps & { children: ReactNode; reverse?: boolean };

export const FormGroup: FC<FormGroupProps> = ({ children, ...props }) => {
  return (
    <FGroupBase
      row={props.row}
      alignItems={props.alignItems}
      reverse={props.reverse}
    >
      {children}
    </FGroupBase>
  );
};
