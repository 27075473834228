import { useCallback } from "react";

import { ClusteringTasks } from "../../../../main-service/src/types/shared/shared";
import { useAuthRequestSender } from "../../hooks/http";

const baseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/clustering/tasks/unassign`;

export const useUnassign = () => {
  const sendRequest = useAuthRequestSender();
  const unassignClusteringTask = useCallback(
    async (id: string) => {
      return await sendRequest(
        `${baseUrl}/?task=${ClusteringTasks.CLUSTERING}`,
        {
          method: "POST",
          body: { replyId: id },
        }
      );
    },
    [sendRequest]
  );

  const unassign = useCallback(
    async (task: ClusteringTasks, id: string) => {
      switch (task) {
        case ClusteringTasks.CLUSTERING:
          return await unassignClusteringTask(id);
        case ClusteringTasks.RECLUSTERING:
          return {
            error: false,
            message: "unassigned",
            data: null,
          };
        case ClusteringTasks.ERROR_RESOLUTION:
          // not implemented
          return {
            error: false,
            message: "unassigned",
            data: null,
          };
        default:
          return await unassignClusteringTask(id);
      }
    },
    [unassignClusteringTask]
  );

  return unassign;
};
