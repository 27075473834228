import styled from "styled-components";

export const ButtonBase = styled.button`
  background: ${(props) =>
    props.variant === "secondary" ? "#fff" : "#FF6731"};
  padding: ${(props) => {
    return props.size === "sm" ? "12px" : "24px 16px"}};

  color: #fff;
  font-size: 18px;

  border: 0;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: #ff7f51;
  }
  &:disabled {
    cursor: default;
    background: rgba(255, 127, 81, 0.3);
  }


`;
