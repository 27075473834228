import React, { useEffect, useState, useCallback } from "react";
import MainLogo from "~/src/assets/main-logo.svg";
import {
  HeaderBase,
  SessionNotification,
  UserIconBase,
  UserInfoArea,
} from "./styled";
import { UserOptions } from "./UserOptions";
import { useAuthStore } from "../../context/stores/auth/auth-store";
import { useGoogleLoginRef } from "../../hooks/use-google-login-ref";

const MINS_TO_WARN = 120000; // 2 MINS IN MILLISECONDS

const getTimeLeft = (expiresAt: number) => {
  const expDate = new Date(expiresAt);
  return expDate.getTime() - Date.now();
};

const AuthAlert = () => {
  const { store, effects } = useAuthStore();
  const [state] = store;
  const [showCountDown, setShowCountDown] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const ref = useGoogleLoginRef();

  const checkExpiration = useCallback(() => {
    const eAt = state.credentials.eAt;
    if (eAt) {
      const msLeft = getTimeLeft(eAt);
      if (msLeft <= 0) {
        effects.logout();
      } else if (msLeft <= MINS_TO_WARN) {
        setShowCountDown(true);
        setTimeLeft(msLeft);
      } else {
        setShowCountDown(false);
      }
    }
  }, [state.credentials.eAt, effects.logout]);

  useEffect(() => {
    checkExpiration();
    const intervalId = setInterval(checkExpiration, 1000);
    return () => clearInterval(intervalId);
  }, [checkExpiration]);

  const leftPad = (val: number) => (val < 10 ? `0${val}` : `${val}`);

  const formatTime = (ms: number) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${leftPad(minutes)}:${leftPad(seconds)}`;
  };

  if (!showCountDown) return <SessionNotification />;

  return (
    <SessionNotification ref={ref}>
      <span>This session is ending in {formatTime(timeLeft)}</span>
      <div id="google-sso-login-btn"></div>
    </SessionNotification>
  );
};

export const Header = () => {
  const { store } = useAuthStore();
  const [state] = store;
  const [isUserOptionsVisible, setUserOptionsVisibility] = useState(false);

  return (
    <HeaderBase className="p3">
      <MainLogo />

      <AuthAlert />

      <UserInfoArea>
        {state.isAuthenticated && (
          <UserIconBase
            src={state.credentials.picture}
            referrerPolicy="no-referrer"
            onClick={() => setUserOptionsVisibility(!isUserOptionsVisible)}
          />
        )}
      </UserInfoArea>
      {isUserOptionsVisible && (
        <UserOptions onMenuItemClick={() => setUserOptionsVisibility(false)} />
      )}
    </HeaderBase>
  );
};
