import styled from "styled-components";

export const SelectedFiltersBase = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;

  > div.filter-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;

    > .label {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 0.95rem;
      line-height: 140%;
      margin-bottom: 0.2rem;
    }

    div.wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;

      div.label {
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 140%;
      }

      div.filter-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: 1rem;
        margin-bottom: 1rem;
        gap: 16px;

        padding: 8px 16px;
        width: fit-content;
        background: #f7e0cf;
        border-radius: 100px;
        justify-content: space-evenly;

        p.label {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 140%;
        }

        span.close {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
        }
      }

      div.filter:last-child {
        margin-right: none;
      }
    }
  }
`;

export const NofilterSelected = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 140%;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
`;
