export enum E_Status {
  ASSIGNED = 'ASSIGNED',
  CLUSTERED = 'CLUSTERED',
  UNCLUSTERED = 'UNCLUSTERED',
  TRANSLATING = 'TRANSLATING',
  AUTOREPLIED = 'AUTOREPLIED',
  REPLIED = 'REPLIED',
  BOUNCED = 'BOUNCED',
}

export enum E_Type {
  AUTOREPLY = 'AUTOREPLY',
  RESPONSE = 'RESPONSE',
  SECONDARY_RESPONSE = 'SECONDARY_RESPONSE',
}

export enum E_Locks {
  LOCKED_FOR_SET_AS_AUTOREPLY = 'LOCKED_FOR_SET_AS_AUTOREPLY',
  LOCKED_FOR_SET_SEND_AFTER_DATE = 'LOCKED_FOR_SET_SEND_AFTER_DATE',
  LOCKED_FOR_SET_REPLY_TO_REPLIED = 'LOCKED_FOR_SET_REPLY_TO_REPLIED',
  LOCKED_FOR_SET_REPLY_TO_BOUNCED = 'LOCKED_FOR_SET_REPLY_TO_BOUNCED',
}
