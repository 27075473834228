import React, { useCallback, useMemo, useState } from "react";
import { ConfigurationList } from "../../../../../../components/ConfigurationPanel/ConfigurationList";
import { ContentCard } from "../../../../../../components/ContentCard";
import { Button } from "../../../../../../components/ConfigurationPanel/styled";
import { useCopilotSettingsStore } from "../../../../../../context/stores/copilot-settings/copilot-settings-store";
import { PromptResultExample } from "../../../../../../../../main-service/src/types/shared/clustering";
import { PromptExample } from "./PromptExample";
import { ExampleForm } from "./ExampleForm";

export const PromptExamplesContent = () => {
  const [adding, setAdding] = useState(false);
  const { store, effects } = useCopilotSettingsStore();
  const [state] = store;
  const examples = useMemo(
    () => state.settings?.value?.prompt?.examples || [],
    [state.settings?.value?.prompt?.examples]
  );

  const onSubmit = useCallback(
    (example: PromptResultExample) => {
      const update = [example, ...examples];

      effects.updatePrompt({ examples: update });
    },
    [examples, effects.updateAgentEmail]
  );

  const onDelete = useCallback(
    (id: number) => {
      const update = examples.filter((_example, index) => index !== id);
      effects.updatePrompt({ examples: update });
    },
    [examples]
  );

  return (
    <>
      <ConfigurationList>
        <Button
          onClick={() => {
            setAdding(true);
          }}
        >
          Add Example
        </Button>
        {examples.map((example, index) => (
          <PromptExample
            key={index}
            email={example.email}
            id={index}
            categorization={example.categorization}
            onDelete={onDelete}
          />
        ))}
      </ConfigurationList>
      {adding ? (
        <ExampleForm onClose={() => setAdding(false)} onSubmit={onSubmit} />
      ) : null}
    </>
  );
};

export const PromptExamples = () => {
  const content = useMemo(
    () => ({
      header: { title: "Examples" },
      body: () => <PromptExamplesContent />,
    }),
    []
  );

  return <ContentCard isLoading={false} data={content} />;
};
