import React from "react";
import FirstPageArrow from "../../assets/first-page-arrow.svg";
import PrevPageArrow from "../../assets/prev-page-arrow.svg";
import NextPageArrow from "../../assets/next-page-arrow.svg";
import LastPageArrow from "../../assets/last-page-arrow.svg";

import {
  Divider,
  PaginationButton,
  PaginationControlLabel,
  PaginationControlWrapper,
  PaginationInput,
  ResultsPerPageDropdown,
} from "./styled";

export type TPagination = {
  count: number;
  maxPage: number;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  itemsOnPage: number;
};

export type TChangePage = (
  page: number,
  dataCount?: number,
  rowsPerPage?: number
) => void;

export const initialPagination: TPagination = {
  itemsOnPage: 0,
  count: 100,
  maxPage: 10,
  page: 1,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 25, 50, 100],
};

export const TablePagination = ({
  pagination,
  changePage,
}: {
  pagination: TPagination;
  changePage: TChangePage;
}) => {
  const onPageChange = (num: number) => {
    if (num < 1) {
      return;
    }

    changePage(num);
  };

  const onFirstPage = () => {
    changePage(1);
  };

  const onLastPage = () => {
    changePage(pagination.maxPage);
  };

  const onPrevPage = () => {
    if(pagination.page <= 1) {
        return 
    }

    changePage(pagination.page - 1);
  };

  const onNextPage = () => {
    changePage(
      pagination.page > pagination.maxPage
        ? pagination.page
        : pagination.page + 1
    );
  };

  return (
    <PaginationControlWrapper>
      {/* Prev controls */}
      <PaginationControlWrapper>
        <PaginationButton onClick={() => onFirstPage()}>
          <FirstPageArrow />
        </PaginationButton>
        <PaginationButton
          onClick={() => {
            onPrevPage();
          }}
        >
          <PrevPageArrow />
        </PaginationButton>
      </PaginationControlWrapper>
      <PaginationControlWrapper>
        <PaginationControlLabel>Page</PaginationControlLabel>
        <PaginationInput
          type="number"
          onChange={(e: { target: { value: string; }; }) => onPageChange(Number.parseInt(e.target.value, 10))}
          value={pagination.page}
        />
        <PaginationControlLabel>of {pagination.maxPage}</PaginationControlLabel>
      </PaginationControlWrapper>
      <PaginationControlWrapper>
        <PaginationButton onClick={() => onNextPage()}>
          <NextPageArrow />
        </PaginationButton>
        <PaginationButton onClick={() => onLastPage()}>
          <LastPageArrow />
        </PaginationButton>
      </PaginationControlWrapper>

      <PaginationControlWrapper>
        <Divider />
        <PaginationControlLabel>Results per page</PaginationControlLabel>
        <ResultsPerPageDropdown
          onChange={(e) =>
            changePage(1, pagination.count, Number.parseInt(e.target.value, 10))
          }
          value={pagination.rowsPerPage}
        >
          {pagination.rowsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </ResultsPerPageDropdown>
        <Divider />
        <PaginationControlLabel>
          Showing {isNaN(pagination.page) ? " ":  pagination.rowsPerPage * pagination.page} -{" "}
          {isNaN(pagination.page) ? " ": pagination.rowsPerPage * pagination.page + pagination.itemsOnPage} of{" "}
          {""}
          {pagination.count}
        </PaginationControlLabel>
      </PaginationControlWrapper>
    </PaginationControlWrapper>
  );
};
