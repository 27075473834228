import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { CopilotSettingsEFfectsContext as Effects } from "./types";
import { CopilotSettingsStateContext } from "./copilot-settings-state";
import { CopilotSettingsAPI } from "../../../api/admin/settings";
import { getDefaultCopilotSettingsEffects } from "./helpers";
import { PromptConfig } from "../../../../../main-service/dist/src/types/shared/clustering";
import {
  CLUSTERING_AI_MODES,
  ClusteringCopilotSettings,
} from "../../../../../main-service/src/types/shared/settings";
import { ClusteringAIAPI } from "../../../api";

export const CopilotSettingsEffectsContext = createContext<Effects>(
  getDefaultCopilotSettingsEffects()
);

const useCopilotEffects = (): Effects => {
  const [state, actions] = useContext(CopilotSettingsStateContext);

  const { getSettings, updateSettings, getPrompt } =
    CopilotSettingsAPI.useCopilotSettings();
  const clusteringAIAPI = ClusteringAIAPI.useClusteringAI();

  const fetchSettings = useCallback(async () => {
    actions.loading();
    const res = await getSettings();

    if (res.error) {
      actions.error(res.message);
      return;
    }

    actions.setCopilotSettings(res.data.data);
  }, [getSettings, actions.error, actions.loading, actions.setCopilotSettings]);

  const updateCopilotBasicProperties = useCallback(
    async (props: Partial<ClusteringCopilotSettings>) => {
      if (!state?.settings?.value) {
        return;
      }

      if (!props) {
        return;
      }

      const update = { ...state.settings.value, ...props };

      actions.setIsBasicSettingsLoading(true);
      const res = await updateSettings(update);
      if (res.error) {
        actions.error(
          res.message || `failed to update ${Object.keys(props).pop() || ""}`
        );
        actions.setIsBasicSettingsLoading(false);
        return;
      }

      actions.setCopilotSettings(res.data?.data);
      actions.setIsBasicSettingsLoading(false);
    },
    [
      updateSettings,
      actions.setCopilotSettings,
      actions.setIsBasicSettingsLoading,
      state?.settings?.value,
    ]
  );

  const setCopilotEnabled = useCallback(
    async (enabled: boolean) => {
      updateCopilotBasicProperties({ enabled });
    },
    [updateCopilotBasicProperties]
  );

  const updateMode = useCallback(
    async (mode: (typeof CLUSTERING_AI_MODES)[number]) => {
      updateCopilotBasicProperties({ mode });
    },
    [updateCopilotBasicProperties]
  );

  const updateAgentEmail = useCallback(
    async (agentEmail: string) => {
      updateCopilotBasicProperties({ agentEmail });
    },
    [updateCopilotBasicProperties]
  );

  const updateConfidenceLevelToAct = useCallback(
    (confidenceLevelToAct: number) => {
      updateCopilotBasicProperties({ confidenceLevelToAct });
    },
    [updateCopilotBasicProperties]
  );

  const setHintEnabled = useCallback(
    (hintEnabled: boolean) => {
      updateCopilotBasicProperties({ hintEnabled });
    },
    [updateCopilotBasicProperties]
  );

  const updatePrompt = useCallback(
    (prompt: Partial<PromptConfig>) => {
      if (!state.settings?.value?.prompt) {
        return;
      }

      const p = { ...state.settings?.value?.prompt, ...prompt };
      updateCopilotBasicProperties({ prompt: p });
    },
    [updateCopilotBasicProperties]
  );

  const fetchFullPrompt = useCallback(async () => {
    const p = await getPrompt();
    if (p.error) {
      return;
    }

    actions.setFullPrompt(p.data?.data || "Prompt not found");
  }, [getPrompt, actions.setFullPrompt]);

  const testPrompt = useCallback(
    async (email: string) => {
      actions.setPromptTestingResultStatus({
        isLoading: true,
        error: "",
      });
      const test = await clusteringAIAPI.testPrompt(email);
      if (test.error) {
        actions.setPromptTestingResultStatus({
          isLoading: false,
          error: test.message || "",
        });
        return;
      }


      actions.setPromptTestResult(test.data.data);
    },
    [clusteringAIAPI.testPrompt, actions.setPromptTestResult]
  );

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    fetchFullPrompt();
  }, [fetchFullPrompt, state.settings?.value?.prompt]);

  return useMemo(
    () => ({
      testPrompt,
      updatePrompt,
      setHintEnabled,
      updateConfidenceLevelToAct,
      updateMode,
      updateAgentEmail,
      setCopilotEnabled,
      fetchSettings,
    }),
    [
      updatePrompt,
      fetchSettings,
      setCopilotEnabled,
      updateMode,
      updateAgentEmail,
      setHintEnabled,
    ]
  );
};

export const CopilotSettingsEffectsProvider = ({ children }) => {
  const value = useCopilotEffects();

  return (
    <CopilotSettingsEffectsContext.Provider value={value}>
      {children}
    </CopilotSettingsEffectsContext.Provider>
  );
};
