import React from "react";
import { LoginPageBase, Title } from "./styled";
import { useGoogleLoginRef } from "../../hooks/use-google-login-ref";

export const Login = () => {
  const ref = useGoogleLoginRef();
  return (
    <LoginPageBase ref={ref}>
      <Title>Clustering App</Title>
      <div id="google-sso-login-btn"></div>
    </LoginPageBase>
  );
};
