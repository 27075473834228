import React from 'react';
import { ContentCardBodyBase, TextPlaceholder } from './styled';

export type ContentCardBody = {
	isLoading: boolean,
	content: () => JSX.Element,
}

const Loading = () => {
	return <>
		<TextPlaceholder width="80%"/>	
		<TextPlaceholder width="100%"/>	
		<TextPlaceholder width="100%"/>	
		<TextPlaceholder width="60%"/>	
		<TextPlaceholder width="0%"/>	
		<TextPlaceholder width="20%"/>	
		<TextPlaceholder width="5%"/>	
	</>
}

export const ContentCardBody = (props: ContentCardBody) => {
	const {isLoading, content} = props;
	
	const Content = () => content();
	
	return <ContentCardBodyBase>
		{ isLoading ? <Loading/> : <Content/> }
	</ContentCardBodyBase>
}
