import React, { FC } from "react";
import { NofilterSelected, SelectedFiltersBase } from "./styled";

export const SelectedFilters: FC<{
  filters?: Record<string, Array<string | number | Date>>;
  onFilterItemDeleted: (
    updatedFilters: Record<string, (string | number | Date)[]>
  ) => void;
}> = ({ filters, onFilterItemDeleted }) => {
  const keys = Object.keys(filters || []);

  const getFilterItemValue = (v: string | number | Date) => {
    return typeof v === "object" && v !== null
      ? v.toLocaleDateString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : v;
  };

  const removeFilterItem = (k: string, v: string | number | Date) => {
    if(!filters) {
      return
    }
  
    const newVals = filters[k]?.filter?.((val) => {
      return val !== v;
    });

    onFilterItemDeleted({ ...filters, [k]: newVals });
  };

  if (keys.length === 0) {
    return <NofilterSelected>No filter selected</NofilterSelected>;
  }

  return (
    <SelectedFiltersBase>
      {keys.length > 0
        ? keys.map((key) => {
            const field = key.split("_").join(" ");
            const filterValues = filters?.[key];

            return filterValues?.length ? (
              <div className="filter-group" key={key}>
                <div className="label">{field}</div>
                <div className="wrapper">
                  {filterValues.map((v) => (
                    <div className="filter-item" key={getFilterItemValue(v)}>
                      <p className="label">{getFilterItemValue(v)}</p>
                      <span
                        className="close"
                        onClick={() => {
                          removeFilterItem(key, v);
                        }}
                      >
                        x
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ) : null;
          })
        : null}
    </SelectedFiltersBase>
  );
};
