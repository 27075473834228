import React from "react";
import { ErrorComp } from "../../components/ErrorComp";
import { useNavigate } from "react-router-dom";

export const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <ErrorComp
      message="The page You're looking for does not exist:("
      action={() => {
        navigate("/");
      }}
      actionText="Go back to home page"
    />
  );
};
