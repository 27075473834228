import React, { FC, useCallback } from "react";
import { OptionProps, Option } from "./Option";
import { OptionSuggestion } from "./Option/types";

export type OptionsProps = {
  options: Omit<OptionProps, "onSelect" | "isSelected">[];
  color?: string;
  selectedOption?: string;
  suggestion?: OptionSuggestion | null;
  selectedSubOption?: string;
  onSelect: (mainOption: string, subOption?: string) => void;
};

export const Options: FC<OptionsProps> = ({
  options,
  color,
  selectedOption,
  selectedSubOption,
  suggestion,
  onSelect,
}) => {
  const isCurrentOptionSelected = useCallback(
    (optionName: string, subOptionName: string) => {
      if (!selectedOption && !selectedSubOption) {
        return false;
      }
      if (!subOptionName) {
        return selectedOption === optionName;
      }

      return (
        selectedOption === optionName && selectedSubOption === subOptionName
      );
    },
    [selectedOption, selectedSubOption]
  );

  return options.map((option) => {
    return (
      <Option
        color={color}
        key={option.name}
        name={option.name}
        suggestion={suggestion}
        tip={option.tip}
        subOptions={option.subOptions}
        onSelect={onSelect}
        isSelected={(mainOption, subOption) => {
          return isCurrentOptionSelected(mainOption, subOption || "");
        }}
      />
    );
  });
};
