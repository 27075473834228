import React from "react";
import { AvatarWrapper } from "./styled";

export const Avatar = ({
  initials,
  imgUrl,
}: {
  initials?: string;
  imgUrl?: string;
}) => {
  const icon = imgUrl ? (
    <img src={imgUrl} alt="avatar" />
  ) : (
    <span>{initials}</span>
  );

  return <AvatarWrapper>{icon}</AvatarWrapper>;
};
