import styled, { keyframes } from 'styled-components';

const SmoothBlinking = keyframes`
	from { opacity: 0.2; }
	to { opacity: 1; }
`;

export const ContentCardBase = styled.div`
	background: #fff;
	border-radius: 8px;
	overflow: hidden;
`;

export const ContentCardHeaderBase = styled.div`
	padding: 8px 16px;
	background: #edeeee;
	display: flex;
	align-items: center;
	grid-gap: 8px;
	position: relative;
`;

export const ContentCardBodyBase = styled.div`
	padding: 16px;
	white-space: pre-wrap;
	font-size: 14px;
`;

export const ContentCardIcon = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	background: #002648;
	color: #fff;
	> * {
		font-size: 0.83rem;
	}
`;

export const TextPlaceholder = styled.div`
	background: #eee;
	height: 15px;
	width: ${props => props.width ? props.width : '100%'};
	margin-bottom: 10px;
	animation: ${SmoothBlinking} 1s alternate infinite;
`
	
export const OptionsIconBase = styled.div`
	width: 25px;
	height: 25px;
	cursor: pointer;

	${props => props.isLoading && `
		cursor: default;
		opacity: 0.3;
	`}
`;

export const Title = styled.h3`
	font-size: 16px;
	color: #002648;
	flex-grow: 1;
	font-weight: 400;
`;

export const SubTitle = styled.h5`
	font-weight: 500;
	font-size: 12px;
	color: #002648;
`;
