import { Role } from "../../../../../main-service/src/types/shared/shared";
import { Tab } from "../types";

export const determineLeastStrictTab = (defaultTab: Tab) => {
  return (tab: Tab) => {
    if (tab.roles.length === 0) {
      return tab;
    }

    if (tab.roles.includes(Role.MANAGER)) {
      return tab;
    }

    if (
      tab.roles.includes(Role.ADMIN) &&
      (!defaultTab.roles.includes(Role.MANAGER) ||
        defaultTab.roles.length === 0)
    ) {
      return defaultTab;
    }
  };
};
