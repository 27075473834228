import React, { FC } from "react";
import PausedIllustration from "~/src/assets/paused-illustration.svg";
import { Clock } from "../../components/Clock";
import { AsideImageBase } from "../LandingIntro/styled";
import { ContainerBase, PausedContentBase } from "./styled";

export const NoReplyView: FC<{ retry: () => Promise<void> }> = ({ retry }) => {
  const onClockStop = async () => {
    await retry();
  };

  return (
    <ContainerBase className="with-padding">
      <AsideImageBase>
        <PausedIllustration />
      </AsideImageBase>
      <PausedContentBase>
        <h1>No Replies to Cluster!</h1>
        <h3>The system is trying to fetch new replies for you to cluster</h3>
        <Clock
          className="countdown"
          variant="countdown"
          seconds={30}
          onStop={onClockStop}
        />
      </PausedContentBase>
    </ContainerBase>
  );
};
