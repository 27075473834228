import React from "react";
import { ButtonBase } from "./styled";

type ButtonProps = {
	size?: string;
	disabled?: boolean,
	variant: "primary" | "secondary",
	label: string,
	onClick: () => void
};

export const Button = (props: ButtonProps) => {
	return <ButtonBase {...props}>
		{props.label}
	</ButtonBase>
}
