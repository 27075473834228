import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Header } from "../containers/Header";
import { withAuth } from "../hoc/withAuth";
import { Settings } from "../pages/Settings";
import { AppRoutes } from "./types";
import { LandingRouter } from "./LandingRouter";
import { PageNotFound } from "../pages/NotFound/index";
import { Reporting } from "../pages/Reporting";
import { Unauthorized } from "../pages/Unauthorized";
import { ClusteringRouter } from "./ClusteringRouter";
import { Login, Logout } from "../pages";
import { Role } from "../../../main-service/src/types/shared/shared";

const routes: AppRoutes = [
  { path: "", component: () => <Navigate to="/landing" /> },
  { path: "/settings", component: Settings },
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/landing/*", component: LandingRouter },
  { path: "/clustering/*", component: ClusteringRouter },
  { path: "/reporting", component: Reporting, roles: [Role.MANAGER] },
  { path: "/unauthorized", component: Unauthorized, noauth: true },
  {
    path: "*",
    component: PageNotFound,
    noauth: true,
  },
];

export const MainRouter = () => {
  return (
    <>
      <BrowserRouter>
        <Header />
        <div className="full flex main-container">
          <Routes>
            {routes.map((route, i) => {
              const ComponentWithAuth = route.noauth
                ? route.component
                : withAuth(route.component, route.roles);
              return (
                <Route
                  key={i}
                  path={route.path}
                  element={<ComponentWithAuth />}
                />
              );
            })}
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
};
