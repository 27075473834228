import React, { useContext } from "react";
import {
  ClusteringEffectsContext,
  ClusteringEffectsProvider,
} from "./clustering-effects";
import { ReplyManagementEffectsProvider } from "./reply-management-effect";
import {
  ReplyClusterStateContext,
  ReplyClusterStateProvider,
} from "./reply-cluster-state";
import { ClusteringStoreContext as CSC } from "./types";

export const useClusteringStore = (): CSC => {
  const store = useContext(ReplyClusterStateContext);
  const effects = useContext(ClusteringEffectsContext);

  return {
    store,
    effects,
  };
};

export const ClusteringStoreProvider = ({ children }) => {
  return (
    <ReplyClusterStateProvider>
      <ClusteringEffectsProvider>
        <ReplyManagementEffectsProvider>
          {children}
        </ReplyManagementEffectsProvider>
      </ClusteringEffectsProvider>
    </ReplyClusterStateProvider>
  );
};
