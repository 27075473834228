import { RES_AdminUsers } from "../../../../../main-service/src/types/shared/api";
import { User } from "../../../../../main-service/src/types/shared/shared";
import { PaginationProps } from "../../../hooks/pagination";
import { SearchProps } from "../../../hooks/search";

export type UserManagementState = {
  search: SearchProps["state"] | undefined;
  adminUsers: RES_AdminUsers | undefined;
  isLoading: boolean;
  userToEdit: Partial<User> | undefined | null;
  editError: string;
  usersFetchError: string;
  shouldRefresh: boolean;
  pagination: PaginationProps["state"];
};

export type UserManagementActions = {
  handleUsersFetchSuccess(data: RES_AdminUsers): void;
  setUsersFetchError: React.Dispatch<React.SetStateAction<string>>;
  handleUsersFetchError(error: string): void;
  handleEditError(error: string): void;
  setRefresh(refresh: boolean): void;
  search: SearchProps["actions"] | undefined;
  pagination: PaginationProps["actions"];
  setAdminUsers: React.Dispatch<
    React.SetStateAction<RES_AdminUsers | undefined>
  >;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setUserToEdit: React.Dispatch<
    React.SetStateAction<Partial<User> | undefined | null>
  >;
  setEditError: React.Dispatch<React.SetStateAction<string>>;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

export type UserManagementStateContext = readonly [
  UserManagementState,
  UserManagementActions
];
