import React from "react";
import { ContentBase, SettingsError } from "../styled";
import {
  CopilotSettingsStoreProvider,
  useCopilotSettingsStore,
} from "../../../../context/stores/copilot-settings/copilot-settings-store";
import { BasicSettings } from "./BasicSettings";
import { AdvanceSettings } from "./AdvanceSettings";
import { Loader } from "../../../../components/Loader/Loader";

export const CopilotSettingsContent = () => {
  const { store } = useCopilotSettingsStore();
  const [data] = store;

  return (
    <>
        {data?.isLoading || data?.isBasicSettingsLoading ?<Loader/> : null}
      <SettingsError isVisible={!!data.error}>{data.error}</SettingsError>
      <ContentBase>
        <BasicSettings />
        {data?.settings?.value?.enabled ? <AdvanceSettings /> : null}
      </ContentBase>
    </>
  );
};

export const CopilotSettings = () => {
  return (
    <CopilotSettingsStoreProvider>
      <CopilotSettingsContent />
    </CopilotSettingsStoreProvider>
  );
};
