import { useCallback, useMemo } from "react";
import { API_Data, API } from "../../../../main-service/src/types";
import { useAuthRequestSender } from "../../hooks/http";
import { useSendRequest } from "../../hooks/http/use-send-request";

const baseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/auth`;

export type LoginRes = API[API_Data.LOGIN_RESPONSE];
export const useAuth = () => {
  const sendRequest = useAuthRequestSender();
  const noAuthRequest = useSendRequest();

  const logout = useCallback(() => {
    return sendRequest(`${baseUrl}/logout`);
  }, [sendRequest]);

  const login = useCallback(
    (idToken: string) => {
      return noAuthRequest<LoginRes>(`${baseUrl}/login`, {
        method: "POST",
        body: {
          idToken,
        },
      });
    },
    [noAuthRequest]
  );

  const checkAuth = useCallback(
    (token: string) => {
      return noAuthRequest(`${baseUrl}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    [sendRequest]
  );

  return useMemo(
    () => ({
      logout,
      login,
      checkAuth,
    }),
    [logout, login, checkAuth]
  );
};
