import React, { FC, useState } from "react";
import ChevronLeftIcon from "../../assets/chevron-left.svg";
import ChevronRightIcon from "../../assets/chevron-right.svg";
import CalendarIcon from "../../assets/calendar.svg";

import { IconContainer } from "../IconContainer";
import { BasicCalendarBase, CalendarBase, DateDisplayWrapper } from "./styled";
import { Input } from "../Form/FormControls/Input/index";

// storing full name of all months in array
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function shouldDisable(
  currDate: number,
  currMonth: number,
  currYear: number,
  disablePast: boolean
) {
  return (
    currDate < new Date().getDate() &&
    new Date().getMonth() <= currMonth &&
    new Date().getFullYear() < currYear &&
    disablePast
  );
}
type BasicCalendarProps = {
  onDateSelect: (date: Date | null) => void;
  noDefaultToday?: boolean;
  disablePast?: boolean;
};
const defaultDate = new Date();

const BasicCalendar: FC<BasicCalendarProps> = ({
  onDateSelect,
  noDefaultToday,
  disablePast,
}) => {
  const [currYear, setCurrYear] = useState(defaultDate.getFullYear());
  const [currMonth, setCurrMonth] = useState(defaultDate.getMonth());
  const [currDate, setCurrDate] = useState(
    !noDefaultToday ? defaultDate.getDate() : null
  );

  const firstDayOfMonth = new Date(currYear, currMonth, 1).getDay();
  const lastDateOfMonth = new Date(currYear, currMonth + 1, 0).getDate();
  const lastDayOfMonth = new Date(
    currYear,
    currMonth,
    lastDateOfMonth
  ).getDay();
  const lastDateOfLastMonth = new Date(currYear, currMonth, 0).getDate();

  const handleDateSelect = (i: number) => {
    const selectedDate = new Date(currYear, currMonth, i);
    selectedDate.setDate(i);

    setCurrDate(i);

    onDateSelect(selectedDate);
  };

  const fixDate = (m: number) => {
    if (m < 0) {
      setCurrYear(currYear - 1);
      setCurrMonth(11);
      return;
    }

    if (m > 11) {
      setCurrYear(currYear + 1);
      setCurrMonth(0);
    }
  };

  return (
    <BasicCalendarBase>
      <header>
        <div className="icons">
          <IconContainer
            onClick={() => {
              const navigate = ()  => {
                const m = currMonth - 1;
                setCurrMonth(m);
                fixDate(m);
                setCurrDate(null);
              }

              if(disablePast) {
                if (currMonth > new Date().getMonth()|| currYear > new Date().getFullYear()) {
                  navigate();
                }
                return
              }

              navigate()
       
            }}
          >
            <ChevronLeftIcon />
          </IconContainer>
          <p className="current-date">
            <span>{months[currMonth]} </span> <span> {currYear}</span>
          </p>
          <IconContainer
            onClick={() => {
              const m = currMonth + 1;
              setCurrMonth(m);
              fixDate(m);
              setCurrDate(null);
            }}
          >
            <ChevronRightIcon />
          </IconContainer>
        </div>
      </header>
      <div className="calendar">
        <ul className="weeks">
          <li>Sun</li>
          <li>Mon</li>
          <li>Tue</li>
          <li>Wed</li>
          <li>Thu</li>
          <li>Fri</li>
          <li>Sat</li>
        </ul>
        <CalendarDays
          disablePast={disablePast}
          firstDayOfMonth={firstDayOfMonth}
          lastDayOfMonth={lastDayOfMonth}
          lastDateOfLastMonth={lastDateOfLastMonth}
          lastDateOfMonth={lastDateOfMonth}
          selectedDate={currDate}
          currMonth={currMonth}
          currYear={currYear}
          onDaySelect={handleDateSelect}
        />
      </div>
    </BasicCalendarBase>
  );
};

type CalendarDaysProps = {
  onDaySelect: (i: number) => void;
  firstDayOfMonth: number;
  lastDateOfLastMonth: number;
  lastDateOfMonth: number;
  lastDayOfMonth: number;
  selectedDate?: number | null;
  disablePast?: boolean;
  currMonth: number;
  currYear: number;
};

const CalendarDays: React.FC<CalendarDaysProps> = ({
  firstDayOfMonth,
  lastDateOfLastMonth,
  lastDateOfMonth,
  lastDayOfMonth,
  selectedDate,
  disablePast,
  currMonth,
  currYear,
  onDaySelect,
}) => {
  const daysNodes: Array<React.FC> = [];

  for (let i = firstDayOfMonth; i > 0; i--) {
    // creating li of previous month last days
    daysNodes.push(() => (
      <li className="disabled">{lastDateOfLastMonth - i + 1}</li>
    ));
  }

  for (let i = 1; i <= lastDateOfMonth; i++) {
    const active = i === selectedDate ? "active" : "";

    let disabled = shouldDisable(i, currMonth, currYear, disablePast || false) ? "disabled": "";

    daysNodes.push(() => (
      <li className={`${active} ${disabled}`} onClick={() => onDaySelect(i)}>
        {i}
      </li>
    ));
  }

  for (let i = lastDayOfMonth; i < 6; i++) {
    // creating li of next month first days
    daysNodes.push(() => (
      <li className="disabled">{i - lastDayOfMonth + 1}</li>
    ));
  }

  return (
    <ul className="days">
      {daysNodes.map((Li, i) => (
        <Li key={i} />
      ))}
    </ul>
  );
};

type CalendarProps = BasicCalendarProps & { displaySelected?: boolean };

export const Calendar: React.FC<CalendarProps> = (props) => {
  const [date, setDate] = useState<Date | null>();

  const onSelectDate = (date: Date | null) => {
    setDate(date);
    props.onDateSelect(date);
  };

  if (!props.displaySelected) {
    return <BasicCalendar {...props} onDateSelect={onSelectDate} />;
  }

  return (
    <CalendarBase>
      <DateDisplayWrapper>
        <Input
          disabled
          placeholder="Next campaign send out date"
          icon={<CalendarIcon />}
          value={date?.toDateString?.() || ""}
        />
      </DateDisplayWrapper>
      <BasicCalendar {...props} onDateSelect={onSelectDate} />
    </CalendarBase>
  );
};
