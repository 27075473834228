import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
} from "react";
import { API, API_Data } from "../../../main-service/src/types/index";
import { useState } from "react";
import { feedFilters } from "../../../main-service/src/constants/index";
import { http } from "../modules/http";
import { APIFilter } from "../../../main-service/src/types/shared/api";
import { urlSearchParamsFromObj } from "../modules/url";
import { useAuthStore } from "./stores/auth/auth-store";

export type FeedResponse = API[API_Data.FEED_RESPONSE];
export type FeedFilterProps = APIFilter<keyof typeof feedFilters>;

type FetchAction = (
  limit?: number,
  offset?: number,
  searchTerm?: string,
  filters?: FeedFilterProps
) => Promise<unknown>;

type FeedActions = {
  fetch: FetchAction;
  fetchClients: () => Promise<void>;
};

type TFeedContext = {
  feed?: FeedResponse | null;
  clients: { id: string; name: string }[];
  isLoading: boolean;
  feedActions: FeedActions;
};

export const FeedContext = createContext<TFeedContext>({
  feed: null,
  isLoading: true,
  clients: [],
  feedActions: {
    fetch: (_: any, __: any, ___: any, _filters?: FeedFilterProps) =>
      Promise.resolve(),
    fetchClients: () => Promise.resolve(),
  },
});

export const FeedContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const feedContext = useFeed();

  return (
    <FeedContext.Provider value={feedContext}>{children}</FeedContext.Provider>
  );
};

const useFeed = (): TFeedContext => {
  const { store } = useAuthStore();
  const [state] = store;
  const [feed, setFeed] = useState<FeedResponse | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState<{ id: string; name: string }[]>([]);

  const fetchFeed = useCallback(
    async (
      limit = 8,
      offset = 0,
      searchTerm = "",
      filters?: FeedFilterProps
    ) => {
      setIsLoading(true);

      const q = filters
        ? urlSearchParamsFromObj(filters)
        : new URLSearchParams();

      const data = await http.fetch<FeedResponse>(
        `${
          process.env.CLUSTERING_BACKEND_HOST
        }/feeds?limit=${limit}&offset=${offset}&search=${encodeURIComponent(
          searchTerm
        )}&${q}`,
        {
          headers: {
            Authorization: state.bearerToken,
            "Content-Type": "application/json",
          },
        }
      );
      setFeed(data);
      setIsLoading(false);
    },
    [state.bearerToken]
  );

  const fetchClients = useCallback(async () => {
    const wpcomps = await http.fetch<{ id: string; name: string }[]>(
      `${process.env.CLUSTERING_BACKEND_HOST}/clustering/wpcompanies`,
      {
        headers: { Authorization: state.bearerToken },
      }
    );

    setClients(wpcomps!);
  }, [state.bearerToken]);

  const feedActions = useMemo(
    () => ({
      fetch: fetchFeed,
      fetchClients,
    }),
    []
  );

  return useMemo(
    () => ({
      feed,
      clients,
      feedActions,
      isLoading,
    }),
    [feedActions, clients, feed, isLoading]
  );
};
