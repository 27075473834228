import React, { FC, useEffect, useState } from "react";

import SearchIcon from "../../../../assets/search-icon.svg";
import { useDebounce } from "../../../../hooks/debounce";

import { Input } from "..";

export type SearchProps = {
  debounceTime?: number;
  onChange: (value: string) => void;
};

export const Search: FC<SearchProps> = ({ debounceTime, onChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debounced = useDebounce(searchTerm, debounceTime || 0);

  useEffect(() => {
    onChange(debounced);
  }, [debounced]);

  return (
    <Input
      type="search"
      placeholder="Search"
      onChange={(e) => {
        setSearchTerm(e.target.value);
      }}
      icon={<SearchIcon />}
    />
  );
};
