import React, { useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../context/global";
import { useAuthStore } from "../../context/stores/auth/auth-store";

export const Logout = () => {
  const { store, effects } = useAuthStore();
  const [state] = store;

  const {
    globalActions: { unassignReply },
  } = useContext(GlobalContext);
  const isCalledOnce = useRef(false);

  useEffect(() => {
    if (state.isAuthenticated && !isCalledOnce.current) {
      isCalledOnce.current = true;
      unassignReply().finally(() => {
        effects.logout();
      });
    }
  }, [state.isAuthenticated, unassignReply, effects.logout, isCalledOnce]);

  return <></>;
};
