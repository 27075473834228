import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { Tab, TabNames } from "./types";
import { UserManagement } from "./content/UserManagement";
import { WoodpeckerIntegration } from "./content/WoodpeckerIntegration";
import { CopilotSettings } from "./content";

import { useAuthStore } from "../../context/stores/auth/auth-store";

export const TabContentSelector: FC<{ tab: Tab }> = ({ tab }) => {
  const { store } = useAuthStore()
  const [_, reducers] = store; 
  const tabComponents = new Map([
    [TabNames.UserManagement, UserManagement],
    [TabNames.WoodpeckerIntegration, WoodpeckerIntegration],
    [TabNames.CopilotSettings, CopilotSettings],
  ]);

  const hasAuthorization = tab.roles.find((role) => reducers.isAuthorized(role));

  if (!hasAuthorization) {
    return <Navigate to="/unauthorized" />;
  }

  const TabComponent = tabComponents.get(tab.name);

  return TabComponent ? <TabComponent /> : <h1>Tab not found</h1>;
};
