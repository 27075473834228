import { useCallback } from "react";
import { ClusteringMetricsAPI } from "../api";

export const useClusteringErrorsCount = () => {
  const metricsAPI = 
    ClusteringMetricsAPI.useMetrics();

  const getClusteringErrorsCount = useCallback(async () => {
    const res = await metricsAPI.getClusteringErrorsCount()

    return res?.data?.count;
  }, [metricsAPI.getClusteringErrorsCount]);

  return getClusteringErrorsCount;
};
