import React, { FC, useCallback } from "react";
import {
  IconBase,
  OptionGroup,
  OptionGroupHeaderBase,
  OptionsBase,
} from "./styled";

import { Options } from "./Options";
import { OptionSuggestion } from "./Option/types";
import {
  CategorySelection,
  CategorySuggestion,
  TCategorizationOptions,
} from "./types";

export type CategorizationOptionsProps = {
  onSelect: (arg: CategorySelection) => void;
  categoryOptions: Array<TCategorizationOptions>;
  suggestion?: CategorySuggestion | null;
  selection: CategorySelection | null;
};

export const CategorizationOptions: FC<CategorizationOptionsProps> = ({
  categoryOptions,
  suggestion,
  onSelect,
  selection,
}) => {
  const handleSelect = (
    category: string,
    mainOption: string,
    subOption?: string
  ) => {
    onSelect &&
      onSelect({
        category,
        selectedOption: mainOption,
        selectedSubOption: subOption,
      });
  };

  const getOptionSuggestion = useCallback(
    (optionCategory: string) => {
      if (optionCategory === suggestion?.category) {
        const { category, ...rest } = suggestion;

        return rest;
      }

      return null;
    },
    [suggestion]
  );

  return (
    <OptionsBase>
      {" "}
      {categoryOptions.map((option) => {
        return (
          <CategorizationOption
            selectedMainOption={selection?.selectedOption}
            selectedSubOption={selection?.selectedSubOption}
            key={option.name}
            option={option}
            suggestion={getOptionSuggestion(option.name)}
            onSelect={(mainOption, subOption) => {
              handleSelect(option.name, mainOption, subOption);
            }}
          />
        );
      })}
    </OptionsBase>
  );
};

const CategorizationOption: FC<{
  option: TCategorizationOptions;
  selectedMainOption?: string;
  selectedSubOption?: string;
  suggestion: OptionSuggestion | null;
  onSelect: (mainOption: string, subOption?: string) => void;
}> = ({
  option,
  selectedMainOption,
  selectedSubOption,
  onSelect,
  suggestion,
}) => {
  return (
    <OptionGroup>
      <CategorizationOptionsHeader
        icon={option.icon}
        title={option.name}
        color={option.color}
      />{" "}
      {
        <Options
          selectedOption={selectedMainOption}
          selectedSubOption={selectedSubOption}
          color={option.color}
          options={option.options}
          suggestion={suggestion}
          onSelect={onSelect}
        />
      }
    </OptionGroup>
  );
};

const CategorizationOptionsHeader = (props: {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  color?: string;
}) => {
  const { icon, title, color } = props;
  const Icon = icon;
  return (
    <OptionGroupHeaderBase>
      {Icon && <IconBase color={color}>{<Icon />}</IconBase>}
      <h4>{title}</h4>
    </OptionGroupHeaderBase>
  );
};
