import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ClusteringPage } from "../../types";
import {
  CloseDialogKeys,
  DialogPopup,
} from "../../../../components/DialogPopup/types";
import { useReclusteringPageDialogs } from "./use-reclustering-page-dialogs";
import { ClusteringTasks } from "../../../../../../main-service/src/types/shared/shared";
import { Store } from "../../../../context/stores/clustering";

export const useRecluteringPage = (): ClusteringPage => {
  const params = useParams();
  const navigate = useNavigate()

  const { store, effects } = Store.useClusteringStore();

  const [state] = store;

  const [showReclusteringDoneDialog, setShowReclusteringDoneDialog] =
    useState(false);
  const [pageExitPopupVisible, setPageExitPopupVisible] = useState(false);
  useState(false);

  const isViewDirty = !!(state.cluster || state.nextScheduleDate);

  const recluster = useCallback(async () => {
    if (!(state.cluster && params.reply_id)) {
      return;
    }
    await effects.complete(ClusteringTasks.RECLUSTERING, {
      ...state.cluster,
      replyId: params.reply_id,
    });

    setShowReclusteringDoneDialog(true);
  }, [params.reply_id, state.cluster]);

  const complete = useCallback(async () => {
    await recluster();
  }, [recluster]);

  const dialogs: DialogPopup<CloseDialogKeys>[] = useReclusteringPageDialogs({
    isErrorPopupVisible: !!state.metadata.error,
    setPageExitPopupVisible,
    pageExitPopupVisible,
    showReclusteringDoneDialog,
    setShowReclusteringDoneDialog,
  });

  const onViewExit = useCallback(async () => {
    if (isViewDirty) {
      setPageExitPopupVisible(true);
    } else {
      navigate('/landing/feed')
    }
  }, [isViewDirty]);

  const onViewEnter = useCallback(async () => {
    if (params.reply_id) {
      effects.assign(ClusteringTasks.RECLUSTERING, params.reply_id);
    }
  }, [params.reply_id, effects.assign]);

  const onActionDone = useCallback(async () => {
    setShowReclusteringDoneDialog(true);
  }, []);

  const res: ClusteringPage = useMemo(
    () => ({
      showHintDialog: false,
      task: ClusteringTasks.RECLUSTERING,
      isLoading: state.metadata.isLoading,
      dialogs,
      complete,
      onActionDone,
      onViewExit,
      onViewEnter,
    }),
    [state.metadata.isLoading, complete, dialogs, onActionDone, onViewEnter, onViewExit]
  );
  return res;
};
