import React, { PropsWithChildren } from "react";
import { ContentBase, ListView } from "./styled";

export const ConfigurationList: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <ContentBase>
      {Array.isArray(children) ? (
        children.map((child, index) => (
          <ListView key={index}>
            {child}

            {index < children.length - 1 && child ? (
              <hr style={{ marginBottom: "1rem" }} />
            ) : null}
          </ListView>
        ))
      ) : (
        <ListView>{children}</ListView>
      )}
    </ContentBase>
  );
};
