import React, { useContext } from "react";

import {
  UserManagementStateContext,
  UserManagementStateProvider,
} from "./user-management-state";
import {
  UserManagementEffectsContext,
  UserManagementEffectsProvider,
} from "./user-management-effects";

export const useUserManagementStore = () => {
  const store = useContext(UserManagementStateContext);
  const effects = useContext(UserManagementEffectsContext);

  return {
    store,
    effects,
  };
};

export const UserManagementStoreProvider = ({ children }) => {
  return (
    <UserManagementStateProvider>
      <UserManagementEffectsProvider>{children}</UserManagementEffectsProvider>
    </UserManagementStateProvider>
  );
};
