import React, { FC, useCallback, useContext, useMemo } from "react";
import { MessagePane } from "../MessagePane";
import { ContentCard } from "../ContentCard";
import { Avatar } from "../Avatar";
import { State } from "../../context/stores/clustering";

type ReplyAction = {
  label: string;
  action: () => Promise<void>;
};

export type ReplyPaneProps = {
  onSetAutoReplyToNormalReply: (replyId?: string) => Promise<void>;
  onSetNormalReplyToAutoReply: (replyId?: string) => Promise<void>;
  onSetReplyToBounced: (replyId?: string) => Promise<void>;
  toggleTranslation: () => void;
  toggleOriginalMessage: () => void;
};

export const ReplyPane: FC<ReplyPaneProps> = ({
  onSetAutoReplyToNormalReply,
  onSetNormalReplyToAutoReply,
  onSetReplyToBounced,
  toggleTranslation,
  toggleOriginalMessage,
}) => {
  const [
    { metadata, reply, showTranslated, showOriginal, isAutoReply },
    { getReplyContent, getSenderInitials },
  ] = useContext(State.ReplyClusterStateContext);

  const getReplyPaneActions = useCallback((): ReplyAction[] => {
    return [
      {
        label: "Mark as Bounced",
        action: () => onSetReplyToBounced(reply?.id),
      },
      {
        label: isAutoReply ? "Mark as Replied" : "Mark as Auto-Replied",
        action: isAutoReply
          ? () => onSetAutoReplyToNormalReply(reply?.id)
          : () => onSetNormalReplyToAutoReply(reply?.id),
      },
    ];
  }, [
    isAutoReply,
    reply,
    onSetAutoReplyToNormalReply,
    onSetAutoReplyToNormalReply,
    onSetReplyToBounced,
  ]);

  const renderSenderIcon = useCallback(() => {
    return <Avatar initials={getSenderInitials()} />;
  }, [getSenderInitials]);

  const renderReplyBody = useCallback(
    () => (
      <MessagePane
        showOriginal={!!showOriginal}
        message={getReplyContent()}
        showTranslated={!!showTranslated}
        toggleOriginalMessage={toggleOriginalMessage}
        toggleTranslation={toggleTranslation}
      />
    ),
    [showOriginal, showTranslated, getReplyContent]
  );

  const replyPaneHeader = useMemo(() => {
    const getSubtext = () => {
      if (!reply?.replied_at) {
        return "";
      }

      const date = new Date(reply?.replied_at);
      date.setHours(date.getHours() - 2);

      return Intl.DateTimeFormat("en-GB", {
        dateStyle: "medium",
        timeStyle: "short",
      }).format(date);
    };

    return {
      icon: renderSenderIcon,
      title: reply?.subject || "",
      subText: getSubtext(),
    };
  }, [renderSenderIcon, reply?.replied_at, reply?.subject]);

  const getCardContent = useCallback(() => {
    return {
      actions: getReplyPaneActions(),
      header: replyPaneHeader,
      body: renderReplyBody,
    };
  }, [renderReplyBody, getReplyPaneActions, replyPaneHeader]);

  return (
    <ContentCard isLoading={!!metadata.isLoading} data={getCardContent()} />
  );
};
