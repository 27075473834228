import React from "react";
import day from "dayjs";
import { DateTimeFromNow } from "./DateTimeFromNow";
import { LocaleDateTime } from "./LocaleDateTime";

export const SmartDateTime = ({
  timestamp,
  dateClassName,
  timeClassName,
}: {
  timestamp: string;
  dateClassName?: string;
  timeClassName?: string;
}) => {
  const anHour = 3.6e6;
  const oneDay = anHour * 24;
  const timeDiff = Math.abs(day(timestamp).diff());

  if (timeDiff <= oneDay) {
    return <DateTimeFromNow timestamp={timestamp} />;
  }

  return (
    <LocaleDateTime
      timestamp={timestamp}
      dateClassName={dateClassName}
      timeClassName={timeClassName}
    />
  );
};
