import React, { FC, useEffect, useRef, useState } from "react";
import { ContentCard } from "../../../../components/ContentCard";
import { throttle } from "../../../../hooks/util";
import {
  Button,
  ContentBase,
  Group,
  ListView,
  MetricBase,
  Metrics,
} from "../styled";
import { useAuthStore } from "../../../../context/stores/auth/auth-store";

enum DataCodes {
  FETCH_IN_PROGRESS = "FETCH_IN_PROGRESS",
  INVALID = "INVALID",
  READY_FOR_FETCH = "READY_FOR_FETCH",
  READY_FOR_SYNC = "READY_FOR_SYNC",
  SYNCING = "SYNCING",
  SYNC_COMPLETE = "SYNC_COMPLETE",
}

type SyncDataPayload = {
  fetched?: number;
  code: DataCodes;
  error?: any;
  updateCount?: number;
  inQueue?: number;
  totalReqs?: number;
  metadata?: {
    processCompleted?: string;
  };
};

const wpFetch = async (endpoint, method, authHeader) => {
  return await fetch(
    `${process.env.CLUSTERING_BACKEND_HOST}/admin/woodpecker/sync${endpoint}`,
    {
      headers: { Authorization: authHeader },
      method,
    }
  );
};

const Metric = (props: {
  name: string;
  className?: string;
  value: string | number;
}) => {
  return (
    <MetricBase className={props.className}>
      <label>{props.name}</label>
      <span>{props.value}</span>
    </MetricBase>
  );
};

const InfoView = (props: { code: string; data: any; error: any }) => {
  const keys = Object.keys(props.data);
  return (
    <Metrics>
      <Metric name="status" value={props.code}></Metric>
      {keys.map((k) =>
        !!props.data[k] ? <Metric name={k} value={props.data[k]} /> : null
      )}
      {props.error ? (
        <Metric
          className="desc error"
          name="error"
          value={`${props.error.message}`}
        />
      ) : null}
    </Metrics>
  );
};

const Sync = (props: { endpoint: string }) => {
  const [metadataStatus, setMetadataStatus] = useState("");
  const { store } = useAuthStore()
  const [state, reducer] = store
  const [code, setCode] = useState<string | undefined>(
    DataCodes.READY_FOR_FETCH
  );
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const response = useRef<SyncDataPayload | undefined>();

  const btnFetch = code === "READY_FOR_FETCH";
  const btnClear =
    code === "READY_FOR_SYNC" || code === "INVALID" || code === "SYNC_COMPLETE";
  const btnApply = code === "READY_FOR_SYNC";

  const throttledFetch = throttle(async () => {
    const data = await wpFetch(props.endpoint, "GET", state.bearerToken);
    response.current = await data.json();
    setCode(response.current?.status);
    setData(response.current?.data);
    setError(response.current?.error);
  }, 1000);

  const throttledApply = throttle(async () => {
    const data = await wpFetch(props.endpoint, "POST", state.bearerToken);
    response.current = await data.json();
    setCode(response.current?.status);
    setData(response.current?.data);
    setError(response.current?.error);
  }, 1000);

  const startFetch = async () => {
    const r = response.current;
    if (
      !r ||
      (r.status !== DataCodes.READY_FOR_SYNC &&
        r.status !== DataCodes.INVALID &&
        r.status !== DataCodes.SYNC_COMPLETE)
    ) {
      await throttledFetch();
      setTimeout(async () => await startFetch(), 1000);
    }
  };

  const clear = async () => {
    const data = await wpFetch(props.endpoint, "DELETE", state.bearerToken);
    response.current = await data.json();
    setCode(response.current?.status);
  };

  const apply = async () => {
    const r = response.current;
    if (
      r?.status === DataCodes.READY_FOR_SYNC ||
      r?.status === DataCodes.SYNCING
    ) {
      await throttledApply();
      setTimeout(async () => await apply(), 1000);
    }
  };

  const get_metadata = async () => {
    const data = await fetch(
      `${process.env.CLUSTERING_BACKEND_HOST}/admin/woodpecker/sync/replies/metadata`,
      {
        headers: {
          Authorization: state.bearerToken,
        },
        method: "GET",
      }
    );
    const diagnostics = await fetch(
      `${process.env.CLUSTERING_BACKEND_HOST}/admin/diagnostics`,
      {
        headers: { Authorization: state.bearerToken },
      }
    );
    console.log(`Diagnostics: `, await diagnostics.json());
    console.log(`Metadata: `, await data.json());
  };

  const post_metadata = async (status) => {
    await fetch(
      `${process.env.CLUSTERING_BACKEND_HOST}/admin/woodpecker/sync${props.endpoint}/metadata`,
      {
        headers: {
          Authorization: state.bearerToken,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          status,
        }),
      }
    );
  };

  useEffect(() => {
    console.log(data, error);
  }, [data, error]);

  useEffect(() => {
    return () => {
      if (response.current) response.current.status = DataCodes.INVALID;
    };
  }, []);

  return (
    <>
      <Group>
        <Button disabled={!btnFetch} onClick={startFetch}>
          Start Fetch Job
        </Button>
        <Button disabled={!btnClear} onClick={clear}>
          Clear Current Job
        </Button>
        <Button disabled={!btnApply} onClick={apply}>
          Resolve Data Difference
        </Button>
        <Button onClick={get_metadata}>Get Metadata</Button>
        <select onChange={(e) => setMetadataStatus(e.target.value)}>
          <option value="READY_FOR_SYNC">READY_FOR_SYNC</option>
          <option value="READY_FOR_FETCH">READY_FOR_FETCH</option>
          <option value="INVALID">INVALID</option>
        </select>
        <Button onClick={() => post_metadata(metadataStatus)}>
          Change Status in Server
        </Button>
      </Group>
      {code && (data || error) ? (
        <InfoView code={code} data={data} error={error} />
      ) : null}
    </>
  );
};

const CompanySync = () => {
  return (
    <ContentBase>
      <p>
        Get <strong>Company Data</strong> from Woodpecker and Synchronize with
        Clustering Database
      </p>
      <Sync endpoint="/companies" />
    </ContentBase>
  );
};

const ProspectSync = () => {
  return (
    <ContentBase>
      <p>
        Get <strong>Prospect Data</strong> from Woodpecker and Synchronize with
        Clustering Database.
        <br />
        Make sure that you have latest company data for best results
      </p>
      <Sync endpoint="/prospects" />
    </ContentBase>
  );
};

const ReplySync = () => {
  return (
    <ContentBase>
      <p>
        Get <strong>Reply Data</strong> from Woodpecker and Synchronize with
        Clustering Database.
        <br />
        Make sure that you have latest prospect data for best results
      </p>
      <Sync endpoint="/replies" />
    </ContentBase>
  );
};

const ReplyClusterSync = () => {
  const { store } = useAuthStore()
  const [state] = store;
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const response = useRef<any>();

  const [code, setCode] = useState<string | null>(DataCodes.READY_FOR_FETCH);
  const [replies, setReplies] = useState<string | null>(null);
  const [updateCount, setUpdateCount] = useState<number | null>(null);
  const [completed, setCompleted] = useState<string | null>(null);
  const [errors, setErrors] = useState<number | null>(null);

  const btnFetch = to && from && code === DataCodes.READY_FOR_FETCH;
  const btnClear =
    code === DataCodes.READY_FOR_SYNC || code === DataCodes.SYNC_COMPLETE;
  const btnApply = code == DataCodes.READY_FOR_SYNC;

  const throttledFetchDirty = throttle(async () => {
    const result = await fetch(
      `${process.env.CLUSTERING_BACKEND_HOST}/admin/woodpecker/replies/dirty?from=${from}&to=${to}`,
      {
        headers: { Authorization: state.bearerToken },
      }
    );
    response.current = await result.json();
    setCode(response.current.code);
    response.current.data.replies
      ? setReplies(
          `${response.current.data.replies} out of ${response.current.data.prospects_and_campaigns}`
        )
      : setReplies(null);
    setUpdateCount(response.current.data.updateCount);
  }, 1000);

  const fetchDirty = async () => {
    const r = response.current;
    if (
      !r ||
      r.code === DataCodes.READY_FOR_FETCH ||
      r.code === DataCodes.FETCH_IN_PROGRESS
    ) {
      await throttledFetchDirty();
      setTimeout(async () => await fetchDirty(), 1000);
    }
  };

  const clearDirty = async () => {
    const result = await fetch(
      `${process.env.CLUSTERING_BACKEND_HOST}/admin/woodpecker/replies/dirty`,
      {
        headers: { Authorization: state.bearerToken },
        method: "DELETE",
      }
    );
    response.current = await result.json();
    setCode(response.current.code);
  };

  const throttledApply = throttle(async () => {
    const result = await fetch(
      `${process.env.CLUSTERING_BACKEND_HOST}/admin/woodpecker/replies/dirty`,
      {
        headers: { Authorization: state.bearerToken },
        method: "PATCH",
      }
    );
    response.current = await result.json();
    setCode(response.current.code);
    if (response.current.data.errors)
      setErrors(response.current.data.errors.length);
    if (response.current.data.metadata)
      setCompleted(response.current.data.metadata.completed);
  }, 1000);

  const apply = async () => {
    const r = response.current;
    if (
      !r ||
      r.code === DataCodes.READY_FOR_SYNC ||
      r.code === DataCodes.SYNCING
    ) {
      await throttledApply();
      setTimeout(async () => await apply(), 1000);
    }
  };

  useEffect(() => {
    return () => {
      if (response.current) response.current.code = DataCodes.INVALID;
    };
  }, []);

  return (
    <ContentBase>
      <p>
        Once you have enough data on Replies from Woodpecker, it's time for us
        to clean it.
      </p>
      <Group>
        <input
          type="date"
          value={from}
          onInput={(n) => setFrom(n.target.value)}
        />
        <input type="date" value={to} onInput={(n) => setTo(n.target.value)} />
      </Group>
      <Group>
        <Button disabled={!btnFetch} onClick={fetchDirty}>
          Start Fetch Job
        </Button>
        <Button disabled={!btnClear} onClick={clearDirty}>
          Clear Current Job
        </Button>
        <Button disabled={!btnApply} onClick={apply}>
          Start Cleanup Process
        </Button>
      </Group>
      <Metrics>
        {code && <Metric name="status" value={code} />}
        {replies && (
          <Metric name="replies per prospects-campaigns" value={replies} />
        )}
        {updateCount && <Metric name="to be updated" value={updateCount} />}
        {errors && (
          <Metric className="desc error" name="errors" value={errors} />
        )}
        {completed && <Metric name="process completed" value={completed} />}
      </Metrics>
    </ContentBase>
  );
};

export const WoodpeckerIntegration: FC = () => {
  return (
    <>
      <ListView>
        <ContentCard
          isLoading={false}
          data={{
            header: { title: "Sync Companies (Clients) from Woodpecker" },
            body: () => <CompanySync />,
          }}
        />
        <ContentCard
          isLoading={false}
          data={{
            header: { title: "Sync Prospects from Woodpecker" },
            body: () => <ProspectSync />,
          }}
        />
        <ContentCard
          isLoading={false}
          data={{
            header: { title: "Sync Replies from Woodpecker" },
            body: () => <ReplySync />,
          }}
        />
        <ContentCard
          isLoading={false}
          data={{
            header: { title: "Clean and Push Replies for Clustering" },
            body: () => <ReplyClusterSync />,
          }}
        />
      </ListView>
    </>
  );
};
