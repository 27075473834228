import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { ClusteringOptionsWrapper } from "../styled";
import { clusteringCategories } from "../constants";
import { Calendar } from "../../../components/Calendar";
import { ClusterData } from "../../../../../main-service/src/types/shared/api";
import { ClusteringTasks } from "../../../../../main-service/src/types/shared/shared";
import { CategorizationOptions } from "../../../components/Options";
import { ClusterInfo, ErrorCategories } from "../../../../../main-service/src/types/shared/clustering";
import { OptionSetters } from "./types";
import {
  _getOptionSuggestion,
  categoryToClusterOption,
  clusterOptionToCategory,
} from "./helpers";
import { CategorySelection } from "../../../components/Options/types";

export type ClusteringOptionsProps = {
  task: ClusteringTasks;
  optionSetters: OptionSetters;
  clusteringSuggestion?: ClusterInfo | null;
  cluster?: Omit<ClusterData, "replyId"> | null;
  setIsViewDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  errorCategory?: ErrorCategories;
};

export const ClusteringOptions: React.FC<ClusteringOptionsProps> = ({
  optionSetters,
  task,
  clusteringSuggestion,
  cluster,
  errorCategory,
  setIsViewDirty,
}) => {
  const [selection, setSelection] = useState<CategorySelection | null>(null);

  const getSuggestion = useCallback(() => {
    return _getOptionSuggestion(clusteringSuggestion);
  }, [clusteringSuggestion]);

  const setSelectedClusterOption = useCallback(
    (selection: CategorySelection) => {
      optionSetters.setCluster(categoryToClusterOption(selection));
    },
    [optionSetters.setCluster]
  );

  useEffect(() => {
    if (cluster) {
      setSelection(clusterOptionToCategory(cluster));
      setIsViewDirty && setIsViewDirty(true);
    } else {
      setSelection(null);
      setIsViewDirty && setIsViewDirty(false);
    }
  }, [cluster]);

  const handleDateSelected = (
    selection: React.SetStateAction<Date | null | undefined>
  ) => {
    optionSetters.setNextScheduleDate(selection);
    setIsViewDirty && setIsViewDirty(true);
  };

  let heading = "";
  let option: ReactNode;

  switch (task) {
    case ClusteringTasks.CLUSTERING:
    case ClusteringTasks.RECLUSTERING:
      heading = "Clustering Categories";

      option = (
        <CategorizationOptions
          categoryOptions={clusteringCategories}
          onSelect={setSelectedClusterOption}
          selection={selection}
        />
      );
      break;
    case ClusteringTasks.ERROR_RESOLUTION:
      heading = `FIX ${errorCategory}`;
      option = (
        <CategorizationOptions
          categoryOptions={clusteringCategories}
          onSelect={setSelectedClusterOption}
          selection={selection}
          suggestion={getSuggestion()}
        />
      );
      break;
    case ClusteringTasks.RESCHEDULING:
      heading = "Reschedule";

      option = (
        <Calendar
          onDateSelect={handleDateSelected}
          displaySelected
          noDefaultToday
          disablePast
        />
      );
      break;
    default:
      heading = "Clustering Categories";
      option = (
        <CategorizationOptions
          categoryOptions={clusteringCategories}
          onSelect={setSelectedClusterOption}
          selection={selection}
        />
      );
  }

  return (
    <ClusteringOptionsWrapper>
      <h3>{heading}</h3>

      {option}
    </ClusteringOptionsWrapper>
  );
};
