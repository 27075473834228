import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DialogPopup } from "../../../components/DialogPopup";
import {
  CLUSTERING_TIMEOUT_IN_MS,
  INACTIVITY_WARNING_IN_MS,
} from "../../../pages/Clustering/constants";
import { CloseDialogKeys } from "../../../components/DialogPopup/types";
import { Store } from "../../../context/stores/clustering";
import { ClusteringTasks } from "../../../../../main-service/src/types/shared/shared";

export const TimeoutTracker: FC<{ task: ClusteringTasks }> = ({ task }) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const { store, effects } = Store.useClusteringStore();
  const [state] = store;
  const timer = useRef<NodeJS.Timeout | number | undefined>(0);
  const logoutTimer = useRef<NodeJS.Timeout | number | undefined>(0);
  const navigate = useNavigate();

  const onMouseClick = () => {
    if (timer.current) {
      clearTimeout(timer.current as number);
    }
    timer.current = setTimeout(() => {
      setDialogVisible(true);
    }, INACTIVITY_WARNING_IN_MS);
  };

  useEffect(() => {
    if (dialogVisible) {
      logoutTimer.current = setTimeout(() => {
        if (state?.reply?.id) {
          effects.unassign(task, state.reply?.id);
        }
        navigate("/landing");
      }, CLUSTERING_TIMEOUT_IN_MS);
    } else if (logoutTimer.current) clearTimeout(logoutTimer.current as number);
  }, [dialogVisible]);

  useEffect(() => {
    timer.current = setTimeout(() => {
      setDialogVisible(true);
    }, INACTIVITY_WARNING_IN_MS);
    window.addEventListener("click", onMouseClick);
    return () => {
      window.removeEventListener("click", onMouseClick);
    };
  }, []);

  return (
    <DialogPopup
      id="timeout-dialog"
      isVisible={dialogVisible}
      mainText="You have been Inactive for a while"
      subText="The system is going to stop clustering for you now"
      actions={[
        {
          name: "I wanna continue clustering",
          type: "primary",
          key: CloseDialogKeys.GO_BACK,
        },
      ]}
      onActionCall={() => setDialogVisible(false)}
    />
  );
};
