import React, { FC } from "react";

export const OptionToolTip: FC<{ tip: string }> = ({ tip }) => {
  return (
    <div className="tooltip">
      <span className="icon">i</span>
      <span className="tooltiptext">{tip}</span>
    </div>
  );
};
