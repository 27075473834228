import { Role } from "../../../../main-service/src/types/shared/shared";

export enum TabNames {
  UserManagement = "User Management",
  WoodpeckerIntegration = "Woodpecker Integration",
  CopilotSettings = "Copilot Settings",
}

export type Tab = {
  name: TabNames;
  roles: Role[];
};
