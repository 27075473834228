import React, { useCallback } from "react";
import { Toggle } from "../../../../../components/Toggle";
import { ToggleWrapper } from "../../styled";
import { useCopilotSettingsStore } from "../../../../../context/stores/copilot-settings/copilot-settings-store";

export const CopilotSuspiciousClusteringHintToggler = () => {
    const { store, effects } = useCopilotSettingsStore();
    const [data] = store;
  
    const handleToggleChange = useCallback(
      (enabled: boolean) => {
        effects.setHintEnabled(enabled);
      },
      [effects.setHintEnabled]
    );
  
    return (
      <ToggleWrapper>
        <Toggle
          leftLabel="Hinting Disabled"
          rightLabel="Hinting Enabled"
          defaultIsChecked={data.settings?.value?.hintEnabled}
          onChange={handleToggleChange}
          checkedColor="#002342"
          uncheckedColor="#002342e0"
        />
      </ToggleWrapper>
    );
}