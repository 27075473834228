import styled from 'styled-components';

export const LoginPageBase = styled.div`
	display: grid;
	height: 100%;
	width: 100%;
	align-content: center;
	justify-items: center;
	grid-gap: 8px;
	overflow: hidden;
	& > * {
		transition: all 1.6s;
		opacity: 0;
	}
	& > *.visible {
		opacity: 1;
	}
	& > *:last-child {
		transition-delay: 900ms;
  }
`;

export const Title = styled.h1`
	font-size: 64px;
	color: #111E1E;
`;

export const SubTitle= styled.h1`
	font-size: 24px;
	color: #697171;
	text-align: right;
	margin-bottom: 64px;
	transition-delay: 600ms;				
`;
