import styled from "styled-components";

export const ContentActionsBase = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const SecondaryButton = styled.button`
  border: none;
  text-decoration-line: underline;
  color: #7f92a3;
  background: none;
  cursor: pointer;
  font-size: 16px;
`;

export const PrePara = styled.p`
  padding: 10px 0;
  font-size: 14px;
  color: #0b0b0c;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  button {
    display: inline-flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
`;
