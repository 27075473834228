import React, { useCallback, useMemo } from "react";
import { ConfigurationList } from "../../../../../../components/ConfigurationPanel/ConfigurationList";
import { Store } from "../../../../../../context/stores/copilot-settings";
import { ContentCard } from "../../../../../../components/ContentCard";
import { TextAreaItemForm } from "../../../../../../components/ConfigurationPanel/TextAreaItemForm";
import { PromptExamples } from "./PromptExamples";

export const PromptSettingsContent = () => {
  const { store, effects } = Store.useCopilotSettingsStore();
  const [state] = store;

  const prompt = useMemo(
    () => state.settings?.value?.prompt,
    [state.settings?.value?.prompt]
  );

  const updateGeneralInstructions = useCallback(
    (generalInstructions: string) => {
      effects.updatePrompt({ generalInstructions });
    },
    [effects.updatePrompt]
  );

  const updateObjective = useCallback(
    (objective: string) => {
      effects.updatePrompt({ objective });
    },
    [effects.updatePrompt]
  );

  const categories = useMemo(
    () =>
      ((prompt?.categories as never as string[]) || []).reduce(
        (prevValue, currValue) => {
          return `${prevValue}\n${currValue}`.trim();
        },
        ""
      ),
    [prompt?.categories]
  );

  return (
    <ConfigurationList>
      <TextAreaItemForm
        label="Objective"
        minHeight="2rem"
        defaultValue={prompt?.objective}
        onSubmit={updateObjective}
      />
      <TextAreaItemForm
        label="Instructions"
        minHeight="5rem"
        defaultValue={prompt?.generalInstructions}
        onSubmit={updateGeneralInstructions}
      />
      <TextAreaItemForm
        label="Categories"
        defaultValue={categories}
        minHeight="6rem"
        readOnly
      />
      <TextAreaItemForm
        readOnly
        minHeight="7rem"
        label="Format Instructions"
        defaultValue={prompt?.formatInstructions}
      />

      <PromptExamples />
    </ConfigurationList>
  );
};

export const PromptSettings = () => {
  const content = useMemo(
    () => ({
      header: { title: "Prompt Settings" },
      body: () => <PromptSettingsContent />,
    }),
    []
  );

  return <ContentCard isLoading={false} data={content} />;
};
