import { convertToFetchAPIOpts, validateRequest } from "./helpers";
import { FetchOptions, HttpFetchResult, SendRequest } from "./types";
type ResData = {
  message: string;
  description: string;
  error: any;
  [key: string]: unknown;
} | null;

const resHasContent = (res: Response) => {
  const contlen = res.headers.get("content-length");

  return !(contlen === "0" || contlen === null);
};

/**
 * Javascript fetch only throws when there is a network error but does not throw for any error
 * > 300 status code. This is a thin wrapper around fetch()
 * @param input
 * @param init
 * @returns
 */
export async function httpFetch<R = ResData>(
  input: RequestInfo | URL,
  init: RequestInit | undefined
): Promise<HttpFetchResult<R>> {
  // todo: JSON.sringify init.body automatically when present
  const res = await fetch(input, init);
  if (res.status < 300) {
    if (!resHasContent(res)) {
      return { status: res.status, data: null as R };
    }

    // fix me: we need to decide how to handle none json ok responses too.
    const data: ResData = await res.json();

    let error = "";
    if (typeof data?.error === "boolean" && data?.error === true) {
      error = data?.message;
    }

    return {
      data: data as R,
      error: error || data?.error,
      message: data?.message,
      status: res.status,
    };
  }
  let data_1: ResData;
  let message: string;
  if (resHasContent(res)) {
    data_1 = await res.json();

    message = data_1?.message || data_1?.description || data_1?.error;
    if (message) {
      return { data: data_1 as R, error: message, message, status: res.status };
    }
  }
  switch (res.status) {
    case 400:
      message = "Bad Request";
      break;
    case 422:
      message = "Unprocessable Entity";
      break;
    case 404:
      message = "Not found";
      break;
    case 403:
      message = "Forbidden";
      break;
    default:
      message = "Internal Server Error";
  }
  return { data: null as R, error: message, message, status: res.status };
}

export const sendRequest: SendRequest = async <ResBody, ReqBody>(
  url: string,
  options?: FetchOptions<ReqBody>
) => {
  const validation = validateRequest(url, options);
  if (validation.error) {
    return { ...validation, data: null as ResBody };
  }

  try {
    const input = convertToFetchAPIOpts(options!, validation);
    return await httpFetch<ResBody>(url, input);
  } catch (error) {
    return {
      error: error.message,
      message: error.message,
      data: null as ResBody,
    };
  }
};
