export class HTTPUnauthorized extends Error {
  code: number;
  message: string;
  name: string;
  stack?: string | undefined;

  constructor(message: string) {
    super(message);
    this.code = 401;
    this.message = message;
    this.name = "Unauthorized";
  }
}

export class HTTPForbidden extends Error {
  code: number;
  message: string;
  name: string;
  stack?: string | undefined;

  constructor(message: string) {
    super(message);
    this.code = 403;
    this.message = message;
    this.name = "Unauthorized";
  }
}

export class HTTPNotFound extends Error {
  code: number;
  message: string;
  name: string;
  stack?: string | undefined;

  constructor(message: string) {
    super(message);
    this.code = 404;
    this.message = message;
    this.name = "Unauthorized";
  }
}

export class HTTPBadRequest extends Error {
  code: number;
  message: string;
  name: string;
  stack?: string | undefined;
  data?: any;

  constructor(message: string, data?: any) {
    super(message);
    this.code = 400;
    this.message = message;
    this.name = "BadRequest";
    this.data = data;
  }
}

export class HTTPUnprocessableEntity extends Error {
  code: number;
  message: string;
  name: string;
  stack?: string | undefined;
  data: any;
  constructor(message: string, data: any) {
    super(message);
    this.code = 422;
    this.message = message;
    this.name = "Unprocessable Entity";
    this.data = data;
  }
}

export type HTTPFetch<> = <T = any>(
  nput: RequestInfo | URL,
  init?: RequestInit
) => Promise<T | undefined>;

/**
 * Javscript fetch only throws when there is a network error but does not throw for any error
 * > 300 status code. This is a thin wrapper around fetch()
 * @param nput
 * @param init
 * @returns
 */
const httpFetch: HTTPFetch = <T = any>(
  input: RequestInfo | URL,
  init: RequestInit | undefined
): Promise<T | undefined> => {
  const resHasContent = (res: Response) => {
    const contlen = res.headers.get("content-length");

    return !(contlen === "0" || contlen === null);
  };

  // todo: JSON.sringify init.body automatically when present
  return fetch(input, init).then(async (res) => {
    if (res.status < 300) {
      if (!resHasContent(res)) {
        return;
      }

      // fix me: we need to decide how to handle none json ok responses too.
      return res.json() as Promise<T | undefined>;
    } else if (res.status === 400 || res.status === 422) {
      let data: {
        message: string;
        description: string;
        error: any;
        [key: string]: unknown;
      } | null = null;

      if (resHasContent(res)) {
        data = await res.json();
      }

      let message = data?.message || data?.description || data?.error;
      if (res.status === 400) {
        throw new HTTPBadRequest(message || "Bad Request", data);
      }

      if (res.status === 422) {
        throw new HTTPUnprocessableEntity(
          message || "Unprocessable Entity",
          data
        );
      }
    } else if (res.status === 401) {
      localStorage.removeItem("comxloginstate");
      window.location.reload();
    } else if (res.status === 403) {
      throw new HTTPForbidden("Forbidden");
    } else if (res.status === 404) {
      throw new HTTPNotFound("Not Found");
    } else {
      throw new Error(res.statusText);
    }
  });
};

export const http = {
  fetch: httpFetch,
};
