import React, { FC } from "react";
import { ContentTitle } from "../styled";

export const ClusteringViewTitle: FC<{
  sender?: { companyName: string; email: string };
  isLoading?: boolean;
}> = ({ sender, isLoading }) => {


  return (
    <ContentTitle>
      {!isLoading ? (
        <>
          {" "}
          <h1>{`${sender?.companyName}`}</h1>
          <h3>{sender?.email}</h3>{" "}
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </ContentTitle>
  );
};
