import { useCallback } from "react";
import { useAuthRequestSender } from "../../../hooks/http";
import {
  API,
  API_Data,
} from "../../../../../main-service/src/types/shared/api";
import { E_AdminAPI } from "../../../../../main-service/src/types/shared/api/admin";

export type ResCopilotSettings =
  API[API_Data.ADMIN_RES][E_AdminAPI.CLUSTERING_COPILOT_SETTINGS_RES];

export type ResCopilotPrompt =
  API[API_Data.ADMIN_RES][E_AdminAPI.COPILOT_PROMPT_RES];

const baseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/admin/settings/clustering/copilot`;
export const useCopilotSettings = () => {
  const sendRequest = useAuthRequestSender();

  const getSettings = useCallback(() => {
    return sendRequest<ResCopilotSettings>(baseUrl);
  }, [sendRequest]);

  const updateSettings = useCallback(
    (update: ResCopilotSettings["data"]["value"]) => {
      return sendRequest<ResCopilotSettings, typeof update>(baseUrl, {
        body: update,
        method: "PUT",
      });
    },
    [sendRequest]
  );

  const getPrompt = useCallback(() => {
    return sendRequest<ResCopilotPrompt>(`${baseUrl}/prompt`, {
      method: "GET",
    });
  }, [sendRequest]);

  return {
    getSettings,
    getPrompt,
    updateSettings,
  };
};
