import React, { createContext, useCallback, useMemo } from "react";

import { useGetUnclusteredRepliesCount } from "../hooks/use-unclustered-replies-count";
import { usePolling } from "../hooks/http/use-polling";
import { useAuthRequestSender } from "../hooks/http";
import { useClusteringErrorsCount } from "../hooks/use-clustering-errors-count";

type TGlobalState = {
  unclusteredRepliesCount?: number | null;
  clusteringErrorsCount?: number | null;
};
type TGlobalActions = {
  unassignReply: () => Promise<void>;
};

type TGlobalContext = {
  globalState: TGlobalState;
  globalActions: TGlobalActions;
};

export const GlobalContext = createContext<TGlobalContext>({
  globalState: {
    unclusteredRepliesCount: undefined,
    clusteringErrorsCount: undefined,
  },
  globalActions: {
    unassignReply: async () => {},
  },
});

const useGlobal = (): TGlobalContext => {
  const sendRequest = useAuthRequestSender();

  const unclusteredRepliesCount = usePolling(
    useGetUnclusteredRepliesCount(),
    10000
  );

  const clusteringErrorsCount = usePolling(useClusteringErrorsCount());

  const unassignReply = useCallback(async () => {
    await sendRequest(
      `${process.env.CLUSTERING_BACKEND_HOST}/clustering/tasks/unassign-all`
    );
  }, [sendRequest]);

  const gS: TGlobalState = {
    unclusteredRepliesCount,
    clusteringErrorsCount,
  };

  const globalState = useMemo(
    () => gS,
    [unclusteredRepliesCount, clusteringErrorsCount]
  );

  return {
    globalState,
    globalActions: { unassignReply },
  };
};

export const GlobalContextProvider = ({ children }) => {
  const value = useGlobal();

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
