import React from "react";
import { UserOptionsBase } from "./styled";
import { useNavigate } from "react-router-dom";
import { MenuPopup } from "../../components/MenuPopup";
import { Role } from '../../../../main-service/src/types/shared/shared';
import { useAuthStore } from "../../context/stores/auth/auth-store";

export const UserOptions = ({ onMenuItemClick }) => {
  const { store } = useAuthStore();
  const [ state] = store;
  const navigate = useNavigate();

  const menuItemList = [
    {
      label: "Log out",
      action: () => {
        navigate("/logout");
      },
    },
  ];

  if (state.credentials.role === Role.ADMIN || state.credentials.role === Role.MANAGER) {
    menuItemList.splice(
      0,
      0,
      {
        label: "Settings",
        action: () => {
          navigate("/settings");
        },
      },
      {
        label: "Reporting",
        action: () => navigate("/reporting"),
      }
    );
  }

  const HeaderComponent = () => {
   return <UserOptionsBase>
      <h4 className="user-name">{state.credentials.name}</h4>
      <label className="user-email">{state.credentials.email}</label>
    </UserOptionsBase>
  }

  return (
    <MenuPopup
      header={HeaderComponent}
      items={menuItemList}
      onItemClick={onMenuItemClick}
    />
  );
};
