import styled from "styled-components";

export const ClusteringHistoryWrapper = styled.div`
  display: flex;
  width: 100%;
  ul {
    list-style: none;
    width: 100%;
  }

  li {
    display: flex;
    flex-direction: space-between;
    padding: 10px 16px;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: #fff;
    background-color: #fade9c;
    justify-content: space-between;
    align-items: center;

    .cluster-info {
      display: flex;
      align-items: center;
      .avatar {
        margin-right: 8px;
        font-size: 0.83rem;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background-color: #8e6d21;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 800;

        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          object-fit: contain;
        }
      }

      .name,
      .category {
        font-size: 1rem;
        font-weight: 900;
        padding: 5px;
      }
    }
  }
`;
