import { ClusterInfo } from "../../../../../main-service/src/types/shared/clustering";
import {
  CategorySelection,
  CategorySuggestion,
} from "../../../components/Options";
import { ClusteringOption } from "./types";

export const _getOptionSuggestion = (
  clusteringSuggestion: ClusterInfo | null | undefined
) => {
  if (!clusteringSuggestion) {
    return;
  }

  const suggestion: CategorySuggestion = {
    category: clusteringSuggestion.sentiment,
    mainOption: clusteringSuggestion.category,
    subOption: clusteringSuggestion?.other?.subCategory,
    confidence: clusteringSuggestion.confidence
      ? `${clusteringSuggestion.confidence}%`
      : "",
  };

  return suggestion;
};

export const categoryToClusterOption = (
  category: CategorySelection
): ClusteringOption => {
  return {
    sentiment: category.category,
    category: category.selectedOption,
    other: { subCategory: category.selectedSubOption },
  } as ClusteringOption;
};

export const clusterOptionToCategory = (
  cluster: ClusteringOption | null | undefined
): CategorySelection | null => {
  if (!cluster) {
    return null;
  }

  return {
    category: cluster?.sentiment,
    selectedOption: cluster?.category,
    selectedSubOption: cluster.other?.subCategory,
  };
};
