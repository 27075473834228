import { SetStateAction } from "react";
import { ClusteringCopilotMode } from "../../../../../main-service/src/types/shared/settings";
import { CopilotSettingsStateContext } from "./types";
import { CopilotSettingsEFfectsContext } from "./types";

export const getDefaultCopilotStateContext =
  (): CopilotSettingsStateContext => {
    const notImplementedError = new Error(
      "Function not implemented. Ensure CopilotSettingsStateContext is within scope."
    );

    return [
      {
        error: "",
        isLoading: false,
        settings: null,
        fullPrompt: "",
        promptTestingResultStatus: {
          error: "",
          isLoading: false
        }
      },
      {
        loading: function () {
          throw notImplementedError;
        },
        error: function (_message?: string): unknown {
          throw notImplementedError;
        },
        setCopilotSettings: function (_data): unknown {
          throw notImplementedError;
        },
        setIsBasicSettingsLoading: function (_isUpdating?: boolean): void {
          throw notImplementedError;
        },
        setFullPrompt: function (value: SetStateAction<string>): void {
          throw notImplementedError;
        }
      },
    ] as const;
  };

export const getDefaultCopilotSettingsEffects =
  (): CopilotSettingsEFfectsContext => {
    const effectContextImplementation = new Error(
      "Function not implemented. Ensure that CopilotSettingsEffectsContext is in scope "
    );

    const effects: CopilotSettingsEFfectsContext = {
      fetchSettings: function (): Promise<void> {
        throw effectContextImplementation;
      },
      setCopilotEnabled: function (_enabled: boolean): void {
        throw effectContextImplementation;
      },
      updateMode: function (_value: ClusteringCopilotMode): unknown {
        throw effectContextImplementation;
      },
      updatePrompt: function (_value): void {
        throw effectContextImplementation;
      },
      setHintEnabled: function (_enabled: boolean): unknown {
        throw effectContextImplementation;
      },
      updateConfidenceLevelToAct: function (_value: number): void {
        throw effectContextImplementation;
      },
      updateAgentEmail: function (_arg0: string): unknown {
        throw effectContextImplementation;
      },
      testPrompt: function (_email: string): void {
        throw effectContextImplementation;
      }
    };

    return effects;
  };
