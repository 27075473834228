import React, { useMemo } from "react";
import { ContentCard } from "../../../../../components/ContentCard";
import { ListView, MaxWidth60Rem } from "../../styled";
import { PromptSettings } from './PromptSettings/PromptSettings';
import { PromptTesting } from "./PromptTesting";


const AdvanceSettingsContent = () => {
  return (
    <>
      <PromptSettings />
      <PromptTesting />
    </>
  );
};

export const AdvanceSettings = () => {
  const content = useMemo(
    () => ({
      header: { title: "Advance Settings" },
      body: () => <AdvanceSettingsContent />,
    }),
    []
  );

  return (
    <MaxWidth60Rem>
      <ListView>
        <ContentCard isLoading={false} data={content} />
      </ListView>
    </MaxWidth60Rem>
  );
};
