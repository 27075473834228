import styled from "styled-components";

export const Button = styled.button`
  padding: 12px;
  color: #fff;
  background: #002342;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s;
  &:hover {
    background: #405d76;
  }
  &:disabled {
    cursor: unset;
    opacity: 0.3;
  }
`;

export const ContentBase = styled.div`
  display: flex;
  grid-gap: 8px;
  flex-direction: column;
`;

export const ErrorMessage = styled.div`
  padding: 24px;
  color: #f04541;
`;

export const Group = styled.div`
  display: flex;
  grid-gap: 8px;
`;

export const ListView = styled.div`
  display: flex;
  grid-gap: 16px;
  flex-grow: 1;
  flex-direction: column;
`;

export const Metrics = styled.div`
  display: flex;
  padding: 12px 0;
  grid-gap: 20px;
`;

export const MetricBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 8px;
  & > label {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: #535c5c;
  }
  & > span {
    display: inline-block;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
  }
  &.desc > span {
    font-size: 16px;
  }
  &.error > span {
    color: #c23734;
  }
`;

export const TableBase = styled.div`
  padding: 24px;
  display: flex;
  grid-gap: 14px;
  flex-flow: column;
  padding: 0.5rem;
  width: 100%;
  background-color: #fff;
  height: 75vh;
  margin-top: 2rem;
`;

export const TableHeading = styled.span`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #9da2a2;
`;

export const FormBase = styled.div`
  display: flex;
  position: relative;
  background-color: #fdfdfdc3;
  width: 28rem;
  height: 35rem;
  padding: 0.5rem;

  form {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;
    input {
      display: inline-flex;
      border: 1px solid #b3b1b1fc;
      width: 100%;
    }

    input:disabled {
      background: #e4e0e0c2;
    }

    button {
      width: 96%;
      margin-top: 0.5rem;
      text-align: center;
    }

    h4 {
      margin-bottom: 0.5rem;
    }

    .error {
      text-align: center;
      font-size: 1rem;
      color: #c73d3de4;
      font-weight: bold;
    }
  }
`;

export const ToggleWrapper = styled.div`
  width: 300px;
  height: 30px;
  margin-left: auto;
`;

export const SettingsError = styled.div<{ isVisible?: boolean }>`
  visibility: ${({ isVisible }) => (isVisible ? "visible" : undefined)};
  color: red;
  padding: 1rem;
  height: 1rem;
  transition: visibility 0.5s ease-in;
`;

export const MaxWidth60Rem = styled.div`
  max-width: 60rem;
  padding: 2rem;
`;
