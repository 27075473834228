import { PromptConfig } from './clustering';
import { JSONB } from 'src/types/internal';

export const CLUSTERING_AI_MODES = [
  'BACKGROUND',
  'FACT_CHECKING',
  'AGENT',
] as const;

export type ClusteringCopilotMode = (typeof CLUSTERING_AI_MODES)[number];

export type ClusteringCopilotSettings = {
  agentEmail?: string;
  enabled?: boolean;
  confidenceLevelToAct?: number;
  mode?: ClusteringCopilotMode;
  prompt: PromptConfig;
  hintEnabled?: boolean;
};

export enum E_Settings {
  CLUSTERING_COPILOT = 'CLUSTERING_COPILOT',
}

export type Settings = {
  [E_Settings.CLUSTERING_COPILOT]: ClusteringCopilotSettings;
};

export type SettingsNames = keyof Settings;

export type DBSettings<
  Name extends keyof Settings = SettingsNames,
  Decoded extends boolean = false,
> = {
  id?: string;
  name: Name;
  value: Decoded extends false ? JSONB<Settings[Name]> : Settings[Name];
};

export type D = DBSettings<SettingsNames, true>;
