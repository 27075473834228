import styled from "styled-components";

export const FilterOptions = styled("div")<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: ${(props) => (!props.isSelected ? "#f8f8f8" : "#ccc6c6")};
  padding: 10px;
  min-width: 42%;
  max-height: 30rem;
  position: absolute;
  margin-top: 0.8rem;
  z-index: 1002;
`;

export const FilterOpt = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:last-child {
    margin-bottom: 0;
  }

  background: #f8f8f8;
  border-radius: 8px 8px 0px 0px;

  &.selected {
    background: #edeeee;
  }

  &:hover {
    cursor: pointer;
    background: #edeeee;
  }
`;
