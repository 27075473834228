import { useEffect, useState } from "react";

export const usePolling = <T>(
  dataFetcher: () => Promise<T | undefined | null>,
  frequency = 5000,
  fetchImmediately = true
) => {
  const [polledData, setPolledData] =
    useState<Awaited<ReturnType<typeof dataFetcher>>>();

  useEffect(() => {
    const doFetch = async () => {
      const data = await dataFetcher();
      setPolledData(data);
    }

    if(fetchImmediately) {
      doFetch()
    }

    const iID = setInterval(async () => {
       await doFetch();
    }, frequency);

    return () => {
      clearInterval(iID);
    };
  }, [fetchImmediately, frequency, dataFetcher]);

  return polledData;
};
