import styled from 'styled-components';

export const BackButton = styled.button`
	padding: 8px;
	background: ${props => props.theme.colors['cold-grey'][50]};
	border-radius: 8px;
	border: none;
	cursor: pointer;
	transition: all 0.3s;
	&:hover {
		background: #002648;
	}
	&:hover svg path {
		stroke: #fff;
	}
`;

export const ContentBase = styled.div`
	padding: 24px;
	flex-grow: 1;
	
	& .error {
		color: red;
	}
`;

export const MainOptions = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	border-right: 1px solid #dcdddd;	
	& > h3 {
		font-size: 14px;
		text-transform: uppercase;
		color: #7F92A3;
		white-space: nowrap;
	padding-bottom: 8px;
	}
	grid-gap: 16px;
	flex-basis: max-content;
`;

export const MainOption = styled.a`
	border: none;
	font-size: 18px;
	color: #535C5C;
	cursor: pointer;
`;

export const SettingsBase = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	flex-grow: 1;
`;

export const SideBar = styled.div`
	display: flex;
	padding: 24px;
	border-right: 1px solid #dcdddd;
	flex-direction: column;
	
	& > div {
		padding: 0 0 16px 0;
		border-bottom: 1px solid #dcdddd;
	}
	& > div:last-child {
		border: none;
	}
`;

