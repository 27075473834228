import React, { useCallback } from "react";

import { ClusteringView } from "../../../containers/Clustering/ClusteringView";
import { useRecluteringPage } from "./hooks/use-reclustering-page";
import { Popups } from "../../../components/DialogPopups";

export const Reclustering = () => {
  const {
    task,
    isLoading,
    dialogs,
    onViewExit,
    onViewEnter,
    onActionDone,
    complete,
  } = useRecluteringPage();

  const renderFallback = useCallback(() => <h1>not found</h1>, []);

  return (
    <>
      <ClusteringView
        task={task}
        isLoading={isLoading}
        complete={complete}
        onViewExit={onViewExit}
        renderFallback={renderFallback}
        onViewEnter={onViewEnter}
        onClusteringActionDone={onActionDone}
      />

      <Popups configs={dialogs} />
    </>
  );
};
