import styled from "styled-components";
import { Button } from "../../../../components/ConfigurationPanel/styled";

export const DeleteButton = styled(Button)`
  padding: 0 1rem;
  background-color: #011c331d;

  &:hover {
    background-color: #011c33db;
  }

  transform: background-color 0.3s ease-in;
`;

export const ExampleFormWrapper = styled.div`
  width: 28rem;
  height: fit-content;
  padding: 1rem;
  margin: auto;
  form {
    position: relative;
    width: 90%;
    margin: auto;

    > div {
      margin-bottom: 1.5rem;
    }

    select,
    textarea,
    input,
    button {
      display: flex;
      position: relative;
      width: 100%;
    }
    button {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
`;
