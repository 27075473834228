import { useCallback, useMemo } from "react";
import { useAuthRequestSender } from "../../hooks/http";

const baseUrl = `${process.env.CLUSTERING_BACKEND_HOST}/clustering/metrics`;
export const useMetrics = () => {
  const sendRequest = useAuthRequestSender();

  const getUnclusteredRepliesCount = useCallback(() => {
    return sendRequest<{ count?: number }>(`${baseUrl}/replies/unclustered`);
  }, [sendRequest]);

  const getClusteringErrorsCount = useCallback(() => {
    return sendRequest<{ count?: number }>(`${baseUrl}/errors`);
  }, [sendRequest]);

  return useMemo(
    () => ({ getUnclusteredRepliesCount, getClusteringErrorsCount }),
    [getUnclusteredRepliesCount, getClusteringErrorsCount]
  );
};
