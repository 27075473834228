import styled from "styled-components";

export type FGBaseProps = {
  row?: boolean;
  reverse?: boolean;
  alignItems?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "center"
    | "baseline"
    | "stretch";
};

export const FGroupBase = styled("div")<FGBaseProps>`
  display: flex;
  flex-direction: ${(props) => {
    let direction = props.row ? "row" : "column";

    if (props.reverse) {
      direction += "-reverse";
    }

    return direction;
  }};
  align-items: ${(props) => props.alignItems || "center"};
  justify-content: space-between;

  padding: 0.5rem 0.8rem;
  width: 100%;
  height: 100%;
  border-radius: 3.75rem;
  background: #f8f8f8;
`;
