import React, { FC, useCallback } from "react";

import { SubOptionsBase } from "../styled";
import { BaseOption, OptionActions } from "./types";
import { OptionToolTip } from "./OptionToolTip";

type SubOptionsProps = {
  options?: BaseOption[];
  parent: string;
  suggestion?: string;
} & OptionActions;

export const SubOptions: FC<SubOptionsProps> = ({
  options,
  parent,
  suggestion,
  onSelect,
  isSelected,
}) => {
  const shouldSuggest = (subOption: string | undefined) => {
    return suggestion === subOption;
  };

  return options?.length ? (
    <SubOptionsBase className="options">
      {options.map((option) => (
        <SubOption
          suggested={shouldSuggest()}
          key={`sub-option-${parent}-${option.name}`}
          option={option}
          parent={parent}
          onSelect={onSelect}
          isSelected={isSelected}
        />
      ))}
    </SubOptionsBase>
  ) : null;
};

export type SubOptionProps = {
  option: BaseOption;
  suggested?: boolean;
  parent: string;
} & OptionActions;

export const SubOption: FC<SubOptionProps> = ({
  option,
  parent,
  suggested,
  onSelect,
  isSelected,
}) => {
  const handleClick = useCallback(() => {
    onSelect(parent, option.name);
  }, [onSelect, parent, option.name]);

  const getClassName = () => {
    if (isSelected(parent, option.name)) {
      return "selected";
    }

    if (suggested) {
      return "suggested";
    }

    return "";
  };

  return (
    <button onClick={handleClick} className={getClassName()}>
      {option.name} {option.tip && <OptionToolTip tip={option.tip} />}
    </button>
  );
};
