import React, { useCallback, useEffect, useState } from "react";
import ArrowLeft from "~/src/assets/arrow-left.svg";
import { useNavigate } from "react-router-dom";

import {
  BackButton,
  ContentBase,
  MainOption,
  MainOptions,
  SettingsBase,
  SideBar,
} from "./styled";
import { Role } from "../../../../main-service/src/types/shared/shared";
import { determineLeastStrictTab } from "./content/helper";
import { Tab, TabNames } from "./types";
import { TabContentSelector } from "./TabContentSelector";
import { PageTitle } from "../../components/PageTitle";
import { useAuthStore } from "../../context/stores/auth/auth-store";

const tabs: Tab[] = [
  {
    name: TabNames.UserManagement,
    roles: [Role.ADMIN, Role.MANAGER],
  },
  {
    name: TabNames.WoodpeckerIntegration,
    roles: [Role.ADMIN],
  },
  {
    name: TabNames.CopilotSettings,
    roles: [Role.ADMIN],
  },
];

const roles = [Role.ADMIN, Role.MANAGER];
let defaultOption = tabs[0];

export const Settings = () => {
  const { store } = useAuthStore();
  const [state, reducer] = store;
  const [tab, setTab] = useState<Tab>(
    tabs.find(determineLeastStrictTab(defaultOption)) || defaultOption
  );
  const navigate = useNavigate();

  useEffect(() => {
    const authorization = roles.find((role) => reducer.isAuthorized(role));
    if (!authorization) {
      navigate("/unauthorized");
    }
  }, []);

  const onSelectTab = useCallback((t: Tab) => {
    setTab(t);
  }, []);

  return (
    <SettingsBase>
      <SideBar>
        <BackButton onClick={() => history.back()}>
          <ArrowLeft />
        </BackButton>
      </SideBar>
      <MainOptions>
        <h3>Options for {state.credentials.role}</h3>
        {tabs.map(
          (tab) =>
            state.credentials.role && (
              <MainOption key={tab.name} onClick={() => onSelectTab(tab)}>
                {tab.name}
              </MainOption>
            )
        )}
      </MainOptions>
      <ContentBase>
        <PageTitle>{tab.name}</PageTitle>
        <TabContentSelector tab={tab} />
      </ContentBase>
    </SettingsBase>
  );
};
