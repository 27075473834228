import {
  errorDialogActions,
  reclusteringCloseDialogActions,
  reclusteringDoneActions,
} from "../../constants";

export const reclusteringExitPopupConfig = {
  mainText: "The page contains unsaved changes.",
  subText: "Are you sure you want to leave the page?",
  actions: reclusteringCloseDialogActions,
} as const;

export const reclusteringErrorPopupConfig = {
  mainText: "An error occurred while clustering",
  subtext: "",
  actions: errorDialogActions,
} as const;

export const reclusteringDonePopupConfig = {
  mainText: "Reclustering done",
  subText: "",
  actions: reclusteringDoneActions,
} as const;
